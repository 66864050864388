import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private http: HttpClient) {

  }
  getTicketsByBusiness(businessID: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/tickets?businessID=${businessID}`);
  }
  getTicketsByJobNo(jobNo: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/tickets?jobNo=${jobNo}`);
  }
  reinstateTicket(ticketId: number, pendingStatus: boolean): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/tickets/${ticketId}/reinstate`, { updatePendingStatus: pendingStatus });
  }
  getTicketsByID(ticketId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/tickets/${ticketId}`);
  }
  getTicketDetailsByID(ticketId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/tickets/${ticketId}/fulldetails`);
  }
}
