import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import Utils from '../../helpers/utils-helper'
import { FootInchQuarter } from "../../../models/footInchQuarter";

@Component({
  selector: 'foot-inch-quarter-select',
  templateUrl: './foot-inch-quarter-select.component.html',
  styleUrls: ['./foot-inch-quarter-select.component.scss']
})
export class FootInchQuarterSelectComponent implements OnInit {

  @ViewChild('editField') editField: ElementRef;
  @ViewChild('editSelect') editSelect;
  utils = new Utils();

  /**
   * Measurement value for the select boxes
   */
  @Input() measurement: number = 0.0000;

  /**
     * Field name for the select boxes
     */
  @Input() editFieldName: any;

  /**
   * The business type ids allowed to edit this field.
   */
  @Input() allowedBusinessTypes: Array<number>;

  /**
   * Ticket or job detail from the loaded ticket/job
   */
  @Input() originalObject: any;

  footInchQuarter: FootInchQuarter = { foot: 0, inch: 0, quarter: 0 };
  measurementDataType: String = "";

  constructor() {}

  ngOnInit(): void {
    this.footInchQuarter = {
      foot: this.getFeet(this.measurement),
      inch: this.getInches(this.measurement),
      quarter: this.getQuarter(this.measurement)
    }
    this.measurementDataType = typeof this.measurement;
  }

  /**
   * Counter for creating the foot, inch, and quarter-inch drop down selections.
   *
   * @param length
   */
  counter(length: number) {
    return new Array(length);
  }

  /**
   * Gets the whole-feet portion of the measurement, leaving anything that remains to be calculated as inches.
   *
   * @param measurement
   */
  getFeet(measurement: number) {
    return Math.floor(measurement);
  }

  /**
   *  Gets the remaining whole-inch portion of the passed measurement, after subtracting out the whole-feet.
   *
   * @param measurement
   */
  getInches(measurement: number) {
    let feet = this.getFeet(measurement);
    let feetRemainder = measurement - feet;
    let inchDecimal = feetRemainder * 12;
    let quarters = Math.floor((Math.round((inchDecimal - Math.floor(inchDecimal)) * 100) / 100) * 4);

    return Math.floor(inchDecimal) + Math.floor(quarters / 4);
  }

  /**
   * Gets the remaining quarter-inch value from the passed measurement.
   *
   * @param measurement
   */
  getQuarter(measurement: number) {
    let feet = this.getFeet(measurement);
    let inches = this.getInches(measurement);
    let quarter = ((measurement - feet) * 12) - inches;

    return Math.round(quarter * 100) / 100;
  }

  /**
   * Recalculates the measurement based on the selections and updates the original data.
   */
  updateDisplay(): void {
    let selections = this.footInchQuarter;
    let newValue = selections.foot + ((selections.inch + selections.quarter) / 12);
    this.originalObject[this.editFieldName] = this.measurementDataType === 'number' ? newValue : `${newValue}`;
  }

  /**
   * Starts edit-in-place for the field when clicking the value.
   */
  startFieldEdit(): void {
    // Stops field from being edited if user is not allowed.
    if (!this.utils.isAllowed(this.allowedBusinessTypes))
      return;

    // This triggers the display of the edit-in-place field.
    // It must finish initializing/displaying before calling the field edit.
    this.utils.setEditFieldName(this.editFieldName);

    // This will focus the selected field after it is activated.
    setTimeout(() => {
      this.utils.startFieldEdit(this.editFieldName, this.editField, this.editSelect);
    }, 0);
  }
}
