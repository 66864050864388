import { AbstractControl } from '@angular/forms';
import { User } from '../../user/user';

export class PasswordRequirements {

  public static numberReqPattern = "^.*[0-9].*$";
  public static lowerCaseLetterReqPattern = "^.*[a-z].*$";
  public static upperCaseLetterReqPattern = "^.*[A-Z].*$";
  public static mustBeSixCharacterReqPattern = "/^.{6,}$/";
  public static specialCharacterReqPattern = "[^A-Za-z0-9]";

  static passwordMustMeetPatternRequirements(ctrl: AbstractControl): { [key: string]: boolean | null } {
    /*
        ^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$
        NOTE: the above should work as a single check for all criteria however even
        though it works in a REGEX validator, the below will not function
        properly with it.
    */
    let newPwd = ctrl.get('password');
    let rgx = new RegExp(PasswordRequirements.numberReqPattern);
    if (!rgx.test(newPwd.value)) { return { doesNotMeetValidationReq: true } }
    rgx = new RegExp(PasswordRequirements.lowerCaseLetterReqPattern);
    if (!rgx.test(newPwd.value)) { return { doesNotMeetValidationReq: true } }
    rgx = new RegExp(PasswordRequirements.upperCaseLetterReqPattern);
    if (!rgx.test(newPwd.value)) { return { doesNotMeetValidationReq: true } }
    rgx = new RegExp(PasswordRequirements.specialCharacterReqPattern);
    if (!rgx.test(newPwd.value)) { return { doesNotMeetValidationReq: true } }
    return null;
  }

  static passwordNewConfirmMustMatch(ctrl: AbstractControl): { [key: string]: boolean | null } {
    let confPwd = ctrl.get('confirmPassword');
    let newPwd = ctrl.get('password')
    if (newPwd.value !== confPwd.value) {
      return { newConfDoesNotMatch: true }
    }
    return null;
  }

  static passwordNewMustNotBeUsernameMatch(ctrl: AbstractControl): { [key: string]: boolean | null } {
    let loggedInUser: User;
    let newPwd = ctrl.get('password')
    if (localStorage.getItem('person')) {
      loggedInUser = JSON.parse(localStorage.getItem('person'));
    }

    if (loggedInUser != null && newPwd.value === loggedInUser.username) {
      return { newPwdUsernameMatch: true }
    }
    return null;
  }
}
