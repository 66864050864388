<span *ngIf="utils.editFieldName!=editFieldName" (click)="startFieldEdit();">{{measurement | number : '1.4'}}</span>
<div class="row" *ngIf="utils.editFieldName==editFieldName">
  <div class="col-lg-4">
    <mat-form-field>
      <mat-label>Ft</mat-label>
      <mat-select [(ngModel)]="footInchQuarter.foot" (ngModelChange)="updateDisplay()" (blur)="utils.stopFieldEdit()">
        <mat-option *ngFor="let ft of counter(51); let idx = index" [value]="idx">
          {{idx}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-lg-4">
    <mat-form-field>
      <mat-label>In</mat-label>
      <mat-select [(ngModel)]="footInchQuarter.inch" (ngModelChange)="updateDisplay()" (blur)="utils.stopFieldEdit()">
        <mat-option *ngFor="let in of counter(12); let idx = index" [value]="idx">
          {{idx}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-lg-4">
    <mat-form-field>
      <mat-label>Qtr</mat-label>
      <mat-select [(ngModel)]="footInchQuarter.quarter" (ngModelChange)="updateDisplay()" (blur)="utils.stopFieldEdit()">
        <mat-option *ngFor="let qtr of counter(4); let idx = index" [value]="idx * 0.25">
          {{idx * 0.25}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
