import {ElementRef, ViewChild} from "@angular/core";
import {Business} from "../../models/business";

/**
 * Utilities Class to hold helper functions and capabilities
 */
export default class Utils {

  editFieldName: String = '';
  activeBusiness: Business = new Business();

  constructor() {
    this.activeBusiness = JSON.parse(localStorage.getItem('selectedBusiness'));
  }

    /**
     * Returns a Material Icon showig true or false
     * @param value boolean, string, number cnverted to a true/false.
     * Returns a green check or red "X" depending on true/false state
     */
    getGlyphCheck(value: any) {
        let checkVal = new Boolean();
        if (this.isEmpty(value)) checkVal = false;
        else if (typeof value === 'number') checkVal = (value != 0);
        else if (typeof value === 'string') checkVal = (value.toLowerCase() === 'yes' || value.toLowerCase() === 'ok' || value === '1');
        else if (typeof value === 'boolean') checkVal = value;

        return checkVal ? '<span class="green material-icons">done</span>' : '<span class="red material-icons">close</span>';
    }

    /**
     * Check if the supplied variable is empty in any way
     * @param value value to check
     */
    isEmpty(value: any) {
        return value === undefined || value === null || value.length === 0;
    }

  /**
   * Sets the field name currently being edited by edit-in-place.
   * This toggles the normal display vs. editing.
   *
   * @param fieldName
   */
  setEditFieldName(fieldName: string) {
      this.editFieldName = fieldName;
  }

  /**
   * Starts edit-in-place for the field when clicking the value.
   *
   * @param fieldName
   * @param editField
   * @param editSelect
   */
  startFieldEdit(fieldName: string, editField: ElementRef, editSelect: any): void {
    this.setEditFieldName(fieldName);

    // This will focus the selected field after it is activated.
    setTimeout(() => {
      if (editField && editField.nativeElement) {
        editField.nativeElement.focus();
      } else if (editSelect) {
        editSelect.focus();
        editSelect.open();
      }
    }, 0);
  }

  /**
   * Stops edit-in-place for the field being edited.
   */
  stopFieldEdit(): void {
    setTimeout(() => {
      this.setEditFieldName('');
    }, 250);
  }

  /**
   * Finds the first object in an array, where the passed value
   * matches the object's value for the given key.
   *
   * @param objectList
   * @param key
   * @param searchVal
   */
  findObject(objectList, key, searchVal): any {
    return objectList.find(object => object[key] == searchVal);
  }

  /**
   * Validates if a user is allowed to perform an action.
   *
   * @param allowedBusinessTypes
   */
  isAllowed(allowedBusinessTypes: Array<number>): boolean {
    if (allowedBusinessTypes.includes(this.activeBusiness.businessTypeID))
      return true;

    return false;
  }
}
