import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CommonModule } from '@angular/common';
import { HeaderTitleService } from '../header-title.service';
import { BusinessService } from '../../operator/business.service';
import { BusinessSelectComponent } from '../../operator/business-select/business-select.component';
import { AuthService } from '../../auth/auth.service'
import { User } from '../../user/user';
import { empty } from 'rxjs';

@Component({
  selector: 'em-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input()
  menuActive;
  notificationCount;
  environment: any;
  title = [];
  titleText = '';
  isShowDiv = "";
  @Output() loadView = new EventEmitter<any>();
  person: User = new User();

  constructor(private headerTitleService: HeaderTitleService, public authService: AuthService) {
    this.isCollapsed = false;

    this.authService.personSubject.subscribe(person => {
      if (person != null) this.person = person;
    });
  }

  ngOnInit() {

    this.headerTitleService.title.subscribe(updatedTitle => {
      this.title = updatedTitle;
      if (this.title && this.title.length > 1) {
        let i = 1;
        this.title.forEach(page => {
          if (this.title.length > i) {
            this.titleText += page + ' -> ';
          } else {
            this.titleText += '<strong>' + page + '</strong>';
          }
          i++;
        });
      }

    });
  }

  public isCollapsed: boolean;

  /**
   * Determines if a menu item is active
   */
  toggleMenuActive() {
    this.menuActive = !this.menuActive;
  }

  /**
   * Toggles the user submenu
   */
  toggleDisplayDiv() {
    if (this.isShowDiv === "show") this.isShowDiv = "";
    else this.isShowDiv = "show";
  }

  /**
   * Closes the menu display if clicked outside of the div
   * @param event 
   */
  closeDisplayDiv(event: any) {
    if (!event || !event.target || event.target.id === 'userpulldown') return;
    if (event.target.id !== 'userDropdown') this.isShowDiv = "";
  }

}
