<div class="container">
  <mat-card class="col-lg-12">
    <mat-card-title>
      User Search
    </mat-card-title>
    <mat-card-content>
      <form>
        <div class="row">
          <div class="col-lg-10">
            <mat-form-field class="example-full-width" [floatLabel]="alwaysFloat">
              <mat-label>Search</mat-label>
              <input matInput name="searchTerm" [(ngModel)]="searchTerm">
            </mat-form-field>
            <button class="btn btn-primary btn-accent searchButton" (click)="usersSearch()">
              <fa-icon style="padding:0px !important;" [icon]="faSearch"></fa-icon>
            </button>
          </div>
          <div class="col-lg-2">
            <button class="btn btn-primary btn-accent" (click)="createUser()">
              <fa-icon [icon]="faPlusCircle"></fa-icon> Create User
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10">
            <table class="table table-striped">
              <thead>
                <tr class="col-lg-10">
                  <!-- <th class="col-lg-1">Active</th> -->
                  <th style="width:30%;">Name</th>
                  <th style="width:30%;">Business Name</th>
                  <th style="width:30%;">Role</th>
                  <th style="width:10%"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of users" class="col-lg-10">
                  <!-- <td class="col-lg-1">
                                <mat-checkbox class="example-margin" name="isEnabled" [(ngModel)]="user.isEnabled">
                                </mat-checkbox>
                            </td> -->
                  <td style="width:30%;"> {{user.personName}}</td>
                  <td style="width:30%;"> {{user.businessName}}</td>
                  <td style="width:30%;"> {{user.credentialType}}</td>
                  <td style="width:10%;">
                    <fa-icon (click)="editUser(user)" [icon]="faEdit"></fa-icon>
                  </td>

                </tr>
              </tbody>
            </table>
            <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
              (page)="pageEvent = paginationChange($event)">
            </mat-paginator>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>