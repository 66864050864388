import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../user/user';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Globals } from '../../globals';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  public personSubject: BehaviorSubject<any>;
  public person: Observable<User>;

  constructor(private http: HttpClient, private router: Router, public jwtHelper: JwtHelperService, private globals: Globals) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.personSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('person')));
    this.person = this.personSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get personValue(): User {
    return this.personSubject.value;
  }

  login(username, password): Observable<any> {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('rememberme', 'yes');

    return this.http.post(`${environment.apiUrl}/api/auth/login`,
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Accept', 'application/json')

      }
    ).pipe(map(user => {

      if (user['user'] && user['user'].credentialTypeID !== this.globals.adminCredentialType) {
        this.logout();
        return;
      }
      this.person = user['user'];
      delete user['user'];


      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(user));
      localStorage.setItem('person', JSON.stringify(this.person));
      this.currentUserSubject.next(user);
      this.personSubject.next(this.person);
      return user;
    }));

  }
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }
  // }
  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    localStorage.removeItem('person');
    this.router.navigate(['/login']);
    this.currentUserSubject.next(null);
  }
};

