import { Component, OnInit, Input, OnChanges, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TicketDetail } from '../../models/ticketDetail';
import { GpsLocation } from '../../models/gpsLocation';
import { Ticket } from '../../models/ticket';
import { HeaderTitleService } from '../../layout/header-title.service';
import * as _ from 'lodash';
import { AliasService } from '../../alias/alias.service';
import { HeaderAlias } from '../../models/headerAlias';
import { TicketService } from '../ticket.service';
/// <reference types="@types/googlemaps" />
import { BusinessCostCode } from '../../models/businessCostCode';
import { BusinessService } from '../../operator/business.service';
import { Afe } from "../../models/afe";
import { AfeService } from "../../afe/afe.service";
import { User } from "../../user/user";
import Utils from '../../shared/helpers/utils-helper'
import { InputType } from '../../shared/components/edit-in-place-input/edit-in-place-input.component'
import { BusinessTerminal } from "../../models/businessTerminal";
import { Valve } from "../../models/valve";
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { ScanItem } from "../../models/scanItem";
import { EditScanItemModal } from './edit-scan-item-modal/edit-scan-item-modal.component';
import { ConfirmationDialogComponent } from "../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatExpansionPanel } from '@angular/material/expansion';
import { ItemQrCodeService } from "../../item-qr-code/item-qr-code.service";
import { ItemQRCode } from "../../models/itemQRCode";
import { ScanItemDialogData } from "./edit-scan-item-modal/ScanItemDialogData";
import { ApiResponse } from "../../models/apiResponse";

@Component({
  selector: 'app-review-ticket',
  templateUrl: './review-ticket.component.html',
  styleUrls: ['./review-ticket.component.scss']
})
export class ReviewTicketComponent implements OnInit {

  @Input() ticketid: any;

  @Input() viewdata;
  @Input() userData;
  ticket: TicketDetail
  ticketID: number;
  ticketAttachments: any[] = [];  // used by file upload component
  // Location for map
  mapLoc: GpsLocation = { latitude: 0, longitude: 0 };
  // Marker data for map: start {} [0], destination {} [1]
  mapMarkers: google.maps.MarkerOptions[] = [];
  routeDetailsExpanded = true;
  totalTime: number;
  totalTimeVal: any;
  // Associated ticket data; structure should always mirror review-ticket.component
  ticketData: Ticket = { startTime: null, status: null };
  headerAliases: HeaderAlias
  mobileAliases: HeaderAlias;
  totalPhaseTime = 0;
  ticketOption = 0;

  costCodes: BusinessCostCode[];
  afes: Afe[];
  foremen: User[];
  terminals: BusinessTerminal[];
  valves: Valve[];
  itemQRCodes: ItemQRCode[];

  @ViewChild('editField') editField: ElementRef;
  @ViewChild('editSelect') editSelect;
  utils = new Utils();
  InputType = InputType;

  faTrash = faTrash;
  faEdit = faEdit;

  constructor(
    private route: ActivatedRoute,
    private headerTitleService: HeaderTitleService,
    private aliasService: AliasService,
    private businessService: BusinessService,
    private afeService: AfeService,
    private itemQrCodeService: ItemQrCodeService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe((result) => {
      this.ticket = result.ticket.data;

    });

    this.aliasService.headerAliases.subscribe(aliases => {
      this.headerAliases = aliases;
    });
    this.aliasService.mobileAliases.subscribe(aliases => {
      this.mobileAliases = aliases;
    });

    this.businessService.getAllTerminals(this.ticket.businessTruckerID).subscribe(result => {
      this.terminals = result.data;
    });

    this.businessService.getAllValves(this.ticket.businessTruckerID).subscribe(result => {
      this.valves = result.data;
    });

    this.businessService.getAllCostCodes(this.ticket.businessOperatorID).subscribe(result => {
      this.costCodes = result.data;
    });

    this.afeService.getAfeByBusiness(this.ticket.businessOperatorID).subscribe(result => {
      this.afes = result.data;
    });

    this.businessService.getAllUsers(this.ticket.businessOperatorID, [2, 5]).subscribe(result => {
      this.foremen = result.data;
    });

    this.itemQrCodeService.getItemQrCodeByBusiness(this.ticket.businessTruckerID).subscribe(result => {
      this.itemQRCodes = _.sortBy(result.data, ['itemDescription']);
    });

    this.headerTitleService.setTitle(['Tickets', 'Review Tickets'])
    this.ticketOption = this.ticket.operator.ticketOption;
    let snapshot = this.route;

    this.mapLoc = { latitude: parseFloat(this.ticket.jobTimeTypes[0].startlatitude), longitude: parseFloat(this.ticket.jobTimeTypes[0].startlongitude) }; // Denver
    this.ticket.jobTimeTypes = _.sortBy(this.ticket.jobTimeTypes, [function (o) { return o.startTime; }]);;
    this.ticket.jobTimeTypes.forEach(jtt => {
      //TODO:Add these to CONSTANT files when it gets created.
      if (jtt.startlatitude != '0.0' && jtt.timeTypeID === 1) { //En Route
        this.mapMarkers.push({ position: { lat: parseFloat(jtt.startlatitude), lng: parseFloat(jtt.startlongitude) }, clickable: true, icon: 'enRoute' })
      }
      if (jtt.startlatitude != '0.0' && jtt.timeTypeID === 3) { //Work
        this.mapMarkers.push({ position: { lat: parseFloat(jtt.startlatitude), lng: parseFloat(jtt.startlongitude) }, clickable: true, icon: 'work' })
      }

      let timeDiff = this.getDifferenceBetweenTimes(jtt.startTime, jtt.endTime);

      jtt.phaseTime = this.convertMinutesToHoursAndMinutes(this.roundToQuarterHour(timeDiff) * 60);
      this.totalPhaseTime += this.roundToQuarterHour(timeDiff) * 60;
    });

    if (this.ticket.jobEndLatitude) {
      this.mapMarkers.push({ position: { lat: parseFloat(this.ticket.jobEndLatitude), lng: parseFloat(this.ticket.jobEndLongitude) }, clickable: true, icon: 'completed' })
    }
    if (this.ticket.afeDetails.latitude) {
      this.mapMarkers.push({ position: { lat: parseFloat(this.ticket.afeDetails.latitude), lng: parseFloat(this.ticket.afeDetails.longitude) }, clickable: true, icon: 'worksite' })
    }

    this.ticketData = { status: this.ticket.jobStatus, startTime: this.ticket.jobDetails.startTime }; // placeholder data

    this.totalTimeVal = this.convertMinutesToHoursAndMinutes(this.totalPhaseTime);

    if (this.ticket.attachDocURL !== null && this.ticket.attachDocURL.trim().length > 0) {
      let attachment = { name: this.ticket.attachDocURL.split('/').pop(), url: this.ticket.attachDocURL };
      this.ticketAttachments.push(attachment);
    }
  }

  setRouteDetailsExpanded(expanded: boolean) {
    this.routeDetailsExpanded = expanded;
  }

  roundToQuarterHour(minutes) {

    let time = ((minutes / 15 | 0) * 15);
    return time / 60;
  }

  openGoogleMapLink(jobTimeType) {

    window.open("http://maps.google.com/?q=" + jobTimeType.startlatitude + "," + jobTimeType.startlongitude, "_blank");
  }
  openImageInNewTab(imgLink) {

    window.open(imgLink, "_blank");
  }


  getDifferenceBetweenTimes(startTime, endTime) {

    if (startTime && endTime) {
      let endTimeVal = new Date(endTime).valueOf();
      let startTimeVal = new Date(startTime).valueOf();
      let timeDiff = (endTimeVal - startTimeVal) / 1000 / 60;
      let tempTime = Math.round((timeDiff + Number.EPSILON) * 100) / 100;
      return tempTime;
    }
  }
  convertMinutesToHoursAndMinutes(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    return { hours: rhours, minutes: rminutes }
  }

  approveTicket() {
  }


  /**
   * Updates the ticket values associated with selecting a new foreman.
   *
   * @param selectedValue
   */
  updateForemanSelection(selectedValue): void {
    let newForeman = this.utils.findObject(this.foremen, 'businessPersonID', selectedValue);
    this.ticket.foremanDetails = newForeman;
    this.ticket.foremanDetails.ID = newForeman.businessPersonID;
    this.ticket.personName = newForeman.personName;

  }

  /**
   * Sets up the correct UI components for adding a scan item.
   *
   * @param $event
   * @param expansionPanel
   */
  addScanItem($event: Event, expansionPanel: MatExpansionPanel): void {
    $event.stopPropagation();
    this.openExpansionPanel(expansionPanel);
    this.openScanItemModal(null);
  }

  /**
   * Opens the scan item modal to add or edit a scan item on the ticket.
   * Pass null to add a new scan item.
   *
   * @param scanItemIndex
   */
  openScanItemModal(scanItemIndex: number): void {
    let isAdd: boolean = true;
    let scanItem: ScanItem;

    // Determine if we are adding a new item to the ticket, or updating an existing item.
    if (scanItemIndex == null) {
      scanItem = new ScanItem();
      scanItem.itemQRCode = new ItemQRCode();
    } else {
      isAdd = false;
      scanItem = this.ticket.scanItems[scanItemIndex];
    }

    // Set the data to pass to the modal.
    let modalData: ScanItemDialogData = {
      isAdd: isAdd,
      businessID: this.ticket.businessTruckerID,
      itemQRCodes: this.itemQRCodes,
      scanItem: scanItem
    };
    let info = {
      width: '550px',
      data: modalData
    };

    // Open the modal.
    const dialogRef = this.dialog.open(EditScanItemModal, info);

    // Callback for the modal after it closes, which handles saving the new/updated item.
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // If this is a new scan item on the ticket, push it to the ticket's scan items array.
        if (info.data.isAdd) {
          this.ticket.scanItems.push(info.data.scanItem);
        }

        // If the scan item uses a new itemQRCode, then save the new itemQRCode data. After it's saved, add the newly
        // created item (with generated ID) back to the ticket's scan item, and also to the loaded itemQRCodes array.
        if (info.data.scanItem.itemQRCode.ID == null) {
          this.itemQrCodeService.createItemQrCode(info.data.scanItem.itemQRCode).subscribe(res => {
            info.data.scanItem.itemQRCode = res.data;
            info.data.scanItem.itemQRCodeID = res.data.ID;
            this.itemQRCodes.push(res.data);
            this.itemQRCodes = _.sortBy(this.itemQRCodes, ['itemDescription']);
          });
        }
      }
    });
  }

  /**
   * Confirms the deletion of a scan item from the ticket.
   *
   * @param scanItemIndex
   */
  confirmDeleteScanItem(scanItemIndex: number): void {
    if (scanItemIndex == null)
      return;

    // Get the item from the scan items array.
    let scanItem: ScanItem = this.ticket.scanItems[scanItemIndex];
    let itemName: string = scanItem.itemQRCode.itemDescription + " (Quantity: " + scanItem.quantity + ", Price: $" + scanItem.currentItemPrice + ")";

    // Setup and open the dialog.
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: "Remove Item From Ticket?",
        message: "This action will remove the following item. Are you sure?\r\n" + itemName
      }
    });

    // Handle confirmation of the deletion.
    dialogRef.afterClosed().subscribe(result => {
      if (result) this.deleteScanItem(scanItemIndex);
    });
  }

  /**
   * Deletes a scan item from the ticket.
   * @param scanItemIndex
   */
  deleteScanItem(scanItemIndex: number): void {
    if (scanItemIndex == null)
      return;

    // Remove the item at the given index.
    this.ticket.scanItems.splice(scanItemIndex, 1);
  }

  /**
   * Opens the specified expansion panel if not already opened.
   *
   * @param expansionPanel
   */
  openExpansionPanel(expansionPanel: MatExpansionPanel): void {
    if (!expansionPanel.expanded)
      expansionPanel.open();
  }
}
