import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component'
import { faIcons, faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'manage-region-buttons',
  template: `
    <span>
      <a type="button" class="add-area-btn {{addDisabled}}" title="Add an Area Under This Region" (click)="addSubArea()">
        <fa-icon class="pr-2" [icon]="faPlusCircle"></fa-icon>
      </a>
      <a type="button" class="del-area-btn {{disabled}}" title="Remove this Region and All Sub-areas" (click)="removeFromHierarchy()">
        <fa-icon [icon]="faTrashAlt"></fa-icon>
      </a>
    </span>
  `
})

/**
 * Creates the Edit and Remove buttons for the AG Tree Grid
 */
export class ManageRegionButtons implements ICellRendererAngularComp {
  public params: any;
  public parent: any;
  public cell: any;
  public disabled: string = "ag-action-icon";
  public addDisabled: string = "ag-action-icon";
  public component: any;
  faPlusCircle = faPlusCircle;
  faTrashAlt = faTrashAlt;

  constructor(public dialog: MatDialog) { }

  /**
   * Called when the grid is refreshed
   * @param params
   */
  refresh(params: any): boolean {
    return false;
  }

  /**
   * Assigns the row data and header name to the cell object
   * @param params
   */
  agInit(params: any): void {
    this.params = params;
    if (params.node) {
      this.parent = params.node.context.contextParams.providedBeanInstances.gridOptions.context.componentParent;
    }

    this.cell = { row: params.data, col: params.colDef.headerName };
    if (params.data && params.data.isParent == 1) this.disabled = 'ag-action-icon-disabled';
    else if (params.data && params.data.isParent === 0 && params.data.regionName === params.data.businessRegionArea.fullPath) {
      this.addDisabled = 'ag-action-icon-disabled';
      this.disabled = 'ag-action-icon-disabled';
    }
  }

  /**
   * Not used
   * @param params
   */
  afterGuiAttached?(params: any) { }

  /**
   * Opens the modal to add a sub-area to the hierarchy by calling the parent class
   */
  public addSubArea() {
    this.parent.openEditHierarchy(this.cell)
  }

  /**
   * Removes an element (and it's children) fromt he hierarchy
   */
  public removeFromHierarchy() {
    let title = "Remove Area and all Children?";
    let message = "This action will remove the selected area and all of its children.  This action will not remove the region itself"
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: { title: title, message: message }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.parent.removeBusinessRegion(this.cell);
      }
    });
  }
}
