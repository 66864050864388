import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import {Router} from "@angular/router";

@Component({
  selector: 'edit-auto-reject-button',
  template:`
    <span class="edit-icon-grid-btn" >
      <a title="Edit" (click)="editAutoReject(this.cell.row.id)">
        <fa-icon [icon]="faEdit"></fa-icon>
      </a>
    </span>
  `,
  styleUrls: ['../auto-reject-ticket-list.component.scss'],
})
export class EditAutoRejectButton implements ICellRendererAngularComp {
  public params: any;
  public parent: any;
  public cell: {row: any, col: any};
  faEdit = faEdit;

  constructor(
    private router: Router
  ) {}

    /**
   * Called when the grid is refreshed
   * @param params
   */
  refresh(params: any): boolean {
    return false;
  }

  /**
   * Assigns the row data and header name to the cell object
   * @param params
   */
  agInit(params: any): void {
    this.params = params;
    this.parent = params.context;
    this.cell = { row: params.data, col: params.colDef.headerName };
  }

  editAutoReject(autoRejectID: number) {
    this.router.navigate(['/ticket/auto-reject/edit', autoRejectID]);
  }
}