import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import Roles from '../../shared/helpers/user-role.helper';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faIcons,
  faHome,
  faTicketAlt,
  faMobile,
  faUser,
  faColumns,
  faBuilding,
  faMoneyBill,
  faToolbox,
  faWrench,
  faGlobeAmericas,
  faRedo,
  faPlusCircle,
  faBusinessTime,
  faList,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { Business } from '../../models/business';
import { BusinessService } from '../../operator/business.service';


@Component({
  selector: 'em-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})

export class MenuComponent implements OnInit {
  @Input() menuActive: boolean;
  @Input() viewData: any;
  @Output() loadView: any = new EventEmitter<any>();
  faIcons = faIcons;
  activeBusiness: Business;
  roles = new Roles();
  faHome = faHome;
  faTicketAlt = faTicketAlt;
  faMobile = faMobile;
  faUser = faUser;
  faColumns = faColumns;
  faBuilding = faBuilding;
  faMoneyBill = faMoneyBill;
  faToolbox = faToolbox;
  faWrench = faWrench;
  faGlobeAmericas = faGlobeAmericas;
  faRedo = faRedo;
  faBusinessTime = faBusinessTime;
  faList = faList;
  faPlus = faPlus;

  // Variables for the Menu toggling
  public toggleOperator = true;
  public toggleLanguage = true
  public toggleAutoRejectTickets = true;
  operatorRoutes = ['/user/user-list', '/operator/edit', '/operator/region', '/operator/time-type-aliases'];
  languageRoutes = ['/alias/header-alias', '/alias/mobile-header-alias'];
  ticketRoutes = ['/ticket/reinstate-ticket', '/ticket/review-ticket'];
  autoRejectTicketsRoutes = ['/ticket/auto-reject/list', '/ticket/auto-reject/create'];

  /**
   * Gets the route from the request and determines if the sub navs should be opened or closed
   * @param router
   * @param businessService
   */
  constructor(private router: Router, private businessService: BusinessService) {
    this.toggleOperator = !(this.operatorRoutes.includes(router.url));
    this.toggleLanguage = !(this.languageRoutes.includes(this.router.url));
    this.toggleAutoRejectTickets = !(this.autoRejectTicketsRoutes.includes(this.router.url));
  }

  ngOnInit() {
    this.activeBusiness = JSON.parse(localStorage.getItem('selectedBusiness'));
    this.businessService.activeBusiness.subscribe(business => {
      if (business && business.ID) {
        this.activeBusiness = business;
      }

    })
  }

  /**
   * Determines if the active flag should be set for the current route
   * @param route
   */
  isMenuActive(route: string) {
    if (this.router.url === '/' && route === "/dashboard") return true;
    return route === this.router.url;
  }

  /**
   * Toggles the menus inside the page load
   * @param ele
   */
  toggleMenuGroup(ele) {

    this.toggleOperator = true;
    this.toggleLanguage = true;
    this.toggleAutoRejectTickets = true;

    if (ele === '#sidebarOperatorSubNav') {
      this.toggleOperator = !this.toggleOperator;
    } else if ('#sidebarLanguageSubNav' === ele) {
      this.toggleLanguage = !this.toggleLanguage;
    } else if ('#sidebarAutoRejectTicketsSubNav' === ele) {
      this.toggleAutoRejectTickets =  !this.toggleAutoRejectTickets;
    }
  }

  checkRoleAccess(base: string, role: string) {
    return this.roles[base][role].includes(this.viewData.credentialTypeID);
  }
}

