<span *ngIf="utils.editFieldName!=inputId" (click)="startFieldEdit();">{{displayedValue}}</span>

<mat-form-field *ngIf="utils.editFieldName==inputId">
  <!-- <mat-label>{{label}}</mat-label> -->

  <input *ngIf="inputType!=InputType.TextArea && inputType!=InputType.Select" #editField matInput
    [ngModel]="selectedValue" (ngModelChange)="changeModel($event)" (blur)="utils.stopFieldEdit()"
    type="{{inputType.valueOf()}}">

  <textarea *ngIf="inputType==InputType.TextArea" #editField matInput [ngModel]="selectedValue"
    (ngModelChange)="changeModel($event)" (blur)="utils.stopFieldEdit()"></textarea>

  <mat-select *ngIf="inputType==InputType.Select" #editSelect [ngModel]="selectedValue"
    (ngModelChange)="changeModel($event)" (blur)="utils.stopFieldEdit()">
    <mat-option *ngFor="let item of itemList" [value]="item[listValueKey]">
      {{item[listDisplayKey]}}
    </mat-option>
  </mat-select>
</mat-form-field>