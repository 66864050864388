import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { Business } from '../../models/business';
import { debounceTime, tap, switchMap, finalize, take } from 'rxjs/operators';
import { BusinessService } from '../business.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';

@Component({
  selector: 'business-select',
  templateUrl: './business-select.component.html',
  styleUrls: ['./business-select.component.scss']
})

/**
 * Defines the Business Selector Component at the top of the screen.
 * When a business is selected, the business is stored in local storage for
 * easy navigation form page to page
 */
export class BusinessSelectComponent implements OnInit {

  // ----------- Members -----------------
  businessSearchCtrl = new FormControl();
  public activeBusiness = new Business();
  businesses = [];
  isBusinessLoading = false;
  errorMsg: string;

  constructor(private businessService: BusinessService, private router: Router) { }
  ngOnInit(): void {
    // Subscribe to the business search module
    this.subscribeToBusinessSearch();

    // Retrive the active business from local storage
    let tempBusiness = localStorage.getItem('selectedBusiness');

    this.activeBusiness = tempBusiness ? JSON.parse(tempBusiness) : { businessName: "Select a Business to Edit" };
    this.businesses = [this.activeBusiness]
    this.businessSearchCtrl.setValue(this.activeBusiness);
  }

  // ----------- Functions -----------------
  /**
   * Reloads the page after the business has been changed
   * @param uri
   */
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  /**
   * Return a blank here.  The placeholder value is set to the selected value.
   * @param value
   */
  displayBusiness(value) {
    if (value) {
      return value.businessName;
    }
  }

  /**
   * Controls the output when a business is selected.  The value should be stored
   * into local cache
   * @param event
   */
  businessSelected(event) {
    this.activeBusiness = event.option.value;
    localStorage.setItem('selectedBusiness', JSON.stringify(this.activeBusiness));
    this.businessService.setActiveBusiness(this.activeBusiness);
    // Reload the current page
    this.redirectTo(this.router.url);
  }

  /**
   * Utilze the existing business search to load the appropriate businesssearch
   */
  subscribeToBusinessSearch() {
    this.businessSearchCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.isBusinessLoading = true;
        }),
        switchMap(value =>
          this.businessSearch(value)
            .pipe(
              finalize(() => {
                this.isBusinessLoading = false;
              }),
            )
        ),
        take(1)
      )
      .subscribe(result => {
        if (result.data && result.data.length === 0) {
          this.errorMsg = 'No businesses found, please search again.';
          this.businesses = [];
          this.isBusinessLoading = false;
        } else {
          this.businesses = result.data

        }
        this.subscribeToBusinessSearch()
      });
  }

  /**
   * Business Search Subscription
   * @param searchText
   */
  businessSearch(searchText) {
    return this.businessService.searchBusinessName(searchText);
  }
}
