import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { faSearch, faEdit, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { AutoReject } from "../../../models/autoReject";
import { AutoRejectTicketService } from "../services/auto-reject-ticket.service";
import { AutoRejectTicketGrid } from "./grid/auto-reject-ticket-grid";
import { GridOptions } from "ag-grid-community";
import { Business } from "../../../models/business";

@Component({
  templateUrl: './auto-reject-ticket-list.component.html',
  styleUrls: ['./auto-reject-ticket-list.component.scss']

})
export class AutoRejectTicketListComponent implements OnInit {
  searchTerm = '';
  page = 1;
  faSearch = faSearch;
  faEdit = faEdit;
  faPlusCircle = faPlusCircle;
  length = 0;
  pageSize = 50;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  autoRejects: AutoReject[] = []
  loading = true;
  activeBusiness: Business = new Business();

  autoRejectTicketGridOptions: GridOptions;
  rowData: any[] = [];

  // MatPaginator Output
  pageEvent: PageEvent;
  constructor(
    private autoRejectTicketService: AutoRejectTicketService,
    private router: Router,
  ) {
    this.activeBusiness = JSON.parse(localStorage.getItem('selectedBusiness'));
  }

  ngOnInit(): void {
    this.autoRejectSearch();
    this.autoRejectTicketGridOptions = AutoRejectTicketGrid.loadGridOptions(this);
  }

  autoRejectSearch() {
    this.loading = true;
    this.autoRejectTicketService.getPaginatedAutoRejects(this.searchTerm, this.page, this.pageSize).subscribe(result => {
      this.autoRejects = result.data
      this.rowData = result.data;
      this.length = result.count;
      this.loading = false;
    }, error => {}, () => {
      this.loading = false;
    });
  }
  paginationChange(event) {
    this.page = event.pageIndex + 1;  //Laravel uses literal page numbers (starting at 1), Angular uses array indexes (starting at 0)
    this.pageSize = event.pageSize;
    this.autoRejectSearch();

    return this.pageEvent;
  }
  editAutoReject(autoRejectID: number) {
    this.router.navigate(['/ticket/auto-reject/edit', autoRejectID]);
  }
  createUser() {
    this.router.navigate(['/ticket/auto-reject/create']);
  }
}
