import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ItemQRCode } from "../models/itemQRCode";

@Injectable({
  providedIn: 'root'
})
export class ItemQrCodeService {

  constructor(private http: HttpClient) {

  }

  getItemQrCodeByBusiness(businessID: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/itemQrCode/${businessID}/inventory`);
  }
  createItemQrCode(itemQRCode: ItemQRCode): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/itemQrCode`, itemQRCode);
  }
}
