import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { Business } from '../../models/business';
import { debounceTime, tap, switchMap, finalize, take } from 'rxjs/operators';
import { UserService } from '../../user/user.service';
import { ToastrService } from 'ngx-toastr';
import { BusinessService } from '../business.service';
import * as _ from 'lodash';
import { EquipmentType } from '../../models/equipmentType.';
import { FontAwesomeModule, FaIconLibrary, FaIconLibraryInterface } from '@fortawesome/angular-fontawesome';
import { faPlus, faSave, faTruckPickup, faTrashAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderTitleService } from '../../layout/header-title.service';

@Component({
  templateUrl: './operator-services.component.html',
  styleUrls: ['./operator-services.component.scss']
})
export class OperatorServicesComponent implements OnInit {
  businessSearchCtrl = new FormControl();
  activeBusiness = new Business();
  businesses = [];
  isBusinessLoading = false;
  isServiceLoading = false;
  errorMsg: string;
  equipmentTypes: EquipmentType[] = [];
  truckTypes: EquipmentType[] = [];
  serviceTypes: EquipmentType[] = [];
  truckFormGroup = new FormGroup({});
  faPlusCircle = faPlusCircle;
  faSave = faSave;
  faTrashAlt = faTrashAlt;
  alwaysFloat = 'always';
  public title = ['Business', 'Services'];

  trucksValid = true;
  matcher = new ErrorStateMatcher();
  allService = { ID: -1, equipmentType: 'All', level: 0, businessID: this.activeBusiness.ID };
  testRoute;
  servicesLoaded = false;

  constructor(private userService: UserService, private toastr: ToastrService, private businessService: BusinessService,
    private activatedRoute: ActivatedRoute, private headerTitleService: HeaderTitleService) { }

  ngOnInit(): void {
    this.headerTitleService.setTitle(this.title);
    this.activeBusiness = JSON.parse(localStorage.getItem('selectedBusiness'));

    this.testRoute = this.activatedRoute;
    this.activatedRoute.data.subscribe((result) => {
      this.equipmentTypes = result.services.data;

      _.filter(this.equipmentTypes, { level: 1 }).forEach(truck => {
        truck.services = [];
        if (truck.parents.length > 0) {
          truck.parents.forEach(parent => {
            const service = _.filter(this.equipmentTypes, { ID: parent });
            if (service.length === 1) {
              truck.services.push(service[0]);
            }
          });
        } else {
          truck.services.push(this.allService);
        }
        this.truckTypes.push(truck);

      });
      if (this.truckTypes.length === 0) {
        this.addNewTruckType();
      }
      this.serviceTypes = _.filter(this.equipmentTypes, { level: 0 });
      this.servicesLoaded = true;

    });
  }
  serviceSelected(event, truck) {
    if (event.option.value) {

      if (event.option.value.equipmentType === 'All') {
        truck.services = [];
        truck.services.push(this.allService);
      } else {
        _.pull(truck.services, this.allService)
        truck.services.push(event.option.value);
      }

    }
  }
  addNewTruckType() {
    const newTruck = new EquipmentType();
    newTruck.ID = null;
    newTruck.level = 1;
    newTruck.services = [this.allService];
    newTruck.businessID = this.activeBusiness.ID;
    newTruck.restrictedLoad = false;
    newTruck.isObsTemp = true;
    newTruck.isVisible = true;
    newTruck.equipmentType = '';

    this.truckTypes.push(newTruck);
  }
  displayServiceName(value) {
    return value.equipmentType;
  }
  deleteTruckType(truckIndex) {
    if (confirm('Are you sure you want to permanently delete this Truck Type?')) {

      let truckToDelete = _.nth(this.truckTypes, truckIndex);

      this.businessService.deleteEquipmentType(this.activeBusiness.ID, truckToDelete.ID).subscribe(result => {
        let deletedTruck = _.pullAt(this.truckTypes, truckIndex);
      }, error => {
        this.toastr.error("You cannot delete this Truck Type because it has already been scheduled for an order.")
      },
        () => {
          // 'onCompleted' callback.
          // No errors, route to new page here
        });
    }
  }
  removeService(truck, service) {

    _.pull(truck.services, service);

    if (truck.services.length === 0) {
      truck.services.push(this.allService);
    }
  }
  filteredServices(value: string, truck: EquipmentType) {
    const filterValue = value.toLowerCase();
    return this.serviceTypes.filter(service =>
      (service.equipmentType.toLowerCase().indexOf(filterValue) > -1) && (!truck.services.includes(service)));
  }
  checkUniqueTruckName(name: string): boolean {
    if (name && name.length > 3) {
      const lowerName = name.toLowerCase();
      const matches = _.filter(this.truckTypes, function (truck) {
        if (truck.equipmentType) {
          return truck.equipmentType.toLowerCase() === lowerName
        }
      });
      return matches.length > 1;
    }
  }
  checkAllUniqeTruckNames() {
    let validIteration = true;
    this.truckTypes.forEach(truck => {
      if (truck.equipmentType && truck.equipmentType.length > 3) {
        const lowerName = truck.equipmentType.toLowerCase();
        const matches = _.filter(this.truckTypes, function (truck) {
          if (truck.equipmentType) {
            return truck.equipmentType.toLowerCase() === lowerName
          }
        });
        if (matches.length > 1) {
          validIteration = false;
        }
      }
    });

    this.trucksValid = validIteration;
  }
  updateOperator() {
    this.businessService.updateBusiness(this.activeBusiness).subscribe(result => {
      this.toastr.success(`Operator: ${this.activeBusiness.businessName} successfully updated!`);
    })
    this.truckTypes.forEach(truck => {
      truck.parents = [];

      truck.services.forEach(service => {
        if (service.equipmentType !== 'All') {
          truck.parents.push(service.ID);
        }
      })
      if (!truck.ID) {
        this.businessService.createEquipmentTypeByID(this.activeBusiness.ID, truck).subscribe(result => {
          truck = result.data;
          truck.ID = result.data.ID;
        });
      } else {
        this.businessService.updateEquipmentType(this.activeBusiness.ID, truck).subscribe(() => {
          // this.toastr.success(`Truck: ${truck.equipmentType} successfully updated!`);
        })
      };
    })
  }
};
