// export class Business {
//   ID: number;
//   businessName: string;
//   ticketOption: number;
//   truckTypeRequired: boolean;
//   businessTypeID: number
// }
export class Business {
  ID: number;
  businessName: string;
  truckTypeRequired?: any;
  businessTypeID: number;
  address1?: string;
  address2?: any;
  address3?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  phone?: string;
  ticketOption?: number;
}
