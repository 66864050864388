<div class="container">
  <mat-card class="col-lg-12">
    <mat-card-title>
      Auto Reject Search
    </mat-card-title>
    <mat-card-content>
      <!-- <button class="btn btn-primary btn-accent" (click)="createUser()">
        <fa-icon [icon]="faPlusCircle"></fa-icon> Create
      </button> -->
      <div class="btn-section">
        <btn [btnName]="'Create'" 
          [tooltipMessageOptions]="{ default: 'create new auto reject' }"
          (click)="createUser()"
        >
          <!-- <fa-icon [icon]="faPlusCircle"></fa-icon> -->
        </btn>
      </div>
      <ag-grid-angular #autoRejectTicketGrid 
        id="autoRejectTicketGrid-1" 
        class="ag-theme-alpine"
        [rowData]="rowData" 
        [gridOptions]="autoRejectTicketGridOptions">
      </ag-grid-angular>

      <!-- <form>
        <div class="row mb-2">
          <div class="col-lg-10"> -->
            <!--<mat-form-field class="example-full-width">
              <mat-label>Search</mat-label>
              <input matInput name="searchTerm" [(ngModel)]="searchTerm">
            </mat-form-field>
            <button class="btn btn-primary btn-accent searchButton"
                    (click)="autoRejectSearch()"
                    [disabled]="loading"
            >
              <fa-icon [icon]="faSearch"></fa-icon>
            </button>-->
          <!-- </div>
          <div class="col-lg-2">
            <button class="btn btn-primary btn-accent" (click)="createUser()">
              <fa-icon [icon]="faPlusCircle"></fa-icon> Create
            </button>
          </div>
        </div> -->
        <!-- <div class="row">
          <div class="col-lg-12">
            <table class="table table-striped">
              <thead>
              <tr class="col-lg-10">
                <th style="width:30%;">Customer</th>
                <th style="width:30%;">Rejector</th>
                <th style="width:30%;">Service</th>
                <th style="width:10%"></th>
              </tr>
              </thead>

              <tbody>
                <tr *ngFor="let autoReject of autoRejects" class="col-lg-10">
                  <td style="width:30%;"> {{autoReject.customer.businessName}}</td>
                  <td style="width:30%;"> {{autoReject.rejector.person.personName}}</td>
                  <td style="width:30%;"> {{autoReject.service.equipmentName}}</td>
                  <td style="width:10%;" class="edit">
                    <fa-icon (click)="editAutoReject(autoReject.id)" [icon]="faEdit"></fa-icon>
                  </td>

                </tr>
              </tbody>
            </table>
            <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                           (page)="pageEvent = paginationChange($event)">
            </mat-paginator>
          </div>
        </div> -->
      <!-- </form> -->
    </mat-card-content>
  </mat-card>
</div>
