<h3 mat-dialog-title>
  {{isAdd ? 'Add' : 'Edit'}} Item
</h3>
<form [formGroup]="editScanItemForm" autocomplete="off" novalidate fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
    <mat-dialog-content>
      <div class="row" *ngIf="!isNewItem">
        <div class="col-lg-{{isAdd ? '8' : '12'}}">
          <mat-form-field>
            <mat-select required placeholder="{{isAdd ? 'Select Item' : 'Item Description'}}" name="itemID" formControlName="itemID" [value]="data.scanItem.ID">
              <mat-option *ngFor="let item of itemQRCodes"  [value]="item.ID">{{item.itemDescription}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-2 text-center lh-56" *ngIf="isAdd">OR</div>
        <div class="col-lg-2" *ngIf="isAdd">
          <button mat-button class="btn btn-accent w-100" (click)="swapItemType()">New</button>
        </div>
      </div>
      <div class="row" *ngIf="isNewItem">
        <div class="col-lg-{{isAdd ? '7' : '12'}}">
          <mat-form-field>
            <input matInput required placeholder="Item Description" name="itemDescription" formControlName="itemDescription" />
          </mat-form-field>
        </div>
        <div class="col-lg-2 text-center lh-56" *ngIf="isAdd">OR</div>
        <div class="col-lg-3" *ngIf="isAdd">
          <button mat-button class="btn btn-accent w-100" (click)="swapItemType()">Existing</button>
        </div>
      </div>
      <div class="row" *ngIf="isAdd">
        <div class="col-lg-12">
          <mat-form-field>
            <input matInput required placeholder="Price Per Quantity" name="itemPrice" formControlName="itemPrice" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <mat-form-field>
            <input matInput required placeholder="BOL Quantity" name="itemQuantity" formControlName="itemQuantity" />
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="btn btn-secondary" mat-button (click)="cancelModalEdit()">Cancel</button>
      <button class="ml-auto btn btn-accent" mat-button [mat-dialog-close]="true" [disabled]="!editScanItemForm.valid">Save</button>
    </mat-dialog-actions>
</form>

