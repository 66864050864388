import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { Routes, RouterModule } from '@angular/router';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { UserModule } from './user/user.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TicketModule } from './ticket/ticket.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { faSquare, faCheckSquare, faSearch, faPlusSquare, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { AuthModule } from './auth/auth.module'
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { AliasModule } from './alias/alias.module';
import { ToastrModule } from 'ngx-toastr';
import { OperatorModule } from './operator/operator.module';
import { AgGridModule } from 'ag-grid-angular';
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey("For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-18_July_2020_[v2]_MTU5NTAzMDQwMDAwMA==3f8c549812d7041f7927945951a24b19");
import { ConfirmationDialogComponent } from "./shared/components/confirmation-dialog/confirmation-dialog.component"
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { ReviewTicketComponent } from './ticket/review-ticket/review-ticket.component';
import { createCustomElement } from '@angular/elements';
import { RippleGlobalOptions, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';

import { Globals } from '../globals';
import { DataValidationModule } from './data-validation/data-validation.module'
import { LibSharedModule } from 'lib-shared';
const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  }
];
export function tokenGetter() {
  return localStorage.getItem("access_token");
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FontAwesomeModule,
    AppRoutingModule,
    LayoutModule,
    DashboardModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    UserModule,
    AliasModule,
    ReactiveFormsModule,
    HttpClientModule,
    TicketModule,
    AngularMaterialModule,
    AngularFileUploaderModule,
    MatDialogModule,
    MatButtonModule,
    AgGridModule.withComponents([]),
    BrowserAnimationsModule,
    FormsModule,
    AuthModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["engagemobilize.rocks", "localhost"],
        blacklistedRoutes: []
      }
    }),
    OperatorModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gMapsApiKey
    }),
    DataValidationModule,
    LibSharedModule.forRoot({ apiURL: environment.apiUrl, invoiceApiUrl: environment.invoiceApiUrl })

  ],
  entryComponents: [
    AppComponent,
    ConfirmationDialogComponent,
    // ReviewTicketComponent
  ],
  exports: [
    AngularMaterialModule,
    AngularFileUploaderModule,
    FormsModule,
    AgGridModule
  ],
  providers: [HttpClientModule,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APP_BASE_HREF, useValue: '/' },
    Globals
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private library: FaIconLibrary, injector: Injector) {
    library.addIcons(faSquare, faCheckSquare, faSquare, faCheckSquare, faSearch, faPlusSquare, faPlus);

    // const custom = createCustomElement(ReviewTicketComponent, { injector: injector });
    // customElements.define('review-ticket', custom);
  }
  ngDoBootstrap() { }
}


