<div class="container">
  <form>
    <div class="col-lg-12">
      <div class="row header-filters">
        <div class="col-lg-4 form-group">
          <label for="jobNumber" class="form-label">Job Number</label>
          <input id="jobNumber" class="form-control" name="jobNumber" placeholder="Enter" (keyup)="jobKeyUp(jobNumber)"
            [(ngModel)]="jobNumber">
          <!-- MOB2683884 -->
        </div>

        <div class="col-lg-4 form-group">
          <label for="ticketNumber" class="form-label">Ticket ID</label>
          <input id="ticketNumber" class="form-control" name="ticketNumber" placeholder="Enter" type="number"
            (keyup)="ticketKeyUp(ticketID)" [(ngModel)]="ticketID">
        </div>
      </div>
    </div>

    <div class="row results-header">
      <div class="col-lg-2"> {{tickets.length}} results</div>
    </div>
    <div *ngFor="let ticket of tickets;let index = index" [ngClass]="{'alt-bg': 0 === index % 2}  " class="result">
      <div class="row title-row">
        <div class="col-lg-3">
          <a class="title-link" [routerLink]="['/ticket/review-ticket/',ticket.ID]">
            {{ticket.job.businessEquipmentName}}</a>
        </div>
        <div class="col-lg-3"><strong>Job #: {{ticket.job.jobNo}}</strong></div>
        <div class="col-lg-3"><strong>Total:</strong> {{ticket.amount | currency}}</div>
        <div class="col-lg-3"><strong>Ticket Status:</strong> {{ticket.ticketStatus}}</div>
      </div>
      <div class="row result-detail-row">
        <div class="col-lg-3"><strong>To Location:</strong> {{ticket.job.afeWellSite}}</div>
        <div class="col-lg-3"><strong>Cost Code:</strong> {{ticket.job.costCode}}</div>
        <div class="col-lg-3"><strong>Vendor:</strong> {{ticket.job.dispatchName}}</div>
        <div class="col-lg-3"><a><button class="btn btn-primary btn-small"
              (click)="reinstateTicketToForeman(ticket.ID)">Reinstate
              To
              Foreman</button></a></div>
      </div>
      <div class="row result-detail-row">
        <div class="col-lg-3"><strong>Notes:</strong> {{ticket.job.notes}}</div>
        <div class="col-lg-3"><strong>Driver:</strong> {{ticket.job.driverName}}</div>
        <div class="col-lg-3"></div>
        <div class="col-lg-3"><a><button class="btn btn-secondary btn-small"
              (click)="reinstateTicketToDispatch(ticket.ID)">Reinstate To
              Dispatch</button></a></div>
      </div>
    </div>
  </form>
</div>
