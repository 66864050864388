import { Injectable } from '@angular/core';
import { Business } from '../../models/business';
import { BusinessService } from '../../operator/business.service';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { EquipmentType } from '../../models/equipmentType.';
import { TicketService } from '../../ticket/ticket.service';
import { TicketDetail } from '../../models/ticketDetail';

@Injectable({
  providedIn: 'root'
})
export class TicketDetailResolver implements Resolve<Observable<TicketDetail>>  {

  constructor(private ticketService: TicketService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<TicketDetail> {

    let ticketID = parseInt(route.paramMap.get('id'));
    return this.ticketService.getTicketDetailsByID(ticketID);
  }
}
