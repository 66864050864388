import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AngularMaterialModule } from '../angular-material.module';
import { LoginComponent } from './login/login.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';

@NgModule({
  declarations: [LoginComponent, UpdatePasswordComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AngularMaterialModule,
    ReactiveFormsModule
  ]
})
export class AuthModule { }
