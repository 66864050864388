<div class="login-container">
  <mat-card class="login-card">
    <div class="row">
      <div class="col text-center">
        <img src="https://engage-m.com/bootstrap/img/app/black//E.png" alt="Engage" class="logo icon-size-small">
      </div>
    </div>
    <div class="row text-center">
      <div class="col text-center">
        <h2>Engage</h2>
      </div>
    </div>
    <div class="row text-center">
      <div class="col text-center">
        <h3>Administration</h3>
      </div>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col text-center">
          <mat-form-field>
            <mat-label>Username</mat-label>
            <input matInput formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <div *ngIf="f.username.errors.required">Username is required</div>
            </div>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput formControlName="password" type="password"
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <div class="form-group">
            <button [disabled]="loading" type="submit" class="btn btn-primary btn-small btn-accent">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Login
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-card>
</div>
