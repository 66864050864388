import { Component, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { faSearch, faEdit, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GridOptions } from 'ag-grid-community';
import { BusinessEquipment } from "dist/lib-shared/public-api";
import { ToastrService } from "ngx-toastr";
import { Business } from "../../models/business";
import { BusinessService } from "../../operator/business.service";
import { User } from "../../user/user";
import { DataValidationService } from "../data-validation.service";
import { ScadaValidationFormComponent } from "../scada-validation-form/scada-validation-form.component";
import { ScadaValidationGrid } from "./scada-validation-grid"
@Component({
  templateUrl: './scada-validation-list.component.html',
  styleUrls: ['./scada-validation-list.component.scss']

})
export class ScadaValidationListComponent implements OnInit {
  searchTerm = '';
  page = 1;
  faSearch = faSearch;
  faEdit = faEdit;
  faPlusCircle = faPlusCircle;
  length = 0;
  pageSize = 100;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  rowData = [];
  loading = true;
  scadaValidationGridOptions: GridOptions;
  activeBusiness: Business;
  serviceContractors: Business[] = [];
  // MatPaginator Output
  pageEvent: PageEvent;
  businessEquipments: BusinessEquipment[] = [];
  foremen: User[] = [];
  constructor(

    private router: Router,
    private dataValidationService: DataValidationService,
    private modalService: NgbModal,
    private businessService: BusinessService,
    private toastr: ToastrService,

  ) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('selectedBusiness')) {
      this.activeBusiness = JSON.parse(localStorage.getItem('selectedBusiness'));
    }
    this.scadaValidationGridOptions = ScadaValidationGrid.loadGridOptions(this, this.dataValidationService, this.activeBusiness.ID);
    this.businessService.getServiceContractors(this.activeBusiness.ID)
      .subscribe(result => {
        this.serviceContractors = result.data;
      })

    this.businessService.getForemans(this.activeBusiness.ID)
      .subscribe(result => {
        this.foremen = result.data;
      })

  }

  autoRejectSearch() {
    this.loading = true;
    // this.autoRejectTicketService.getPaginatedAutoRejects(this.searchTerm, this.page, this.pageSize).subscribe(result => {
    //   this.autoRejects = result.data
    //   this.length = result.count;

    //   this.loading = false;
    // }, error => { }, () => {
    //   this.loading = false;
    // });
  }
  paginationChange(event) {
    this.page = event.pageIndex + 1;  //Laravel uses literal page numbers (starting at 1), Angular uses array indexes (starting at 0)
    this.pageSize = event.pageSize;
    this.autoRejectSearch();

    return this.pageEvent;
  }


  openScadaValidationModal(editItem?) {

    const modalRef = this.modalService.open(ScadaValidationFormComponent, { windowClass: 'modal-800' });
    modalRef.componentInstance.serviceContractors = this.serviceContractors;
    modalRef.componentInstance.operator = this.activeBusiness;
    modalRef.componentInstance.businessEquipments = this.businessEquipments;
    modalRef.componentInstance.foremen = this.foremen;

    if (editItem.row) {
      modalRef.componentInstance.validationData = { ...editItem.row };
    }
    modalRef.result.then((result) => {
      if (result) {
        ScadaValidationGrid.refreshCells()
      }
    });
  }

  deleteItem(item) {
    this.dataValidationService.deleteFlowCheck(item.ID).subscribe(result => {
      ScadaValidationGrid.refreshCells()
      this.toastr.success('Setting successfully deleted!');

    });
  }
}
