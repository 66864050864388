<div class="container">
  <mat-card class="col-lg-12">
    <mat-card-title>
      {{createOrModify}}
    </mat-card-title>
    <div class="row">
      <div class="col-xs-4">
        <mat-label>Password requirements:</mat-label>
        <button mat-icon-button #tooltip="matTooltip"
          matTooltip="Password must be at least 6 characters.&#13;Password must contain one number.&#13;Password must contain one lower case character.&#13;Password must contain one upper case character.&#13;Password must contain one special character.&#13;Password cannot match any of last 5 passwords."
          matTooltipPosition="right" matTooltipClass="pwd-tooltip"
          aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
          <mat-icon>help</mat-icon>
        </button>
      </div>
    </div>
    <mat-card-content>
      <form [formGroup]='userForm'>
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="full-width">
              <mat-label>Company</mat-label>
              <mat-select [value]="user.businessID" formControlName="businessID">
                <mat-option *ngFor="let business of businesses" [value]="business.ID">
                  {{business.businessName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="full-width">
              <mat-label>Name</mat-label>
              <input matInput formControlName="personName">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2">
            <mat-form-field class="full-width">
              <mat-label>Username</mat-label>
              <input matInput formControlName="username">
            </mat-form-field>
          </div>
          <div class="col-lg-2">
            <mat-form-field class="full-width">
              <mat-label>Password</mat-label>
              <input matInput (change)="passwordChanged()" formControlName="password" type="password">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="full-width">
              <mat-label>Credential Type</mat-label>
              <mat-select [value]="user.credentialTypeID" formControlName="credentialTypeID">
                <mat-option *ngFor="let credential of credentialTypes" [value]="credential.ID">
                  {{credential.credentialTypeName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2">
            <mat-form-field class="full-width">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email">
            </mat-form-field>
          </div>
          <div class="col-lg-2">
            <mat-form-field class="full-width">
              <mat-label>Phone Number</mat-label>
              <input matInput formControlName="phoneNumber">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="full-width">
              <mat-label>Timezone</mat-label>
              <mat-select [value]="user.credentialTypeID" formControlName="timezoneID">
                <mat-option *ngFor="let timezone of timezones" [value]="timezone.ID">
                  {{timezone.timezoneName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="full-width">
              <mat-label>Person Type</mat-label>
              <mat-select [value]="user.personTypeID" formControlName="personTypeID">
                <mat-option *ngFor="let personType of personTypes" [value]="personType.ID">
                  {{personType.personTypeName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <button class="btn btn-primary btn-accent" (click)="saveUser()" [disabled]="!userForm.valid">
              <fa-icon [icon]="faSave"></fa-icon> {{ this.newUser == true ? 'Save User' : 'Update User' }}
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>