import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

//import { DataValidationModel } from './data-validation.model';

import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../models/apiResponse';
import { map } from 'rxjs/internal/operators/map';
import { FlowcheckConfiguration } from '../models/flowcheckConfiguration';



@Injectable({
  providedIn: 'root'
})
export class DataValidationService {

  constructor(private http: HttpClient) { }

  getFlowCheckByOperator(operatorID: number) {
    return this.http.get<ApiResponse>(environment.apiUrl + '/api/flowcheckconfiguration?businessOperatorId=' + operatorID + '&businessID=' + operatorID);
  }
  updateFlowCheck(flowcheck: FlowcheckConfiguration) {
    return this.http.put<ApiResponse>(environment.apiUrl + '/api/flowcheckconfiguration/' + flowcheck.ID, flowcheck);
  }
  createFlowCheck(flowcheck: FlowcheckConfiguration) {
    return this.http.post<ApiResponse>(environment.apiUrl + '/api/flowcheckconfiguration', flowcheck);
  }
  deleteFlowCheck(ID: number) {
    return this.http.delete<ApiResponse>(environment.apiUrl + '/api/flowcheckconfiguration/' + ID);
  }
}
