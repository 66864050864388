<h3 mat-dialog-title>
    Add a New Business Region
</h3>
<form [formGroup]="editRegionForm" autocomplete="off" novalidate fxLayout="column wrap" fxLayoutAlign="center center"
    fxLayoutGap="10px">
    <mat-dialog-content>
        <mat-form-field>
            <input matInput placeholder="Business Region ID" name="businessRegionID" required
                formControlName="businessRegionID" />
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder=" Region Name" name="regionName" required formControlName="regionName" />
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Description" name="description" required formControlName="description" />
        </mat-form-field>
        <mat-form-field>
            <mat-select required placeholder='Root Business Region?' required name="isParent"
                formControlName="isParent">
                <mat-option value="0">No</mat-option>
                <mat-option value="1">Yes</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button class="btn btn-secondary" mat-button (click)="onNoClick()">No</button>
        <button class="ml-auto btn btn-accent" mat-button [mat-dialog-close]="true"
            [disabled]="!editRegionForm.valid">Yes</button>
    </mat-dialog-actions>
</form>