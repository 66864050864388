import { Injectable, Inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthInterceptor implements HttpInterceptor {
  access_token: string;

  constructor(
    private userService: AuthService,
    private route: ActivatedRoute,
    private router: Router,

  ) {

    if (userService.currentUserValue) {
      this.access_token = userService.currentUserValue.access_token;
    }

    if (!this.access_token) {
      this.router.navigate(['/login']);
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (this.userService.currentUserValue) {
      this.access_token = this.userService.currentUserValue.access_token;
    }

    if (!this.access_token) { //if not authenticated, this is probably a /login call, do not include bearer info
      return next.handle(req);
    }

    /* 
     * Note that an earlier version set the Content-Type header to "application/json", which appears to be unnecessary. 
     * If you choose to specify a Content-Type here, you run the risk of breaking other functionality, such as uploads.
     */
    const clone = req.clone({
      responseType: 'json',
      setHeaders: {
        Authorization: `Bearer ${this.userService.currentUserValue.access_token}`,
        //'Content-Type': 'application/json'
      }
    });

    return next.handle(clone);

  }
}
