import { Injectable } from '@angular/core';
import { Business } from '../../models/business';
import { BusinessService } from '../../operator/business.service';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { EquipmentType } from '../../models/equipmentType.';

@Injectable({
  providedIn: 'root'
})
export class OperatorServicesResolver implements Resolve<Observable<EquipmentType[]>>  {

  constructor(private businessService: BusinessService) { }

  resolve(): Observable<EquipmentType[]> {

    let activeBusiness = JSON.parse(localStorage.getItem('selectedBusiness'));
    if (activeBusiness) {
      return this.businessService.getAllEquipmentTypes(activeBusiness.ID);
    } else {
      return this.businessService.getAllEquipmentTypes(6);
    }

  }
}
