<div class="container">
  <div class="col-lg-12">
    <form *ngIf="this.activeBusiness">
      <div class="row">
        <div *ngIf="newAlias">
          <div class="col-lg-12">
            <p> <strong>Note:</strong> {{this.activeBusiness.businessName}} does not have an existing Header Alias
              record,
              so you will be creating a new one upon saving.</p>
          </div>
        </div>
        <div *ngIf="this.activeBusiness.ID === 10" class="col-lg-2">
          <mat-form-field>
            <mat-label>Engage Admin Ticket Option</mat-label>
            <mat-select [(ngModel)]="ticketOption" name="ticketOption" (selectionChange)="ticketOptionChanged()">
              <mat-option [value]="0">
                0
              </mat-option>
              <mat-option [value]="1">
                1
              </mat-option>
              <mat-option [value]="2">
                2
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row alias-container">
        <div class="alias" *ngFor="let alias of aliasArray ">


          <!-- <div class=""> -->
          <label for="{{alias.aliasKey}}" class="form-label">{{alias.defaultText?alias.defaultText:alias.aliasKey }}
            <div style="display:inline; font-size:10px;" *ngIf="selectedInput === alias.aliasKey" matSuffix>
              <i>{{alias.aliasKey}}</i>
            </div>
          </label>
          <input (click)="selectedInput = alias.aliasKey" (blur)="selectedInput = null" id="{{alias.aliasKey}}"
            class="form-control no-padding full-width" name="{{alias.aliasKey}}" [(ngModel)]="alias.aliasValue">
          <!-- </div> -->


          <!-- <mat-form-field (click)="selectedInput = alias.aliasKey" class="full-width no-padding">
            <mat-label>
              {{alias.defaultText?alias.defaultText:alias.aliasKey }}
            </mat-label>

            <input (blur)="selectedInput = null" id="aliasInput" class="no-padding" matInput name="{{alias.aliasKey}}"
              [(ngModel)]="alias.aliasValue"> -->
          <!-- <div style="font-size:10px;" *ngIf="selectedInput === alias.aliasKey" matSuffix><i>{{alias.aliasKey}}</i>
          </div> -->
          <!-- </mat-form-field> -->
        </div>
      </div>
      <div class="row">
        <div *ngIf="!newAlias" class="col-lg-12 center">
          <button class="btn btn-primary " [disabled]="isLoading" (click)="updateAlias()">
            <fa-icon [icon]="faSave"></fa-icon> Update Alias
          </button>
        </div>
        <div *ngIf="newAlias" class="col-lg-12 center">
          <button class="btn btn-primary" [disabled]="isLoading" (click)="createAlias()">
            <fa-icon [icon]="faSave"></fa-icon> Create Alias
          </button>
        </div>
      </div>
    </form>
    <div *ngIf="!this.activeBusiness">
      Please select a business to view the header alias.
    </div>
  </div>
</div>
