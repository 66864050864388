<agm-map id="afeMap" [latitude]="afeMapLocation.latitude" [longitude]="afeMapLocation.longitude"
  [style.height.px]="mapHeight">
  <!--  -->
  <agm-marker *ngFor="let marker of afeMarkers" [latitude]="marker.position.lat" [longitude]="marker.position.lng"
    [iconUrl]="marker.icon">
    <agm-info-window #window *ngIf="isFirst(marker)">
      <p>{{ ticketData.status }}<br>{{ ticketData.startTime ? getFormattedDate(ticketData.startTime) : '' }}
      </p>
    </agm-info-window>
  </agm-marker>
</agm-map>
