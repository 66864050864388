import { Job } from './job';
import { ItemQRCode } from './itemQRCode';

export class ScanItem {
  ID: number;
  job: Job;
  itemQRCodeID: number;
  quantity: number;
  createdDate: string;
  strap: number;
  typeQuantity: number;
  itemQRCode: ItemQRCode;
  currentItemPrice: number;
}
