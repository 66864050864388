import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import Utils from '../../helpers/utils-helper'

/**
 * Supported input types in this component.
 */
export enum InputType {
  Select = 'select',
  TextArea = 'textarea',
  Text = 'text',
  Number = 'number'
}

@Component({
  selector: 'edit-in-place-input',
  templateUrl: './edit-in-place-input.component.html',
  styleUrls: ['./edit-in-place-input.component.scss']
})

export class EditInPlaceInputComponent implements OnInit {

  @ViewChild('editField') editField: ElementRef;
  @ViewChild('editSelect') editSelect;
  utils = new Utils();
  InputType = InputType;

  /**
   * Input type for this input.
   */
  @Input() inputType: InputType;

  /**
   * ID for this input.
   */
  @Input() inputId: any;

  /**
   * The input's label.
   */
  @Input() label: any;

  /**
   * The business type ids allowed to edit this field.
   */
  @Input() allowedBusinessTypeIds: Array<number>;

  /**
   * The value to use from the model.
   */
  @Input() selectedValue: any;
  @Output() selectedValueChange: EventEmitter<any> = new EventEmitter();

  /**
   * The value to display when not editing.
   */
  @Input() displayedValue: any;

  /**
   * The list of objects to display in a select list.
   */
  @Input() itemList: Array<any>;

  /**
   * The object's key from the item list, corresponding to a newly selected value.
   */
  @Input() listValueKey: string;

  /**
   * The object's key from the item list, corresponding to the value that should be displayed.
   */
  @Input() listDisplayKey: any;

  constructor() {}

  ngOnInit(): void {}

  /**
   * Emits a modelChange event, with $event data from the ngModelChange.
   *
   * @param ngModelChangeEvent
   */
  changeModel(ngModelChangeEvent): void {
    this.selectedValueChange.emit(ngModelChangeEvent);
  }

  /**
   * Starts edit-in-place for the field when clicking the value.
   */
  startFieldEdit(): void {
    // Stops field from being edited if user is not allowed.
    if (!this.utils.isAllowed(this.allowedBusinessTypeIds))
      return;

    // This triggers the display of the edit-in-place field.
    // It must finish initializing/displaying before calling the field edit.
    this.utils.setEditFieldName(this.inputId);

    // This will focus the selected field after it is activated.
    setTimeout(() => {
      this.utils.startFieldEdit(this.inputId, this.editField, this.editSelect);
    }, 0);
  }
}
