import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { User } from './user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  usersSearch(searchTerm, page): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/users?search=${searchTerm}&page=${page}`)
  }
  getUser(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/users/${id}`)
  }
  getAllTimezones(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/timezones`)
  }
  getAllCredentialTypes(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/credentialtypes`)
  }
  getAllPersonTypes(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/persontypes`)
  }
  createUser(user: User): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/users`, user)
  }
  updateUser(user: User): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/users/${user.ID}`, user)
  }
  updatePassword(user: User): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/users/${user.ID}/password`, user)
  }
  getBusinessUsers(businessID: number, businessTypeID: number = 1, perpage: number = 50): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/users?businessid=${businessID}&businesstypeid=${businessTypeID}&perpage=${perpage}`)
  }
}
