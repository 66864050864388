import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { FilterAllPipe } from './pipes/filterAll.pipe';
import { BusinessesResolver } from './resolvers/businesses.resolver';
import { AgmCoreModule } from '@agm/core';
import { AfeMapComponent } from './components/afe-map/afe-map.component';
import { FootInchQuarterSelectComponent } from './components/foot-inch-quarter-select/foot-inch-quarter-select.component';
import { EditInPlaceInputComponent } from './components/edit-in-place-input/edit-in-place-input.component'
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from "@angular/forms";
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileUploadDialogComponent } from './components/file-upload-dialog/file-upload-dialog.component';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


@NgModule({
  declarations: [FilterAllPipe, AfeMapComponent, FileUploadComponent, FileUploadDialogComponent, FootInchQuarterSelectComponent, EditInPlaceInputComponent],
  imports: [CommonModule, AgmCoreModule, AngularFileUploaderModule, FontAwesomeModule, MatFormFieldModule, MatSelectModule, MatInputModule, FormsModule],
  exports: [AfeMapComponent, FileUploadComponent, FootInchQuarterSelectComponent, EditInPlaceInputComponent],
  providers: [CookieService, BusinessesResolver],
})
export class SharedModule { }
