import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MatDialog } from '@angular/material/dialog';
import { faEdit, faIcons, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'comment-buttons',
  template: `
    <span style="padding-right:10px;">
      <a title="Edit Default Item" (click)="editItem($event)">
        <fa-icon class="faEdit" style="font-size:16px; "  [icon]="faEdit"></fa-icon>
      </a>
    </span>
      <span>
      <a title="Delete Comment"  (click)="deleteItem($event)">
        <fa-icon class="pr-2" style="font-size:16px;"  [icon]="faTimesCircle"></fa-icon>
      </a>
    </span>
  `
})

/**
 * Creates the Edit and Remove buttons for the AG Tree Grid
 */
export class ScadaValidationGridActionButtons implements ICellRendererAngularComp {
  public params: any;
  public parent: any;
  public cell: any;
  public component: any;
  faTimesCircle = faTimesCircle;
  faEdit = faEdit;


  constructor(public dialog: MatDialog) { }

  /**
   * Called when the grid is refreshed
   * @param params
   */
  refresh(params: any): boolean {
    return false;
  }

  /**
   * Assigns the row data and header name to the cell object
   * @param params
   */
  agInit(params: any): void {
    this.params = params;
    this.parent = params.context;
    this.cell = { row: params.data, col: params.colDef.headerName };
  }
  public editItem(event: any) {
    event.stopPropagation();
    this.parent.openScadaValidationModal(this.cell);

  }
  public deleteItem(event: any) {
    event.stopPropagation();

    if (!confirm("This will delete the selected Scada Validation Setting. This cannot be undone. Are you sure you want to delete this Default Item?")) return;
    this.parent.deleteItem(this.cell.row);
    this.params.api.refreshCells();
    // CommentUtil.deleteComment(this.parent.data.item.id, this.cell.row.id,
    //   this.parent.commentsGridOptions.commentType, this.parent.invoiceService, this.parent.toastr, this.parent);
  }

  /**
   * Not used
   * @param params
   */
  afterGuiAttached?(params: any) { }
}
