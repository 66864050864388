<div class="container">

  <form [formGroup]="equipmentTypeForm">

    <div class="row">
    </div>

    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-12">
          <h3>Equipment Service Alias</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <label for="equipmentInput">Select a service:</label>
          <input class="full-width" formControlName="equipmentSearch" placeholder="Select a Service" #equipmentInput
            [matAutocomplete]="equipmentService">
          <mat-autocomplete #equipmentService="matAutocomplete" (optionSelected)="serviceSelected($event);"
            [displayWith]="displayFn">
            <!-- <mat-option *ngIf="!truck.services.includes(allService)" [value]="allService">All</mat-option> -->
            <mat-option *ngFor="let service of filteredServices(equipmentInput.value)" [value]="service">
              {{service.equipmentType}}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>

      <div *ngIf="timeTypes" class="row" [formGroup]="masters">
        <div *ngFor="let timeType of timeTypes; let i=index" class="col-lg-2">
          <label for="{{'master-'+ _.camelCase(timeType.timeType)}}" class="form-label"><strong>Master
              {{timeType.timeType}}</strong>
          </label>
          <input id="{{'master-'+ _.camelCase(timeType.timeType)}}" class="form-control no-padding full-width"
            name="{{'master-'+ _.camelCase(timeType.timeType)}}" [formControlName]="_.camelCase(timeType.timeType)">
        </div>
      </div>

      <div *ngIf="selectedEquip" class="row pad-top-15" [formGroup]="aliases">
        <div *ngFor="let timeType of timeTypes" class="col-lg-2">
          <label for="{{_.camelCase(timeType.timeType)}}" class="form-label">{{timeType.timeType}}
          </label>
          <input (blur)="syncSettings()" id="{{_.camelCase(timeType.timeType)}}"
            class="form-control no-padding full-width" name="{{_.camelCase(timeType.timeType)}}"
            [formControlName]="_.camelCase(timeType.timeType)">
        </div>
      </div>
      <div class="row pad-top-15" *ngIf="selectedEquip">
        <div class="col-lg-12 pad-top-15 float-right">

          <a class="pad-right-15 "><button type="button" (click)="saveSettings();"
              class="btn btn-primary btn-small pad-right-15">
              <fa-icon [icon]="faSave"></fa-icon> Save Aliases
            </button></a>

          <a class="pad-right-15 "><button type="button" (click)="copyFromMaster();"
              class="btn btn-secondary btn-small pad-right-15">
              <fa-icon [icon]="faCopy"></fa-icon> Copy From Master Fields
            </button></a>

        </div>
      </div>
    </div>
  </form>
</div>
