import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from '../layout/main-layout/main-layout.component';
import { AuthGuard } from '../shared/helpers/auth.guard';
import { ScadaValidationListComponent } from './scada-validation-list/scada-validation-list.component';


const routes: Routes = [
  {
    path: 'data-validation',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'scada-data-validation-list', component: ScadaValidationListComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DataValidationRoutingModule { }
