import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperatorRoutingModule } from './operator-routing.module';
import { OperatorServicesComponent } from './operator-services/operator-services.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../angular-material.module';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BusinessService } from './business.service';
import { BusinessRegionService } from './business-region/business-region.service';
import { BusinessSelectComponent } from '../operator/business-select/business-select.component';
import { BusinessRegionComponent } from './business-region/business-region.component';
import { AgGridModule } from 'ag-grid-angular';
import { ManageRegionButtons } from './business-region/renderers/manage-region-button'
import { AssociateAreaModal } from './business-region/associate-area-modal/associate-area-modal.component'
import { EditRegionModal } from './business-region/edit-region-modal/edit-region-modal.component';
import { ServicePhaseAliasesComponent } from './service-phase-aliases/service-phase-aliases.component'

@NgModule({
  declarations: [OperatorServicesComponent, BusinessSelectComponent, BusinessRegionComponent, ManageRegionButtons, AssociateAreaModal, EditRegionModal, ServicePhaseAliasesComponent],
  imports: [
    CommonModule,
    OperatorRoutingModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    SharedModule,
    FormsModule,
    FontAwesomeModule,
    AgGridModule.withComponents([
      BusinessRegionComponent,
      ManageRegionButtons
    ])
  ],
  exports: [
    BusinessSelectComponent, ManageRegionButtons, AssociateAreaModal, EditRegionModal
  ],
  providers: [ManageRegionButtons]
})
export class OperatorModule { }
