<div class="container">
    <mat-card class="col-lg-12">
        <mat-card-title>
            <div class="row">
                <div class="col-lg-9">
                    <h4>Total: <span id="totalGridRows">{{rowCount}}</span>&nbsp; <span>Regions</span></h4>
                </div>
                <div class="col-lg-2">
                    <button (click)="addBusinessRegion();" class="btn btn-primary">
                        <fa-icon class="pr-2" [icon]="faPlusCircle"></fa-icon>
                        Add Region
                    </button>
                </div>
                <div class="col-lg-1 pull-right text-right">
                    <mat-menu #appMenu="matMenu">
                        <button mat-menu-item (click)="exportGrid('csv');">
                            <fa-icon class="pr-2" [icon]="faFileCsv"></fa-icon>
                            <span>Export to CSV</span>
                        </button>
                        <button mat-menu-item (click)="exportGrid('excel');">
                            <fa-icon class="pr-2" [icon]="faFileExcel"></fa-icon>
                            <span>Export to Excel</span>
                        </button>
                    </mat-menu>
                    <button mat-icon-button [matMenuTriggerFor]="appMenu">
                        <fa-icon [icon]="faBars"></fa-icon>
                    </button>
                </div>
            </div>
        </mat-card-title>
        <mat-card-content>
            <ag-grid-angular id='businessRegionTable' class="ag-theme-alpine" [rowData]="rowData"
                [gridOptions]="gridOptions">
            </ag-grid-angular>
        </mat-card-content>
    </mat-card>
</div>