import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssocDialogData } from './AssocDialogData';
import { ReactiveFormsModule } from '@angular/forms';
import { AreaSeriesOptions } from 'ag-grid-community';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'associate-area-modal',
  templateUrl: './associate-area-modal.component.html',
  styleUrls: ['./associate-area-modal.component.scss']
})

/**
 * Component for displaying the options for creating a new business Region Area association
 */
export class AssociateAreaModal implements OnInit {
  public areaForm: any;

  constructor(public dialogRef: MatDialogRef<AssociateAreaModal>, @Inject(MAT_DIALOG_DATA) public data: AssocDialogData) { }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.areaForm = new FormGroup({
      area: new FormControl('', [Validators.required]),
    });

    this.areaForm.valueChanges.subscribe(formData => {
      if (formData.area) this.data.area = formData.area;
    });
  }
}
