import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './shared/helpers/auth.guard';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { UpdatePasswordComponent } from 'lib-shared';



const routes: Routes = [
  {
    path: '',

    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'updatePassword', component: UpdatePasswordComponent },
  // { path: 'operator', loadChildren: () => import('./operator/operator.module').then(m => m.OperatorModule) },
  // { path: 'operator', loadChildren: () => import('./operator/operator.module').then(m => m.OperatorModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


