import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegionDialogData } from './RegionDialogData';
import { ReactiveFormsModule } from '@angular/forms';
import { AreaSeriesOptions } from 'ag-grid-community';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'edit-region-modal',
  templateUrl: './edit-region-modal.component.html',
  styleUrls: ['./edit-region-modal.component.scss']
})

/**
 * Component for displaying the options for creating a new business Region Area association
 */
export class EditRegionModal implements OnInit {
  public editRegionForm: any;

  constructor(public dialogRef: MatDialogRef<EditRegionModal>, @Inject(MAT_DIALOG_DATA) public data: RegionDialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.editRegionForm = new FormGroup({
      businessRegionID: new FormControl('', [Validators.required]),
      description: new FormControl(this.data.description, [Validators.required]),
      regionName: new FormControl('', [Validators.required]),
      isParent: new FormControl('', [Validators.required]),
      businessID: new FormControl(),
      isActive: new FormControl(1)
    });

    // Instead of using NG Model (which is deprecated), we need to subscribe to the form changes and store the value
    this.editRegionForm.valueChanges.subscribe(formData => {
      if (formData.description) this.data.description = formData.description;
      if (formData.businessRegionID) this.data.businessRegionID = formData.businessRegionID;
      if (formData.isParent) this.data.isParent = formData.isParent;
      if (formData.regionName) this.data.regionName = formData.regionName;
    });
  }




}
