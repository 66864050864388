<nav class="nav navbar bg-dark">
  <!-- <nav class="nav navbar navbar-expand navbar-dark bg-dark justify-content-start"></nav> -->
  <div class="col-lg-12">
    <div class="row">
      <div class="col-lg-1">
        <a class="navbar-brand ml-2" href="/dashboard" title="Go to Homepage">
          <img class="mr-2 mt-2" src="/assets/engage-logo.svg" alt="Engage Mobilize Logo" />
        </a>
      </div>
      <div class="col-lg-4 pt-4">
        <div class="title-text">
          <span *ngFor="let page of title; let i = index">
            <span *ngIf="(title.length - 1 > i)">
              {{page}} >
            </span>
            <span *ngIf="(title.length - 1 === i)">
              <strong>{{page}}</strong>
            </span>
          </span>
        </div>
      </div>
      <div class="col-lg-2">
        <business-select></business-select>
      </div>
      <div class="col-lg-4 mt-3 text-right">
        <ul class="navbar-nav">
          <li class="nav-item dropdown no-arrow w-100">
            <a type="button" class="nav-link" (click)=toggleDisplayDiv() href="javascript:void(0);" id="userDropdown"
              role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <fa-icon class="pr-2" icon="bell"></fa-icon>
              {{ person.personName }}
            </a>
            <div (document:click)="closeDisplayDiv($event)" id="userpulldown" tabindex="0" [class]="isShowDiv"
              class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
              <a class="dropdown-item" [routerLink]="['/']"><i class="fa fa-eye"></i> Admin Home</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" type="button" (click)="authService.logout();"><i class="fa fa-sign-out"></i>
                Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="menu">
    <em-menu></em-menu>
  </div>
</nav>