<div class="container">

  <form #operatorForm="ngForm">

    <div *ngIf="activeBusiness.ID">
      <div class="row">
        <div class="col-lg-4">
          <h2>Truck Types <button [disabled]="!servicesLoaded" matTooltip="Add new Truck Type"
              aria-label="Add new Truck Type" class="btn addButton" (click)="addNewTruckType()">
              <fa-icon [icon]="faPlusCircle"></fa-icon>
            </button></h2>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <input type="checkbox" name="truckTypeRequired" [(ngModel)]="activeBusiness.truckTypeRequired"> Require
            Truck Type to be set before new ticket can be created
          </div>
        </div>

        <div class="row" *ngFor="let truck of truckTypes; index as i;">
          <div class="col-lg-4">
            <mat-form-field class="full-width" style="padding-top:16px;">
              <mat-label>Name</mat-label>
              <input (keyup)="checkAllUniqeTruckNames()" name="{{i}}" matInput [(ngModel)]="truck.equipmentType">
              <mat-error *ngIf="checkUniqueTruckName(truck.equipmentType)">
                Truck Type name must be unique!
              </mat-error>
            </mat-form-field>
            <mat-error *ngIf="checkUniqueTruckName(truck.equipmentType)">
              Truck Type name must be unique!
            </mat-error>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="full-width">
              <mat-chip-list #chipList aria-label="Select Services">
                <mat-chip *ngFor="let selectedService of truck.services" [selectable]="true" [removable]="true"
                  (removed)="removeService(truck,selectedService)">
                  {{selectedService.equipmentType}}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input placeholder="Services" #truckInput [matAutocomplete]="truckService" [matChipInputFor]="chipList">
                <!-- [formControl]="fruitCtrl" (blur)="truckInput.value = ''"    (matChipInputTokenEnd)="addServiceToTruck($event,truck)" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" -->
                <mat-autocomplete #truckService="matAutocomplete"
                  (optionSelected)="serviceSelected($event, truck); truckInput.value = ''">
                  <mat-option *ngIf="!truck.services.includes(allService)" [value]="allService">All</mat-option>
                  <mat-option *ngFor="let service of filteredServices(truckInput.value, truck)" [value]="service">
                    {{service.equipmentType}}
                  </mat-option>
                </mat-autocomplete>
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div class="col-lg-1">
            <div style="padding-top:16px;"></div>
            <fa-icon class="trash-icon" (click)="deleteTruckType(i)" [icon]="faTrashAlt"></fa-icon>
          </div>
        </div>
      </div>
    </div>

  </form>
  <div class=" button-bar ">
    <div class="row ">
      <div class="col-lg-12">
        <div class="center">
          <button class="btn btn-primary" [disabled]="!activeBusiness.ID || !trucksValid" (click)="updateOperator()">
            <fa-icon [icon]="faSave"></fa-icon> Save Operator
          </button>
        </div>
      </div>
    </div>
  </div>
</div>