import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Business, EquipmentType, TimeTypeService, TimeType, Setting, BusinessService } from 'lib-shared';
import { HeaderTitleService } from '../../layout/header-title.service';
import * as _ from 'lodash';
import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import { faSave, faCopy } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: './service-phase-aliases.component.html',
  styleUrls: ['./service-phase-aliases.component.scss']
})
export class ServicePhaseAliasesComponent implements OnInit {
  activeBusiness = new Business();
  equipmentTypes: EquipmentType[] = [];
  selectedEquip: EquipmentType;
  equipmentControl: FormGroup;
  timeTypes: TimeType[];
  timeTypeSettings: Setting[] = [];
  equipmentTypeForm: FormGroup;
  timeTypeKeys = [];
  _ = _;
  faSave = faSave;
  faCopy = faCopy;
  pageLoaded = false;
  public title = ['Operator', 'Service Phase Aliases'];
  constructor(private headerTitleService: HeaderTitleService, private activatedRoute: ActivatedRoute, private timeTypeService: TimeTypeService, private fb: FormBuilder, private businessService: BusinessService, private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.equipmentTypeForm = this.fb.group({
      equipmentSearch: [''],
      masters: this.fb.group([

      ]),
      aliases: this.fb.group([

      ])
    })

    this.headerTitleService.setTitle(this.title);
    this.activeBusiness = JSON.parse(localStorage.getItem('selectedBusiness'));

    this.timeTypeService.getAllTimeTypes().subscribe(result => {
      if (result && result.data) {
        this.timeTypes = _.sortBy(result.data, [function (o) { return o.timeType }]);

        this.timeTypes.forEach(tt => {
          this.timeTypeKeys.push(_.camelCase(tt.timeType));
          let setting = new Setting();
          setting.name = _.camelCase(tt.timeType);
          this.masters.addControl(setting.name, new FormControl(''));
          this.aliases.addControl(setting.name, new FormControl(''));
          this.timeTypeSettings.push(setting);
        });


      }
    })
    this.activatedRoute.data.subscribe((result) => {

      this.equipmentTypes = result.services.data;
    });

  }
  get masters() {
    return this.equipmentTypeForm.get('masters') as FormGroup;
  }
  get aliases() {
    return this.equipmentTypeForm.get('aliases') as FormGroup;
  }
  filteredServices(value: string) {
    const filterValue = value.toLowerCase();
    return this.equipmentTypes.filter(service =>
      (service.equipmentType.toLowerCase().indexOf(filterValue) > -1));
  }

  serviceSelected(event) {

    if (event.option.value) {

      let keys = Object.keys(this.aliases.controls);
      //reset the inputs
      keys.forEach(ctrl => {
        this.aliases.get(ctrl).setValue(null);
      })
      this.selectedEquip = event.option.value;
      if (!this.selectedEquip.settings) {
        this.selectedEquip.settings = [];
      }
      this.aliases.valueChanges.subscribe(change => {
        if (this.pageLoaded) {
          let test = this.selectedEquip;
          let timeTimeKeys = this.timeTypeKeys;
          _.forIn(change, function (value: string, key: string) {

            let setting = _.find(test.settings, function (s) {
              if (s.name == key) {
                return s;
              }
            });
            if (setting && setting.value == value) {

            }
            else if (setting && setting.value !== value) {
              setting.value = value ? value : setting.value;
              test.isDirty = true;
            }
            else if (timeTimeKeys.indexOf(key) !== -1) {
              test.settings.push({ 'name': key, 'value': value ? value : '' });
              test.isDirty = true;
            }
          })
        }
      });

      if (this.selectedEquip.settings) {
        this.selectedEquip.settings.forEach(s => {
          try {
            let ctrl = this.aliases.get(s.name);
            ctrl.setValue(s.value);
          }
          catch (ex) {
            //we don't actually need to handle this, we just need to not bomb if the alias control doesn't exist yet.
          }
        });
      }

    }
    this.pageLoaded = true;
  }
  syncSettings() {
    //this.aliases.
  }
  displayFn(et?: EquipmentType): string | undefined {
    return et ? et.equipmentType : undefined;
  }
  saveSettings() {
    let equipmentTypes = _.filter(this.equipmentTypes, function (o) { return o.isDirty; });

    equipmentTypes.forEach(et => {
      this.businessService.updateEquipmentTypeSettings(this.activeBusiness.ID, et.ID, et.settings).subscribe(result => {
        this.toastr.success(et.equipmentType + ' successfully updated!')
        et.isDirty = false;
      })
    });

    this.equipmentTypes.forEach(et => {
      et.isDirty = false;
    })


  }
  copyFromMaster() {
    let keys = Object.keys(this.masters.controls);
    //reset the inputs
    let masters = [];
    keys.forEach(ctrl => {
      masters.push(this.masters.get(ctrl));
    })
    let aliasKeys = Object.keys(this.masters.controls);
    aliasKeys.forEach((ctrl, index) => {
      this.aliases.get(ctrl).setValue(masters[index].value);
    });
  }

}
