import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FileuploadService {
    constructor(private http: HttpClient) {

    }
    deleteTicketFile(ticketId: number, filename: string, uploadType: string): Observable<any> {
        let params = new HttpParams()
            .set('filename', filename)
            .set('ticketId', ticketId.toString())
            .set('uploadType', uploadType);

        if (ticketId == null || filename == null || uploadType == null) {
            return throwError("Missing one or more required parameters");
        }

        return this.http.delete(`${environment.apiUrl}/api/upload`, { params: params });
    }
}