import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { TicketService } from '../ticket.service';
import { ReinstateTicketList } from './reinstate-ticket';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HeaderTitleService } from '../../layout/header-title.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
@Component({
  templateUrl: './reinstate-ticket.component.html',
  styleUrls: ['./reinstate-ticket.component.scss']
})
export class ReinstateTicketComponent implements OnInit {

  jobNumber: string;
  ticketID: number;
  faSearch = faSearch;
  tickets: ReinstateTicketList[] = [];
  private jobSubject: Subject<string> = new Subject();
  private ticketSubject: Subject<number> = new Subject();
  private title = ['Ticket', 'Reinstate Ticket'];
  constructor(private ticketService: TicketService, private headerTitleService: HeaderTitleService, private router: Router) { }

  ngOnInit(): void {

    this.headerTitleService.setTitle(this.title)
    // this.ticketService.getTicketsByBusiness(6).subscribe(result => {
    //   this.tickets = result.data;
    // })

    this.jobSubject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {

      if (searchTextValue.length > 6) {
        this.findTicketsByJobNo(searchTextValue)
        this.ticketSubject.next();
      }

      //this.handleSearch(searchTextValue);
    });

    this.ticketSubject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {
      this.findTicketsByID(searchTextValue);
      //this.handleSearch(searchTextValue);
    });
  }
  jobKeyUp(searchTextValue: string) {
    this.jobSubject.next(searchTextValue);
  }
  ticketKeyUp(searchTextValue: number) {
    this.ticketSubject.next(searchTextValue);
  }

  findTicketsByJobNo(jobNo: string) {

    this.ticketService.getTicketsByJobNo(jobNo).subscribe(result => {
      this.tickets = result.data;
    })
  }
  findTicketsByID(ticketID: number) {

    this.ticketService.getTicketsByID(ticketID).subscribe(result => {

      this.tickets = [result.data];
    })
  }
  reinstateTicketToForeman(ticketId: number) {
    this.ticketService.reinstateTicket(ticketId, false).subscribe(result => {
      this.tickets = _.map(this.tickets, function (a) {
        return a.ID === result.data.ID ? result.data : a;
      });

      this.router.navigate([`/ticket/review-ticket/${ticketId}`]);

    })
  }
  reinstateTicketToDispatch(ticketId: number) {
    this.ticketService.reinstateTicket(ticketId, true).subscribe(result => {
      this.tickets = _.map(this.tickets, function (a) {
        return a.ID === result.data.ID ? result.data : a;
      });
      this.router.navigate([`/ticket/review-ticket/${ticketId}`]);
    })
  }
}
