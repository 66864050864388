<div class="container">
  <form [formGroup]="autoRejectForm" (ngSubmit)="onSubmit()">
    <!--People-->
    <div class="row">
      <div class="col-3">
        <h3>People</h3>
      </div>
      <div class="col-1"></div>
      <div class="col-8">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">*Select Customer (Select Service Contractor as Active Business): </label>
              <select formControlName="customerID" class="form-control" (change)="onChangeClient($event)" required>
                <option *ngFor="let operator of operators" value="{{operator.ID}}">
                  {{operator.businessName}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">*Select Rejector: </label>
              <select class="form-control" formControlName="rejectorID" required>
                <option *ngFor="let businessUser of businessUsers" value="{{businessUser.businessPersonID}}">
                  {{businessUser.personName}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <!--Service-->
    <div class="row">
      <div class="col-3">
        <h3>Service</h3>
      </div>

      <div class="col-1"></div>

      <div class="col-8">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">*Select Service: </label>
              <select class="form-control" formControlName="serviceID" required>
                <option *ngFor="let service of businessEquipments" value="{{service.equipmentID}}">
                  {{service.equipmentName}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">*Reject Notes: </label>
              <textarea class="form-control" formControlName="rejectNotes"></textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">*Facility Type: </label>
              <select class="form-control" formControlName="facilityTypeID">
                <option value="null">
                  All
                </option>
                <option *ngFor="let facilityType of facilityTypes" value="{{facilityType.ID}}">
                  {{facilityType.facilityType}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <!--Terminals-->
    <div class="row">
      <div class="col-2">
        <h3>Terminals</h3>
      </div>

      <div class="col-1"></div>

      <div class="col-1 column-title">Reject Null</div>

    </div>

    <div class="row">
      <div class="col-3"></div>
      <div class="col-1 align-self-center">
        <input type="checkbox" formControlName="nullTerminal" style="display: block">
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">Select Terminal(s) Accepted: </label>
              <mat-form-field class="terminals">
                <mat-select formControlName="businessTerminalIDs" multiple>
                  <mat-option *ngFor="let terminal of terminals" value="{{terminal.ID}}">
                    {{terminal.terminal}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <!--Worksite Geofence-->
    <div class="row">
      <div class="col-2">
        <h3>Geofence</h3>
      </div>

      <div class="col-1"></div>
      <div class="col-1 align-self-center">
        <mat-slide-toggle formControlName="terminalGeoFence"></mat-slide-toggle>
      </div>
      
      <div class="col-8">
        <div class="toggle-label first-label">Terminal Geofence Trigger - {{autoRejectForm.value.terminalGeoFence ? 'On' : 'Off'}}</div>
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-3"></div>
      <div class="col-1 align-self-center">
        <mat-slide-toggle formControlName="wellSiteGeoFence"></mat-slide-toggle>
      </div>
    
      <div class="col-8">
        <div class="toggle-label">Work Site Geofence Trigger - {{autoRejectForm.value.wellSiteGeoFence ? 'On' : 'Off'}}</div>
      </div>
    </div>
    
    <hr>

    <!--Units-->
    <div class="row">
      <div class="col-12"><h3>Units</h3></div>
    </div>

    <div class="row">
      <div class="col-2"></div>
      <div class="col-1 column-title">On/Off</div>
      <div class="col-1 column-title">Reject Null</div>
    </div>

    <!-- Volume -->
    <div class="row">
      <div class="col-2"></div>
      <div class="col-1  align-self-center">
        <input
          type="checkbox"
          formControlName="useVolume"
          style="display: block"
        >
      </div>
      <div class="col-1 align-self-center">
        <input
          type="checkbox"
          formControlName="includeNullVolume"
          style="display: block"
        >
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">Volume <br><small>job.volume</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label"> Min: </label>
              <input type="number" step="0.1" formControlName="volumeMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label"> Max: </label>
              <input type="number" step="0.1" formControlName="volumeMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Read Start -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input type="checkbox" formControlName="useReadStart">
      </div>
      <div class="col-1 align-self-center">
        <input type="checkbox" formControlName="includeNullReadStart">
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">Read Start <br><small>job.readStart</small></div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min: </label>
              <input type="number" step="0.1" formControlName="readStartMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max: </label>
              <input type="number" step="0.1" formControlName="readStartMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Read End -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input type="checkbox" formControlName="useReadEnd">
      </div>
      <div class="col-1 align-self-center">
        <input type="checkbox" formControlName="includeNullReadEnd">
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">Read End <br><small>job.readEnd</small></div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min: </label>
              <input type="number" step="0.1" formControlName="readEndMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max: </label>
              <input type="number" step="0.1" formControlName="readEndMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Top Cut -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input type="checkbox" formControlName="useStartTopCut">
      </div>
      <div class="col-1 align-self-center">
        <input type="checkbox" formControlName="includeNullStartTopCut">
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">Start Top Cut <br><small>job.startTopCut</small></div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min: </label>
              <input type="number" step="0.1" formControlName="startTopCutMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max: </label>
              <input type="number" step="0.1" formControlName="startTopCutMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Bottom Cut -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input type="checkbox" formControlName="useStartBottomCut">
      </div>
      <div class="col-1 align-self-center">
        <input type="checkbox" formControlName="includeNullStartBottomCut">
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">Start Bottom Cut <br><small>job.startBottomCut</small></div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min: </label>
              <input type="number" step="0.1" formControlName="startBottomCutMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max: </label>
              <input type="number" step="0.1" formControlName="startBottomCutMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- End Top Cut -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input type="checkbox" formControlName="useEndTopCut">
      </div>
      <div class="col-1 align-self-center">
        <input type="checkbox" formControlName="includeNullEndTopCut">
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">End Top Cut <br><small>job.endTopCut</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min: </label>
              <input type="number" step="0.1" formControlName="endTopCutMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max: </label>
              <input type="number" step="0.1" formControlName="endTopCutMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- End Bottom Cut -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input type="checkbox" formControlName="useEndBottomCut">
      </div>
      <div class="col-1 align-self-center">
        <input type="checkbox" formControlName="includeNullEndBottomCut">
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">End Bottom Cut <br><small>job.endBottomCut</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min: </label>
              <input type="number" step="0.1" formControlName="endBottomCutMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max: </label>
              <input type="number" step="0.1" formControlName="endBottomCutMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Temp -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input type="checkbox" formControlName="useStartTemp">
      </div>
      <div class="col-1 align-self-center">
        <input type="checkbox" formControlName="includeNullStartTemp">
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">Start Temp <br><small>job.startTemp</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min: </label>
              <input type="number" step="0.1" formControlName="startTempMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max: </label>
              <input type="number" step="0.1" formControlName="startTempMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- End Temp -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input type="checkbox" formControlName="useEndTemp">
      </div>
      <div class="col-1 align-self-center">
        <input type="checkbox" formControlName="includeNullEndTemp">
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">End Temp <br><small>job.endTemp</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min: </label>
              <input type="number" step="0.1" formControlName="endTempMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max: </label>
              <input type="number" step="0.1" formControlName="endTempMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SN On - Installed -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input type="checkbox" formControlName="useSNOn">
      </div>
      <div class="col-1 align-self-center">
        <input type="checkbox" formControlName="includeNullSNOn">
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">SN On <br><small>job.installed</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min: </label>
              <input type="text" formControlName="SNOnMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max: </label>
              <input type="text" formControlName="SNOnMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SN Off - Removed -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input type="checkbox" formControlName="useSNOff">
      </div>
      <div class="col-1 align-self-center">
        <input type="checkbox" formControlName="includeNullSNOff">
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">SN Off <br><small>job.removed</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min: </label>
              <input type="text" formControlName="SNOffMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max: </label>
              <input type="text" formControlName="SNOffMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Temp - Alt On -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input type="checkbox" formControlName="useTemp">
      </div>
      <div class="col-1 align-self-center">
        <input type="checkbox" formControlName="includeNullTemp">
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">Alt On <br><small>job.temp</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min: <small>char length</small></label>
              <input type="text" formControlName="tempMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max: <small>char length</small></label>
              <input type="text" formControlName="tempMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SwPer -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input type="checkbox" formControlName="useSwPer">
      </div>
      <div class="col-1 align-self-center">
        <input type="checkbox" formControlName="includeNullSwPer">
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">SW Per <br><small>job.swPer</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min: </label>
              <input type="number" step="0.1" formControlName="swPerMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max: </label>
              <input type="number" step="0.1" formControlName="swPerMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Gravity -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input type="checkbox" formControlName="useGravity">
      </div>
      <div class="col-1 align-self-center">
        <input type="checkbox" formControlName="includeNullGravity">
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">Gravity <br><small>job.gravity</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min: </label>
              <input type="number" step="0.1" formControlName="gravityMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max: </label>
              <input type="number" step="0.1" formControlName="gravityMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Vendor Ticker Reference - refNo -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input
          type="checkbox"
          formControlName="useTicketReference"
        >
      </div>
      <div class="col-1 align-self-center">
        <input
          type="checkbox"
          formControlName="includeNullTicketReference"
        >
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">Vendor Ticket Ref<br>Terminal Ticket: <br> <small>job.refNo</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min <small>char length</small></label>
              <input type="number" step="0.1" formControlName="vendorTicketReferenceMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max <small>char length</small></label>
              <input type="number" step="0.1" formControlName="vendorTicketReferenceMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Ticket Price -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input
          type="checkbox"
          formControlName="useTicketPrice"
        >
      </div>
      <div class="col-1 align-self-center">
        <input
          type="checkbox"
          formControlName="includeNullTicketPrice"
        >
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title"><br>Ticket Price</div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min <small>monetary value</small></label>
              <input type="number" step="0.1" formControlName="ticketPriceMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max <small>monetary value</small></label>
              <input type="number" step="0.1" formControlName="ticketPriceMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Ticket Time -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input
          type="checkbox"
          formControlName="useTicketTime"
        >
      </div>
      <div class="col-1 align-self-center">
        <input
          type="checkbox"
          formControlName="includeNullTicketTime"
        >
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">Ticket Time<br><small>calculated value</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min <small>in total hours</small></label>
              <input type="number" step="0.1" formControlName="ticketTimeMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max <small>in total hours</small></label>
              <input type="number" step="0.1" formControlName="ticketTimeMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Required e-Forms -->
    <div class="row">
      <div class="col-2"></div>

      <div class="col-1  align-self-center">
        <input
          type="checkbox"
          formControlName="useRequiredEForms"
        >
      </div>
      <div class="col-1 align-self-center">
      </div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">Selected E-Forms</div>
          </div>
          <div class="col-8">
            <div class="form-group">
              <mat-form-field class="terminals">
                <mat-select formControlName="requiredEForms" multiple>
                  <mat-option *ngFor="let survey of surveys" value="{{survey.ID}}">
                    {{survey.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <!--Work Phases-->
    <div class="row">
      <div class="col-12"><h3>Work Phases</h3></div>
    </div>

    <div class="row">
      <div class="col-2"></div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">Wait Time<br><small>ticket.waitTime</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min <small>in hours</small></label>
              <input type="number" step="0.1" formControlName="waitMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max <small>in hours</small></label>
              <input type="number" step="0.1" formControlName="waitMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-2"></div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">Work Time<br><small>ticket.workTime</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min <small>in hours</small></label>
              <input type="number" step="0.1" formControlName="workMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max <small>in hours</small></label>
              <input type="number" step="0.1" formControlName="workMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-2"></div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">En Route Time<br><small>ticket.enRouteTime</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min <small>in hours</small></label>
              <input type="number" step="0.1" formControlName="enRouteMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max <small>in hours</small></label>
              <input type="number" step="0.1" formControlName="enRouteMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-2"></div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">Haul Time <br><small>ticket.haulTime</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min <small>in hours</small></label>
              <input type="number" step="0.1" formControlName="haulMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max <small>in hours</small></label>
              <input type="number" step="0.1" formControlName="haulMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-2"></div>

      <div class="col-8">
        <div class="row">
          <div class="col-2">
            <div class="fieldset-title">Terminal Time<br><small>ticket.terminalTime</small></div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Min <small>in hours</small></label>
              <input type="number" step="0.1" formControlName="terminalMin" class="form-control">
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label class="form-label">Max <small>in hours</small></label>
              <input type="number" step="0.1" formControlName="terminalMax" class="form-control">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class='float-right'>
      <button type="button" class="btn btn-secondary mr-2" (click)="this.location.back()">
        <fa-icon class="mr-2" [icon]="faBan"></fa-icon>
        Cancel
      </button>

      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="loading"
      >
        <fa-icon class="mr-2" [icon]="faSave"></fa-icon>
        {{loading ? 'Saving' : 'Save'}}
      </button>
    </div>
  </form>
</div>
