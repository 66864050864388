import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiResponse } from '../models/apiResponse';
import { map } from 'rxjs/operators';
import { User } from '../user/user';
import { AuthService } from '../auth/auth.service';
import { HeaderAlias } from '../models/headerAlias';
import { isArray } from 'util';

@Injectable({
  providedIn: 'root'
})
export class AliasService {
  currentUser: User = new User();
  private headerAliasesSubject: BehaviorSubject<HeaderAlias>;
  public headerAliases: Observable<HeaderAlias>;
  private mobileAliasesSubject: BehaviorSubject<HeaderAlias>;
  public mobileAliases: Observable<HeaderAlias>;
  constructor(private http: HttpClient, public authService: AuthService) {
    this.headerAliasesSubject = new BehaviorSubject<HeaderAlias>(new HeaderAlias);
    this.mobileAliasesSubject = new BehaviorSubject<HeaderAlias>(new HeaderAlias);
    this.headerAliases = this.headerAliasesSubject.asObservable();
    this.mobileAliases = this.mobileAliasesSubject.asObservable();

    this.authService.personSubject.subscribe(person => {
      if (person != null) {
        this.currentUser = person;
        this.getAllHeaderAlisesByBusiness(person.businessID).subscribe(result => {
          if (result.data) {
            if (isArray(result.data)) { //this is Engage Admin
              this.headerAliasesSubject.next(result.data[0]);
            } else {
              this.headerAliasesSubject.next(result.data);
            }
          }

        })

        this.getAllMobileAlisesByBusiness(person.businessID).subscribe(result => {
          if (result.data) {
            this.mobileAliasesSubject.next(result.data);
          }
        })
      }
    });
  }


  getAllHeaderAlisesByBusiness(businessID: number, ticketOption?: number): Observable<ApiResponse> {
    return this.http.get(`${environment.apiUrl}/api/businesses/${businessID}/headeralias/raw`)
      .pipe(map((response) => { return <ApiResponse>response }));
  }
  updateHeaderAlias(headerAlias): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/businesses/${headerAlias.businessID}/headeralias/${headerAlias.ID}`, headerAlias)
  }
  createHeaderAlias(headerAlias): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/businesses/${headerAlias.businessID}/headeralias`, headerAlias)
  }
  getAllMobileAlisesByBusiness(businessID: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses/${businessID}/headeraliasmobile/raw`)
  }
  updateMobileAlias(headerAlias): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/businesses/${headerAlias.businessID}/headeraliasmobile/${headerAlias.ID}`, headerAlias)
  }
  createMobileAlias(headerAlias): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/businesses/${headerAlias.businessID}/headeraliasmobile`, headerAlias)
  }
}
