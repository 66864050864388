import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from '../layout/main-layout/main-layout.component';
import { AuthGuard } from '../shared/helpers/auth.guard';

import { HeaderAliasComponent } from './header-alias/header-alias.component';
import { BusinessesResolver } from '../shared/resolvers/businesses.resolver';
import { HeaderAliasResolver } from '../shared/resolvers/header-alias.resolver';
import { MobileAliasComponent } from './mobile-alias/mobile-alias.component';
import { MobileHeaderAliasResolver } from '../shared/resolvers/mobile-header-alias.resolver';


const routes: Routes = [
  {
    path: 'alias',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'header-alias', component: HeaderAliasComponent, resolve: { defaultAliases: HeaderAliasResolver } },
      { path: 'mobile-header-alias', component: MobileAliasComponent, resolve: { defaultAliases: MobileHeaderAliasResolver } }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AliasRoutingModule { }
