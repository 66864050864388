import {Component, OnInit} from "@angular/core";
import {FormBuilder, Validators} from '@angular/forms';
import { faSave, faBan } from '@fortawesome/free-solid-svg-icons';
import {BusinessService} from "../../operator/business.service";
import {Business} from "../../models/business";
import {BusinessTerminal} from "../../models/businessTerminal";
import {FacilityTypeService} from "../../faciliyt-type/facilitytype.service";
import {FacilityType} from "../../models/facilityType";
import {ActivatedRoute, Router} from "@angular/router";
import {AutoReject} from "../../models/autoReject";
import {AutoRejectTicketService} from "./services/auto-reject-ticket.service";
import {BusinessEquipment} from "../../models/businessEquipment";
import {User} from "../../user/user";
import {UserService} from "../../user/user.service";
import {ToastrService} from "ngx-toastr";
import { Location } from '@angular/common';
import {Survey} from "../../models/Survey";

@Component({
  templateUrl: './auto-reject-ticket.component.html',
  styleUrls: ['./auto-reject-ticket.component.scss']
})

export class AutoRejectTicketComponent implements OnInit{
  faSave = faSave;
  faBan = faBan;
  autoRejectForm = null;
  operators: Business[] = [];
  activeBusiness: Business = new Business();
  serviceContractors: Business[] = [];
  businessUsers: User[] = [];
  businessEquipments: BusinessEquipment[] = [];
  terminals: BusinessTerminal[] = [];
  surveys: Survey[] = [];
  facilityTypes: FacilityType[] = [];
  autoRejectID: number = null;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private businessService: BusinessService,
    private userService: UserService,
    private facilityTypeService: FacilityTypeService,
    private route: ActivatedRoute,
    private router: Router,
    private autoRejectTicketService: AutoRejectTicketService,
    private toastr: ToastrService,
    public location: Location
  ) {
    this.activeBusiness = JSON.parse(localStorage.getItem('selectedBusiness'));
  }

  ngOnInit() {
    this.autoRejectForm = this.fb.group({
      customerID: [null, [Validators.required]],
      rejectorID: [null, [Validators.required]],
      serviceID: [null, [Validators.required]],
      serviceName: [''],
      rejectNotes: ['', [Validators.required, Validators.minLength(3)]],
      facilityTypeID: [null],
      businessTerminalIDs: [[]],
      nullTerminal: [null],
      terminalGeoFence: [null],
      wellSiteGeoFence: [null],

      //units
      useVolume: [null],
      includeNullVolume: [null],
      volumeMin: [null],
      volumeMax: [null],

      useReadStart: [null],
      includeNullReadStart: [null],
      readStartMin: [null],
      readStartMax: [null],

      useReadEnd: [null],
      includeNullReadEnd: [null],
      readEndMin: [null],
      readEndMax: [null],

      useStartTopCut: [null],
      includeNullStartTopCut: [null],
      startTopCutMin: [null],
      startTopCutMax: [null],

      useStartBottomCut: [null],
      includeNullStartBottomCut: [null],
      startBottomCutMin: [null],
      startBottomCutMax: [null],

      useEndTopCut: [null],
      includeNullEndTopCut: [null],
      endTopCutMin: [null],
      endTopCutMax: [null],

      useEndBottomCut: [null],
      includeNullEndBottomCut: [null],
      endBottomCutMin: [null],
      endBottomCutMax: [null],

      useStartTemp: [null],
      includeNullStartTemp: [null],
      startTempMin: [null],
      startTempMax: [null],

      useEndTemp: [null],
      includeNullEndTemp: [null],
      endTempMin: [null],
      endTempMax: [null],

      useSNOn: [null],
      includeNullSNOn: [null],
      SNOnMin: [null],
      SNOnMax: [null],

      useSNOff: [null],
      includeNullSNOff: [null],
      SNOffMin: [null],
      SNOffMax: [null],

      useTemp: [null],
      includeNullTemp: [null],
      tempMin: [null],
      tempMax: [null],

      useSwPer: [null],
      includeNullSwPer: [null],
      swPerMin: [null],
      swPerMax: [null],

      useGravity: [null],
      includeNullGravity: [null],
      gravityMin: [null],
      gravityMax: [null],

      useTicketReference: [null],
      includeNullTicketReference: [null],
      vendorTicketReferenceMin: [null],
      vendorTicketReferenceMax: [null],

      useTicketPrice: [null],
      includeNullTicketPrice: [null],
      ticketPriceMin: [null],
      ticketPriceMax: [null],

      includeNullTicketTime: [null],
      useTicketTime: [null],
      ticketTimeMin: [null],
      ticketTimeMax: [null],

      requiredEForms: [null],
      useRequiredEForms: [null],

      enRouteMin: [null],
      enRouteMax: [null],

      haulMin: [null],
      haulMax: [null],

      waitMin: [null],
      waitMax: [null],

      terminalMin: [null],
      terminalMax: [null],

      workMin: [null],
      workMax: [null],
    });

    this.businessService.getOperators(this.activeBusiness.ID).subscribe(response => {
      this.operators = response.data;
    });

    this.businessService.getServiceContractors(this.activeBusiness.ID).subscribe(response => {
      this.serviceContractors = response.data;
    });

    this.facilityTypeService.getAll().subscribe(response => {
      this.facilityTypes =  response.data;
    });

    this.businessService.getAllEquipments(this.activeBusiness.ID).subscribe(response => {
      this.businessEquipments = response.data;
    });

    this.businessService.getAllTerminals(this.activeBusiness.ID).subscribe(response => {
      this.terminals = response.data;
    });

    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.loading = true;
        this.autoRejectID = Number(params.get('id'));

        this.autoRejectTicketService.getCurrentAutoReject(this.autoRejectID).subscribe(result => {
          this.userService.getBusinessUsers(result.data.customer.ID, 1, 200).subscribe(response => {
            this.businessUsers = response.data;
            this.patchForm(result.data);
          });
        }, error => {}, () => {
          this.loading = false;
        });
      }
    });
  }

  onChangeClient(event) {
    const businessID = event.target.value;
    this.userService.getBusinessUsers(businessID, 1, 200).subscribe(response => {
      this.businessUsers = response.data;
    });

    this.businessService.getAllSurveys(businessID).subscribe(response => {
      this.surveys = response.data;
    });
  }

  onSubmit() {
    this.loading = true;
    if (this.autoRejectForm.invalid) {
      this.autoRejectForm.markAllAsTouched();
      this.loading = false;
      this.toastr.info("Please fill required fields");
      return;
    }

    const serviceID = parseInt(this.autoRejectForm.value.serviceID);
    const service = this.businessEquipments.find(businessEquipment => {
      return businessEquipment.equipmentID === serviceID;
    });

    if(!service) {
      this.loading = false;
      return;
    }

    this.autoRejectTicketService.saveAutoReject({
      ...this.autoRejectForm.value,
      serviceName: service.equipmentName,
      serviceContractorID: this.activeBusiness.ID,
      useReadEnd: this.autoRejectForm.value.useReadStart,
      useStartBottomCut: this.autoRejectForm.value.useStartTopCut,
      useEndBottomCut: this.autoRejectForm.value.useEndTopCut,
      useEndTemp: this.autoRejectForm.value.useStartTemp,
      useRemoved: this.autoRejectForm.value.useInstalled,
      includeNullEndBottomCut: this.autoRejectForm.value.includeNullEndTopCut,
      includeNullEndTemp: this.autoRejectForm.value.includeNullStartTemp,
      includeNullReadEnd: this.autoRejectForm.value.includeNullReadStart,
      includeNullStartBottomCut: this.autoRejectForm.value.includeNullStartTopCut,
      facilityTypeID: this.autoRejectForm.value.facilityTypeID !== 'null' ? this.autoRejectForm.value.facilityTypeID : '',
      nullTerminal: this.autoRejectForm.value.nullTerminal === true ? 1 : 0,
      terminalGeoFence: this.autoRejectForm.value.terminalGeoFence === true ? 1 : 0,
      wellSiteGeoFence: this.autoRejectForm.value.wellSiteGeoFence === true ? 1 : 0,
    }, this.autoRejectID).subscribe((response) => {
      this.autoRejectID = response.data.id;
      this.location.replaceState(`/ticket/auto-reject/edit/${this.autoRejectID}`);
      this.toastr.success("Saved");
      this.loading = false;
    }, (error) => {
      this.toastr.error("An error occurred");
      this.loading = false;
    });
  }

  private patchForm(autoReject: AutoReject) {
    this.autoRejectForm.patchValue({
      ...autoReject,
      customerID: autoReject.customer.ID,
      rejectorID: autoReject.rejector.ID,
      serviceID: autoReject.service.ID,
      businessTerminalIDs: autoReject.businessTerminals.map((businessTerminal) => String(businessTerminal.ID))
    });
  }
}
