import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OperatorServicesComponent } from './operator-services/operator-services.component';
import { MainLayoutComponent } from '../layout/main-layout/main-layout.component';
import { AuthGuard } from '../shared/helpers/auth.guard';
import { UserListComponent } from '../user/user-list/user-list.component';
import { UserEditComponent } from '../user/user-edit/user-edit.component';
import { BusinessRegionComponent } from './business-region/business-region.component';
import { OperatorServicesResolver } from '../shared/resolvers/operator-services.resolver';
import { ServicePhaseAliasesComponent } from './service-phase-aliases/service-phase-aliases.component';

const routes: Routes = [
  {
    path: 'operator',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'services', component: OperatorServicesComponent, resolve: {
          services: OperatorServicesResolver
        }
      },
      {
        path: 'time-type-aliases', component: ServicePhaseAliasesComponent, resolve: {
          services: OperatorServicesResolver
        }
      },
      { path: 'region', component: BusinessRegionComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperatorRoutingModule { }
