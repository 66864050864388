import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse } from '../models/apiResponse';
import { map } from 'rxjs/internal/operators/map';
import { Business } from '../models/business';
@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  private activeBusinessSubject: BehaviorSubject<Business>;
  public activeBusiness: Observable<Business>;
  constructor(private http: HttpClient) {
    this.activeBusinessSubject = new BehaviorSubject<Business>(new Business);
    this.activeBusiness = this.activeBusinessSubject.asObservable();
  }

  getAllBusinesses(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses`);
  }
  updateBusiness(business): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/businesses/${business.ID}`, business);
  }
  searchBusinessName(searchTerm: string): Observable<ApiResponse> {
    return this.http.get(`${environment.apiUrl}/api/businesses?name=${searchTerm}`)
      .pipe(map((response) => { return <ApiResponse>response }));
  }
  searchOperatorsByName(searchTerm: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses?name=${searchTerm}&businessTypeID=1`);
  }
  getAllOperators(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses?businessTypeID=1`);
  }
  getOperators(businessID: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses/${businessID}/bizoptruckerassoc`);
  }
  getServiceContractors(businessID: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses/${businessID}/bizopcontractors`);
  }
  getAllCostCodes(businessID: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses/${businessID}/costcode`);
  }
  getAllTerminals(businessID): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses/${businessID}/terminal`);
  }
  getAllSurveys(businessID): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/survey?parentbusinessid=${businessID}`);
  }
  getAllValves(businessID): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses/${businessID}/valve`);
  }
  getAllUsers(businessID: number, credentialTypeIdList: number[]): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses/${businessID}/users/credentialType/${credentialTypeIdList}`);
  }
  getAllEquipments(businessID: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses/${businessID}/equipment?businessID=${businessID}&perpage=100`);
  }
  getAllEquipmentTypes(businessID: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses/${businessID}/equipmenttype?pagination=0`);
  }
  getEquipmentTypeByID(businessID: number, equipmentTypeID: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses/${businessID}/equipmenttype/${equipmentTypeID}`);
  }
  getEquipmentTypeParents(businessID: number, equipmentTypeID: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses/${businessID}/equipmenttype/${equipmentTypeID}/parents?pagination=0`);
  }
  getEquipmentTypeChildren(businessID: number, equipmentTypeID: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses/${businessID}/equipmenttype/${equipmentTypeID}/children?pagination=0`);
  }
  updateEquipmentType(businessID: number, equipmentType): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/businesses/${businessID}/equipmenttype/${Number(equipmentType.ID)}`, equipmentType);
  }
  createEquipmentTypeByID(businessID: number, equipmentType): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/businesses/${businessID}/equipmenttype`, equipmentType);
  }
  updateEquipmentChildrenAndParent(businessID: number, equipmentTypeID: number, equipmentType): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/businesses/${businessID}/equipmenttype/${equipmentTypeID}`, equipmentType);
  }
  createEquipmentChildrenAndParent(businessID: number, equipmentTypeID: number, equipmentType): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/businesses/${businessID}/equipmenttype/${equipmentTypeID}`, equipmentType);
  }
  deleteEquipmentType(businessID: number, equipmentTypeID: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/businesses/${businessID}/equipmenttype/${equipmentTypeID}`);
  }
  getForemans(businessID: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses/${businessID}/users/foreman`);
  }
  setActiveBusiness(business: Business) {
    this.activeBusinessSubject.next(business);
  }
  getBusinessEquipmentByBusinessID(businessID: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businesses/${businessID}/equipment?pagination=0&businessID=${businessID}`);

  }
}
