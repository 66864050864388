//temporary model to get auth working

import { Business } from '../models/business';

export class User {
    ID: number;
    username: string;
    password: string;
    personName: string;
    access_token: string;
    businessID: string;
    businessName: string;
    businessPersonID: number;
    isEnabled: boolean;
    credentialType: string;
    credentialTypeID: number;
    phoneNumber: string
    timezoneID: number;
    timezoneName: string;
    email:string;
    personTypeID: number;
    personTypeName: string;

}
