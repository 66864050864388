<div>
    <div *ngIf="uploadPermitted()">
        <button type="button" (click)="handleUploadClicked()" class="btn btn-secondary">{{ buttonText }}</button>
    </div>
    <ul *ngIf="filesExist()" class="list-group list-group-horizontal list-group-horizontal-sm">
        <li *ngFor="let file of currentFiles;let i=index;" class="list-group-item assoc-files">
            <div style="cursor:pointer"><a href="{{file.url}}" target=_blank class="pr-2">{{ file.name }}</a>
                <fa-icon [icon]="faTrash" (click)="handleTrashClick(i)"></fa-icon>
            </div>
        </li>
    </ul>
    <div *ngIf="deletionError" class="alert alert-danger alert-dismissible fade show" role="alert">
        <fa-icon [icon]="faExclamationTriangle"></fa-icon>
        <span class="pl-2">Unable to delete file. Please try again.</span>
        <button (click)="dismissError()" type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>