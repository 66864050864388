import { Component, Inject, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'file-upload-dialog',
    templateUrl: './file-upload-dialog.component.html',
    styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent implements OnInit {

    /**
     * Event raised (for parent consumption) when a file is successfully uploaded.
     */
    //@Output() fileUploaded = new EventEmitter<object>();

    /**
     * angular-file-uploader configuration
     */
    afuConfig = {
        multiple: false,
        formatsAllowed: ".jpg,.png",
        maxSize: "10",
        uploadAPI: {
            url: environment.apiUrl + '/api/upload',
            method: "POST",
            params: {
                'ticketId': 0,
                'uploadType': ''
            }
        },
        theme: "dragNDrop",
        hideProgressBar: true,
        hideResetBtn: true,
        hideSelectBtn: false,
        fileNameIndex: true,
        replaceTexts: {
            selectFileBtn: 'Select File',
            resetBtn: 'Reset',
            uploadBtn: 'Upload',
            dragNDropBox: 'Drag your file here or click "Select Files" to choose a file on your computer.',
            attachPinBtn: 'Attach Files...',
            afterUploadMsg_success: 'Upload successful.',
            afterUploadMsg_error: 'Upload failed, please try again.',
            sizeLimit: 'File too large'
        }
    };

    /**
     * Contains any file(s) just uploaded via this dialog.
     */
    newFiles: any[] = [];

    constructor(public dialogRef: MatDialogRef<FileUploadDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.afuConfig.formatsAllowed = data.allowedExtensions;
        this.afuConfig.multiple = data.allowMultiple;
        this.afuConfig.uploadAPI.params.ticketId = data.ticketId;
        this.afuConfig.uploadAPI.params.uploadType = data.uploadType;
    }

    ngOnInit(): void { }

    handleUploadResponse(env) {
        if (env.body !== undefined && env.body.data.success) {
            let newFile = { name: env.body.data.filename, url: env.body.data.location }
            this.newFiles.push(newFile);
            this.dialogRef.close(this.newFiles);
        }
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }
}