class Dispatch {
    base = ['3'];
    lead = ['6'];
    pro = ['7'];
    get all() {
        return [
            ...this.base,
            ...this.lead,
            ...this.pro,
        ];
    }
}

class Foreman {
    base = ['2'];
}

class Engineer {
    base = ['5'];
    technician = ['9'];
    get all() {
        return [
            ...this.base,
            ...this.technician,
        ];
    }
}

class Terminal {
    admin = ['12'];
    technician = ['13'];
    get all() {
        return [
            ...this.admin,
            ...this.technician,
        ];
    }
}

class Groups {
    foreman = new Foreman();
    engineer = new Engineer();

    get operator() {
        return [
            ...this.foreman.base,
            ...this.engineer.base,
            ...this.engineer.technician,
        ];
    }
    get foremanEngineer() {
        return [
            ...this.foreman.base,
            ...this.engineer.base,
        ];
    }
}

class Roles {
    get dispatch() {
        return new Dispatch();
    }
    foreman = new Foreman();
    get engineer() {
        return new Engineer();
    }
    get terminal() {
        return new Terminal();
    }
    get groups() {
        return new Groups();
    }
}

export default Roles;
