import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TicketRoutingModule } from './ticket-routing.module';
import { ReinstateTicketComponent } from './reinstate-ticket/reinstate-ticket.component';
import { AngularMaterialModule } from '../angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared/shared.module';
import { ReviewTicketComponent } from './review-ticket/review-ticket.component';
import { createCustomElement } from '@angular/elements';
import { EditScanItemModal } from "./review-ticket/edit-scan-item-modal/edit-scan-item-modal.component";
import { AutoRejectTicketComponent } from "./auto-reject-ticket/auto-reject-ticket.component";
import { AutoRejectTicketListComponent } from "./auto-reject-ticket/auto-reject-ticket-list/auto-reject-ticket-list.component";
import { LibSharedModule } from 'lib-shared';
import { environment } from '../../environments/environment';
import { EditAutoRejectButton } from './auto-reject-ticket/auto-reject-ticket-list/renderers/edit-auto-reject-button';


@NgModule({
  declarations: [
    ReinstateTicketComponent,
    ReviewTicketComponent,
    EditScanItemModal,
    AutoRejectTicketComponent,
    AutoRejectTicketListComponent,
    EditAutoRejectButton
  ],
  imports: [
    CommonModule,
    SharedModule,
    TicketRoutingModule,
    AngularMaterialModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([]),
    BrowserAnimationsModule,
    LibSharedModule.forRoot({ apiURL: environment.apiUrl, invoiceApiUrl: environment.invoiceApiUrl })
  ],
  exports: [
    EditScanItemModal,
    AgGridModule,
    FormsModule,
  ]

})
export class TicketModule {
  // constructor(injector: Injector) {
  //   const reviewTicket = createCustomElement(ReviewTicketComponent, { injector: injector });
  //   customElements.define('review-ticket', reviewTicket);
  // }
  // ngDoBootstrap() { }
}
