<div class="container">
  <mat-card class="col-lg-12">
    <mat-card-title>
      SCADA and Hourly Data Validation Percentages
    </mat-card-title>
    <mat-card-content>
      <form [formGroup]="autoFlowcheckForm">
        <div class="row">
          <div class="col-lg-1 header-title">
            Details
          </div>
          <div class="col-lg-11">
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="row form-row">
              <div class="col-lg-3">
                <mat-label>Service Contractor *</mat-label>
              </div>
              <div class="col-lg-8">
                <input type="text" class="form-control" matInput #scInput formControlName="serviceContractor"
                  [matAutocomplete]="auto">
                <mat-autocomplete class="form-control" #auto="matAutocomplete" [displayWith]="displayServiceContractor">
                  <mat-option *ngFor="let contractor of serviceContractors" [value]="contractor"
                    (onSelectionChange)="serviceContractorSelected(contractor.ID)">
                    {{contractor.businessName}}
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>
            <div class="row form-row">
              <div class="col-lg-3">
                <mat-label>Service *</mat-label>
              </div>
              <div class="col-lg-8" *ngIf="scInfoLoaded">
                <input type="text" class="form-control" matInput formControlName="businessEquipment"
                  [matAutocomplete]="auto2" #businessEquipmentInput>
                <mat-autocomplete class="form-control" #auto2="matAutocomplete" [displayWith]="displayEquipmentType">
                  <mat-option *ngFor="let be of filteredServices(businessEquipmentInput.value)" [value]="be">
                    {{be.equipmentName}}
                  </mat-option>
                </mat-autocomplete>

              </div>
              <div class="col-lg-8" *ngIf="!scInfoLoaded">
                Please select a Service Contractor to display their services.
              </div>
            </div>
            <div class="row form-row">
              <div class="col-lg-3">
                <mat-label>Foreman *</mat-label>
              </div>
              <div class="col-lg-8">
                <input type="text" class="form-control" matInput formControlName="foreman" [matAutocomplete]="auto3"
                  #foremanInput>
                <mat-autocomplete class="form-control" #auto3="matAutocomplete" [displayWith]="displayForeman">
                  <mat-option *ngFor="let f of filteredForemen(foremanInput.value)" [value]="f">
                    {{f.personName}}
                  </mat-option>
                </mat-autocomplete>
              </div>

            </div>
            <div class="row form-row">
              <div class="col-lg-3">
                <mat-label>Auto Approve:</mat-label>
              </div>
              <div class="col-lg-8">
                <input id="autoApprove" formControlName="autoApprove" type="checkbox">
              </div>
            </div>
            <div class="row form-row">
              <div class="col-lg-3">
                <mat-label>Auto Fail:</mat-label>
              </div>
              <div class="col-lg-8">
                <input id="autoFail" formControlName="autoFail" type="checkbox">
              </div>
            </div>
            <div class="row form-row">
              <div class="col-lg-3">
                <mat-label>Automation Data Required:</mat-label>
              </div>
              <div class="col-lg-8">
                <input id="automationDataRequired" formControlName="automationDataRequired" type="checkbox">
              </div>
            </div>
            <div class="row form-row">
              <div class="col-lg-3">
                <mat-label>Min Hours:</mat-label>
              </div>
              <div class="col-lg-2">
                <input id="minJobHour" class="form-control" formControlName="minJobHour" placeholder="" type="number">
              </div>
              <div class="col-lg-2">
                <mat-label>Max Hours:</mat-label>
              </div>
              <div class="col-lg-2">
                <input id="maxJobHour" class="form-control" formControlName="maxJobHour" placeholder="" type="number">
              </div>
            </div>
            <div class="row form-row">
              <div class="col-lg-3">
                <mat-label>Rejection Comments:</mat-label>
              </div>
              <div class="col-lg-8">
                <input id="comments" class="form-control" formControlName="comments" placeholder="" type="text">
              </div>
            </div>
            <div class="row form-row">
              <div class="col-lg-3">
                <mat-label>Verification ID:</mat-label>
              </div>
              <div class="col-lg-8">
                <input id="verificationID" class="form-control" formControlName="verificationID" placeholder=""
                  type="text">
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-6 header-title">
            Validation Percentages
          </div>
          <div class="col-lg-6">
          </div>
        </div>

        <div class="row form-row">
          <div class="col-lg-4">
            <mat-label class="hoverToolTip"
              matTooltip="If Max Volume Variance Below fails, and the recorded volume is within this given percentage below the calculated volume, the ticket is flagged as marginally approved.">
              Marginal Volume Variance Percentage Below:
            </mat-label>
          </div>
          <div class="col-lg-2">
            <input id="marginalVolumeVariancePercentageBelow" class="form-control"
              formControlName="marginalVolumeVariancePercentageBelow" placeholder="" type="number">
          </div>
          <div class="col-lg-4">
            <mat-label class="hoverToolTip"
              matTooltip="If Max Volume Variance Above fails, and the recorded volume is within this given percentage above the calculated volume, the ticket is flagged as marginally approved.">
              Marginal Volume Variance Percentage Above:</mat-label>
          </div>
          <div class="col-lg-2">
            <input id="marginalVolumeVariancePercentageAbove" class="form-control"
              formControlName="marginalVolumeVariancePercentageAbove" placeholder="" type="number">
          </div>
        </div>
        <div class="row form-row">
          <div class="col-lg-4">
            <mat-label class="hoverToolTip"
              matTooltip="If (calculated volume - recorded volume)/ actual volume is within this percentage the ticket will pass.">
              Max Volume Percentage Below:</mat-label>
          </div>
          <div class="col-lg-2">
            <input id="maxVolumeVariancePercentageBelow" class="form-control"
              formControlName="maxVolumeVariancePercentageBelow" placeholder="" type="number">
          </div>
          <div class="col-lg-4">
            <mat-label class="hoverToolTip"
              matTooltip="If (recorded volume - calculated volume)/ actual volume is within this percentage the ticket will pass.">
              Max Volume Percentage Above:</mat-label>
          </div>
          <div class="col-lg-2">
            <input id="maxVolumeVariancePercentageAbove" class="form-control"
              formControlName="maxVolumeVariancePercentageAbove" placeholder="" type="number">
          </div>
        </div>
        <div class="row form-row">
          <div class="col-lg-6">
            <mat-error class="field-message" *ngIf="autoFlowcheckForm.errors?.maxVolumeVarianceBelowInvalid">
              Must be populated and less than <b>Marginal Volume Variance Percentage Below</b>
            </mat-error>
          </div>
          <div class="col-lg-6">
            <mat-error class="field-message" *ngIf="autoFlowcheckForm.errors?.maxVolumeVarianceAboveInvalid">
              Must be populated and less than <b>Marginal Volume Variance Percentage Above</b>
            </mat-error>
          </div>
        </div>
        <div class="row">

          <div class="col-lg-6 header-title">
            Validation Literals
          </div>
          <div class="col-lg-6">
          </div>
        </div>
        <div class="row form-row">
          <div class="col-lg-4">
            <mat-label matTooltip="A ticket with less volume than this will fail." class="hoverToolTip">Min Volume:
            </mat-label>
          </div>
          <div class="col-lg-2">
            <input id="minJobVolume" class="form-control" formControlName="minJobVolume" placeholder="" type="number">

          </div>

          <div class="col-lg-4">
            <mat-label matTooltip="A ticket with more volume than this will fail." class="hoverToolTip">Max Volume:
            </mat-label>
          </div>
          <div class="col-lg-2">
            <input id="maxJobVolume" class="form-control" formControlName="maxJobVolume" placeholder="" type="number">

          </div>
        </div>
        <div class="row form-row">
          <div class="col-lg-4">
            <mat-label
              matTooltip="If a ticket fails the Max Volume Variance Literal Below but is less than this given number under the calculated volume, the ticket is flagged as marginally approved."
              class="hoverToolTip">Marginal Volume Variance Literal Below:</mat-label>
          </div>
          <div class="col-lg-2">
            <input id="marginalVolumeVarianceLiteralBelow" class="form-control"
              formControlName="marginalVolumeVarianceLiteralBelow" placeholder="" type="number">
          </div>
          <div class="col-lg-4">
            <mat-label
              matTooltip="If a ticket fails the Max Volume Variance Literal Above but is less than this given number above the calculated volume, the ticket is flagged as marginally approved."
              class="hoverToolTip">Marginal Volume Variance Literal Above:</mat-label>
          </div>
          <div class="col-lg-2">
            <input id="marginalVolumeVarianceLiteralAbove" class="form-control"
              formControlName="marginalVolumeVarianceLiteralAbove" placeholder="" type="number">
          </div>
        </div>
        <div class="row form-row">
          <div class="col-lg-4">
            <mat-label
              matTooltip="If a ticket fails the Max Volume Variance Literal Above but is less than this given number above the calculated volume, the ticket is flagged as marginally approved."
              class="hoverToolTip">Max Volume Variance Literal Below:</mat-label>
          </div>
          <div class="col-lg-2">
            <input id="maxVolumeVarianceLiteralBelow" class="form-control"
              formControlName="maxVolumeVarianceLiteralBelow" placeholder="" type="number">
          </div>
          <div class="col-lg-4">
            <mat-label
              matTooltip="If the recorded volume of a ticket is further above the calculated volume than this value, the ticket fails."
              class="hoverToolTip">Max Volume Variance Literal Above:</mat-label>
          </div>
          <div class="col-lg-2">
            <input id="maxVolumeVarianceLiteralAbove" class="form-control"
              formControlName="maxVolumeVarianceLiteralAbove" placeholder="" type="number">
          </div>
        </div>
        <div class="row form-row">
          <div class="col-lg-6">
            <mat-error class="field-message" *ngIf="autoFlowcheckForm.errors?.maxVolumeVarianceLiteralBelowInvalid">
              Must be populated and less than <b>Marginal Volume Variance Literal Below</b>
            </mat-error>
          </div>
          <div class="col-lg-6">
            <mat-error class="field-message" *ngIf="autoFlowcheckForm.errors?.maxVolumeVarianceLiteralAboveInvalid">
              Must be populated and less than <b>Marginal Volume Variance Literal Above</b>
            </mat-error>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-12" style="text-align: right;">
            <button class="btn btn-other btn-small" (click)="onCancelClick()" type="button">
              <fa-icon [icon]="faBan"></fa-icon> Cancel
            </button>
            <button class="btn btn-primary ml-3 btn-small" type="submit" (click)="saveAutoFlowcheckForm()"
              [disabled]="isLoading ||autoFlowcheckForm.invalid || submitted" type="submit">
              <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
              <fa-icon [icon]="faSave"></fa-icon> Save
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
