import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../user/user.service';
import { Business } from '../../models/business';
import { AliasService } from '../../alias/alias.service';
import { ApiResponse } from '../../models/apiResponse';

@Injectable({
  providedIn: 'root'
})
export class HeaderAliasResolver implements Resolve<Observable<ApiResponse>> {

  constructor(private aliasService: AliasService) { }

  resolve(): Observable<ApiResponse> {
    return this.aliasService.getAllHeaderAlisesByBusiness(10, 0); //TODO: Add these to global config
  }
}
