<div class="title-bar">
  <div class="row">
    <div class="col-lg-8">
      {{ticketid}}
      <!-- <strong>{{ticket.jobDetails.businessEquipmentName}}</strong> -->
    </div>
    <div class="col-lg-1">
      Total: <strong>{{ticket.totalAmount | currency}}</strong>
    </div>
    <div class="col-lg-2">
      Status: <strong>{{ticket.jobDetails.status}}</strong>
    </div>
    <!-- <div class="col-lg-2">
    <strong>{{ticket.jobDetails.businessEquipmentName}}</strong>
  </div> -->
  </div>
</div>
<div class="f5-bg">
  <div class="head-container">
    <div class="row">
      <div class="col-lg-6"><strong>{{headerAliases.serviceContractor}}</strong></div>
      <div class="col-lg-6"><strong>{{headerAliases.operator}}</strong></div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <mat-card class="mat-elevation-z8">
          <div class="note-card-header">
            <div class="row card-title">
              {{ticket.serviceContractor.businessName}}
            </div>
            <div class="row">
              {{ticket.serviceContractor.address1}}, {{ticket.serviceContractor.city}},
              {{ticket.serviceContractor.state}}
              {{ticket.serviceContractor.postalCode}}
            </div>
            <div class="row">
              {{ticket.serviceContractor.phone}}
            </div>
          </div>
          <mat-card-content class="card-body">
            <div class="row">
              <div class="col-lg-4 card-label">Business Asset:</div>
              <div class="col-lg-8">{{ticket.businessAssetName}}</div>
            </div>
            <div *ngIf="ticket.jobDetails.altBusinessAssetName" class="card-row row">
              <div class="col-lg-3 card-label">Trailer Number:</div>
              <div class="col-lg-9">{{ticket.jobDetails.altBusinessAssetName}}</div>
            </div>
            <div class="row">
              <div class="col-lg-4 card-label">Job #:</div>
              <div class="col-lg-8">{{ticket.jobDetails.jobNo}}</div>
            </div>
            <div class="row">
              <div class="col-lg-4 card-label">Start Time:</div>
              <div class="col-lg-8">{{ticket.jobDetails.startTime}}</div>
            </div>
            <div class="row">
              <div class="col-lg-4 card-label">End Time:</div>
              <div class="col-lg-8">{{ticket.jobDetails.endTime}}</div>
            </div>
            <div class="row">
              <div class="col-lg-4 card-label">Notes:</div>
              <div class="col-lg-8 card-value ticket-notes">
                <edit-in-place-input [inputType]="InputType.TextArea"
                                     [inputId]="'notes'" [label]="'Notes'" [allowedBusinessTypeIds]="[1]"
                                     [(selectedValue)]="ticket.jobDetails.notes" [displayedValue]="ticket.jobDetails.notes"
                ></edit-in-place-input>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-lg-6">
        <mat-card class="note-card mat-elevation-z8">
          <div class="note-card-header">
            <div class="row card-title">
              {{ticket.operator.businessName}}
            </div>
            <div class="row">
              {{ticket.operator.address1}}, {{ticket.operator.city}}, {{ticket.operator.state}}
              {{ticket.operator.postalCode}}
            </div>
            <div class="row">
              {{ticket.operator.phone}}
            </div>
          </div>
          <mat-card-content class="card-body">
            <div class="row">
              <div class="col-lg-4 card-label">{{headerAliases.foreman}}:</div>

              <div class="col-lg-8 card-value">
                <edit-in-place-input [inputType]="InputType.Select"
                                     [inputId]="'foreman'" [label]="headerAliases.foreman" [allowedBusinessTypeIds]="[1]"
                                     [selectedValue]="ticket.foremanDetails.ID" [displayedValue]="ticket.personName"
                                     (selectedValueChange)="updateForemanSelection($event)"
                                     [itemList]="foremen" [listValueKey]="'businessPersonID'" [listDisplayKey]="'personName'"
                ></edit-in-place-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 card-label">Job Cost Code:</div>
              <div class="col-lg-8 card-value">
                <edit-in-place-input [inputType]="InputType.Select"
                                     [inputId]="'jobCostCode'" [label]="'Job Cost Code'" [allowedBusinessTypeIds]="[1]"
                                     [selectedValue]="ticket.businessCostCode.ID" [displayedValue]="ticket.businessCostCode.costCode"
                                     (selectedValueChange)="ticket.businessCostCode=utils.findObject(costCodes, 'ID', $event)"
                                     [itemList]="costCodes" [listValueKey]="'ID'" [listDisplayKey]="'costCode'"
                ></edit-in-place-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 card-label">{{headerAliases.wellsite}}:</div>
              <div class="col-lg-8 card-value">
                <edit-in-place-input [inputType]="InputType.Select"
                                     [inputId]="'wellSite'" [label]="headerAliases.wellsite" [allowedBusinessTypeIds]="[1]"
                                     [selectedValue]="ticket.afeDetails.ID" [displayedValue]="ticket.afeDetails.wellSite"
                                     (selectedValueChange)="ticket.afeDetails=utils.findObject(afes, 'ID', $event)"
                                     [itemList]="afes" [listValueKey]="'ID'" [listDisplayKey]="'wellSite'"
                ></edit-in-place-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 card-label">{{headerAliases.toLocation}}:</div>
              <div class="col-lg-8">{{ticket.afeDetails.afe}}</div>
            </div>
            <div class="row" *ngIf="ticketOption === 1 || ticketOption === 2 || !ticket.afeOptionalNumber">
              <div class="col-lg-4 card-label">PO #:</div>
              <div class="col-lg-8">{{ticket.afeOptionalNumber}}</div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
<div class="head-container">
  <div class="row ">
    <div class="col-lg-12">
      <afe-map [mapHeight]="450" [afeMapLocation]="mapLoc" [afeMarkers]="mapMarkers" [ticketData]="ticketData">
      </afe-map>
    </div>
  </div>
</div>
<div>
  <mat-expansion-panel [togglePosition]="'before'" [expanded]="true"
    (opened)="setRouteDetailsExpanded(!routeDetailsExpanded)">
    <mat-expansion-panel-header class="collapsible-bg disable_ripple" [collapsedHeight]="'40px'"
      [expandedHeight]="'40px'">

      <mat-panel-title class="collapsible-header">

        <div class="row">
          <div class="col-lg-3 panel-header">
            <strong>Phase</strong>
          </div>
          <div class="col-lg-3 panel-header">
            <strong>Date & Time</strong>
          </div>
          <div class="col-lg-3 panel-header">
            <strong>Phase Time</strong>
          </div>
          <div class="col-lg-3 panel-header">
            <strong>Coordinates</strong>
          </div>
        </div>

      </mat-panel-title>

    </mat-expansion-panel-header>
    <div *ngFor="let jtt of ticket.jobTimeTypes; let index = index;"
      [ngClass]="{ 'lighter-detail-bg': (index%2 == 0), 'darker-detail-bg': index %2 == 1 } ">

      <div class="row detail-rows-container">
        <div class="col-lg-3">
          <strong>{{jtt.timeType}}</strong>
        </div>
        <div class="col-lg-3">
          <strong>{{jtt.startTime | date:'MMM d, y, h:mm a'}}</strong>
        </div>
        <div class="col-lg-3">
          <strong *ngIf="jtt.phaseTime.hours > 0">{{ jtt.phaseTime.hours}}
            {{jtt.phaseTime.hours > 1? 'hours': 'hour'}}
          </strong>
          <strong *ngIf="jtt.phaseTime.minutes > 0">{{ jtt.phaseTime.minutes}}
            {{jtt.phaseTime.minutes > 1? 'minutes': ''}}
          </strong>
        </div>
        <div class="col-lg-3 cursorpointer">
          <span (click)="openGoogleMapLink(jtt)"><strong>{{jtt.startlatitude| number:'2.1-6' }},
              {{ jtt.startlongitude| number:'2.1-6'}}</strong></span>
        </div>
      </div>

    </div>

    <div class="darker-detail-bg" *ngIf="ticket.jobStatus ==='complete'">

      <div class="row detail-rows-container">
        <div class="col-lg-3">
          <strong>Completed</strong>
        </div>
        <div class="col-lg-3">
          <strong>{{ticket.jobDetails.endTime}}</strong>
        </div>
        <div class="col-lg-3">
          Total: <strong *ngIf="totalTimeVal.hours > 0">{{ totalTimeVal.hours}}
            {{totalTimeVal.hours > 1? 'hours': 'hour'}}
          </strong>
          <strong *ngIf="totalTimeVal.minutes > 0">{{ totalTimeVal.minutes}}
            {{totalTimeVal.minutes > 1? 'minutes': ''}}
          </strong>
        </div>
        <div class="col-lg-3 cursorpointer">
          <span
            (click)="openGoogleMapLink({startlatitude:ticket.jobEndLatitude, startlongitude:ticket.jobEndLongitude})"><strong>{{ticket.jobEndLatitude | number:'2.1-6'}},
              {{this.ticket.jobEndLongitude | number:'2.1-6'}}</strong></span>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>


<div class="head-container">
  <div class="row">
    <div class="col-lg-4">
      Driver: <strong>{{ticket.jobDetails.driverName}}</strong>
    </div>
  </div>
</div>
<div class="f5-bg">
  <div class="f5-bg">
    <div class="head-container">
      <div class="row">
        <div class="col-lg-6"><strong>Details</strong></div>
        <div class="col-lg-6"><strong>Cost</strong></div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <mat-card class="mat-elevation-z8">
            <mat-card-content class="card-body">
              <div class="row">
                <div class="col-lg-4 card-label">En Route Time:</div>
                <div class="col-lg-8">{{ticket.enRouteTime}}</div>
              </div>
              <div class="row">
                <div class="col-lg-4 card-label">Wait Time:</div>
                <div class="col-lg-8">{{ticket.waitTime}}</div>
              </div>
              <div class="row">
                <div class="col-lg-4 card-label">Work Time:</div>
                <div class="col-lg-8">{{ticket.workTime}}</div>
              </div>
              <div class="row">
                <div class="col-lg-4 card-label">Haul Time:</div>
                <div class="col-lg-8">{{ticket.haulTime}}</div>
              </div>
              <div class="row">
                <div class="col-lg-4 card-label">Total Time:</div>
                <div class="col-lg-8">{{ticket.jobTotalTimeStr}}</div>
              </div>
              <div class="row">
                <div class="col-lg-4 card-label">Total Duration:</div>
                <div class="col-lg-8">
                  {{getDifferenceBetweenTimes(ticket.jobDetails.startTime, ticket.jobDetails.endTime)/60}} Hours</div>
              </div>

            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-lg-6">
          <mat-card class="mat-elevation-z8">
            <mat-card-content class="card-body">
              <div class="row">
                <div class="col-lg-4 card-label">Hourly Cost:</div>
                <div class="col-lg-8">{{ticket.addHourlyCost | currency:""}}</div>
              </div>
              <div class="row">
                <div class="col-lg-4 card-label">{{mobileAliases.additionalCost}}:</div>
                <div class="col-lg-8">{{ticket.jobAdditionalCosts | currency:""}}</div>
              </div>

              <div class="row">
                <div class="col-lg-4 card-label">{{mobileAliases.pricePerUOM}}:</div>
                <div class="col-lg-8">{{ticket.pricePerUOM | currency:""}}</div>
              </div>
              <div class="row">
                <div class="col-lg-4 card-label">{{mobileAliases.volumeQuantity}}:</div>
                <div class="col-lg-8">{{ticket.volumeQuantity}}</div>
              </div>
              <div class="row">
                <div class="col-lg-4 card-label">Unit Cost:</div>
                <div class="col-lg-8">{{ticket.totalUMR | currency:""}}</div>
              </div>

              <div class="row">
                <div class="col-lg-4 card-label">Unit Rate Cost:</div>
                <div class="col-lg-8">{{ticket.uomRate | currency:""}}</div>
              </div>
              <div class="row">
                <div class="col-lg-4 card-label">Total Cost:</div>
                <div class="col-lg-8">{{ticket.totalAmount | currency:""}}</div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="items">
  <mat-expansion-panel #itemExpansion="matExpansionPanel" *ngIf="ticketOption != 2" [togglePosition]="'before'" [expanded]="true"
    (opened)="setRouteDetailsExpanded(!routeDetailsExpanded)" id="itemExpansionPanel">
    <mat-expansion-panel-header class="collapsible-bg disable_ripple" [collapsedHeight]="'40px'"
      [expandedHeight]="'40px'">
      <mat-panel-title class="collapsible-header">
        <div class="row btn-row">
          <div class="col-lg-2 panel-header">
            <strong>Quantity</strong>
          </div>
          <div class="col-lg-3 panel-header">
            <strong>Item</strong>
          </div>
          <div class="col-lg-2 panel-header">
            <strong>Price</strong>
          </div>
          <div class="col-lg-2 panel-header">
            <strong>Total</strong>
          </div>
          <div class="col-lg-3 panel-header">
            <div class="row">
              <div class="col-lg-4"><strong>Action</strong></div>
              <div class="col-lg-8" *ngIf="true">
                <button type="button" (click)="addScanItem($event, itemExpansion);" class="btn btn-secondary float-right">Add Item</button>
              </div>
            </div>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngFor="let si of ticket.scanItems; let index = index;"
      [ngClass]="{ 'lighter-detail-bg': (index%2 == 0), 'darker-detail-bg': index %2 == 1 } ">

      <div class="row detail-rows-container">
        <div class="col-lg-2">
          <strong>{{si.quantity}}</strong>
        </div>
        <div class="col-lg-3">
          <strong>{{si.itemQRCode.itemDescription}}</strong>
        </div>
        <div class="col-lg-2">
          <strong>{{si.itemQRCode.itemPrice | currency:""}}</strong>
        </div>
        <div class="col-lg-2">
          <strong>{{si.quantity * si.itemQRCode.itemPrice | currency:""}}</strong>
        </div>
        <div class="col-lg-3">
          <fa-icon [icon]="faEdit" class="cursorpointer" (click)="openScanItemModal(index)"></fa-icon>
          <fa-icon [icon]="faTrash" class="ml-2 cursorpointer" (click)="confirmDeleteScanItem(index)"></fa-icon>
        </div>
      </div>
    </div>
    <div *ngIf="!ticket.scanItems" class="lighter-detail-bg">
      <div class="row detail-rows-container">
        <div class="col-lg-12 text-center">
          <strong>No Items</strong>

        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <div *ngIf="ticketOption == 2">
    <mat-expansion-panel [togglePosition]="'before'" [expanded]="true"
      (opened)="setRouteDetailsExpanded(!routeDetailsExpanded)">
      <mat-expansion-panel-header class="collapsible-bg disable_ripple" [collapsedHeight]="'40px'"
        [expandedHeight]="'40px'">
        <mat-panel-title class="collapsible-header">
          <div class="row">
            <div class="col-lg-1 panel-header">
              <strong>Qty</strong>
            </div>
            <div class="col-lg-2 panel-header">
              <strong>Item</strong>
            </div>
            <div class="col-lg-2 panel-header">
              <strong>Company Item No</strong>
            </div>
            <div class="col-lg-2 panel-header">
              <strong>Proper Shipping Name / Notes</strong>
            </div>
            <div class="col-lg-2 panel-header">
              <strong>Serial No / Lot</strong>
            </div>
            <div class="col-lg-1 panel-header">
              <strong>Price</strong>
            </div>
            <div class="col-lg-1 panel-header">
              <strong>Total</strong>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngFor="let si of ticket.scanItems; let index = index;"
        [ngClass]="{ 'lighter-detail-bg': (index%2 == 0), 'darker-detail-bg': index %2 == 1 } ">

        <div class="row detail-rows-container">
          <div class="col-lg-1">
            <strong>{{si.quantity}}</strong>
          </div>
          <div class="col-lg-2">
            <strong>{{si.itemQRCode.itemDescription}}</strong>
          </div>
          <div class="col-lg-2">
            <strong>{{si.itemQRCode.companyItemNo}}</strong>
          </div>
          <div class="col-lg-2">
            <strong>{{si.itemQRCode.properShippingName}}</strong>
          </div>
          <div class="col-lg-2">
            <strong>{{si.itemQRCode.lot}}</strong>
          </div>
          <div class="col-lg-1">
            <strong>{{si.itemQRCode.itemPrice | currency:""}}</strong>
          </div>
          <div class="col-lg-1" class="cursorpointer">
            <strong>{{si.quantity * si.itemQRCode.itemPrice | currency:""}}</strong>
          </div>
        </div>
      </div>
      <div *ngIf="!ticket.scanItems" class="lighter-detail-bg">
        <div class="row detail-rows-container">
          <div class="col-lg-12 text-center">
            <strong>No Items</strong>

          </div>
        </div>
      </div>
      <!-- <div *ngIf="ticket.preTrip" class="row detail-rows-container">
        <div class="col-lg-2"><a><button class="btn btn-secondary" (click)="openImageInNewTab(ticket.preTrip)">JSA
              Inspection</button></a></div>
      </div> -->
    </mat-expansion-panel>


  </div>
  <div class="f5-bg">
    <div class="f5-bg">
      <div class="head-container">
        <div class="row">
          <div class="col-lg-6"><strong>Additional Details</strong></div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <mat-card class="mat-elevation-z8">
              <mat-card-content class="card-body">
                <div class="row" *ngIf="ticket.jobDetails.readStart">
                  <div class="col-lg-4 card-label">{{mobileAliases.readStart}}:</div>

                  <div class="col-lg-8 card-value">
                    <foot-inch-quarter-select [editFieldName]="'readStart'" [allowedBusinessTypes]="[1]"
                                              [measurement]="ticket.jobDetails.readStart"
                                              [originalObject]="ticket.jobDetails"
                    ></foot-inch-quarter-select>
                  </div>
                </div>
                <div class="row" *ngIf="ticket.jobDetails.readEnd">
                  <div class="col-lg-4 card-label">{{mobileAliases.readEnd}}:</div>
                  <div class="col-lg-8 card-value">
                    <foot-inch-quarter-select [editFieldName]="'readEnd'" [allowedBusinessTypes]="[1]"
                                              [measurement]="ticket.jobDetails.readEnd"
                                              [originalObject]="ticket.jobDetails"
                    ></foot-inch-quarter-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 card-label">{{mobileAliases.startTopCut}}:</div>
                  <div class="col-lg-8 card-value">
                    <foot-inch-quarter-select [editFieldName]="'startTopCut'" [allowedBusinessTypes]="[1]"
                                              [measurement]="ticket.startTopCut"
                                              [originalObject]="ticket"
                    ></foot-inch-quarter-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 card-label">{{mobileAliases.startBottomCut}}:</div>
                  <div class="col-lg-8 card-value">
                    <foot-inch-quarter-select [editFieldName]="'startBottomCut'" [allowedBusinessTypes]="[1]"
                                              [measurement]="ticket.startBottomCut"
                                              [originalObject]="ticket"
                    ></foot-inch-quarter-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 card-label">Terminal:</div>
                  <div class="col-lg-8 card-value">
                    <edit-in-place-input [inputType]="InputType.Select"
                                         [inputId]="'terminal'" [label]="'Terminal'" [allowedBusinessTypeIds]="[1]"
                                         [selectedValue]="ticket.terminal?.ID" [displayedValue]="ticket.terminal?.terminal"
                                         (selectedValueChange)="ticket.terminal=utils.findObject(terminals, 'ID', $event)"
                                         [itemList]="terminals" [listValueKey]="'ID'" [listDisplayKey]="'terminal'"
                    ></edit-in-place-input>
                  </div>
                </div>

              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-lg-6">
            <mat-card class="mat-elevation-z8">
              <mat-card-content class="card-body">
                <div class="row">
                  <div class="col-lg-4 card-label">Seal On:</div>
                  <div class="col-lg-8 card-value">
                    <edit-in-place-input [inputType]="InputType.Number"
                                         [inputId]="'removed'" [label]="'Seal On'" [allowedBusinessTypeIds]="[1]"
                                         [(selectedValue)]="ticket.jobDetails.removed" [displayedValue]="ticket.jobDetails.removed"
                    ></edit-in-place-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 card-label">Seal Off:</div>
                  <div class="col-lg-8 card-value">
                    <edit-in-place-input [inputType]="InputType.Number"
                                         [inputId]="'installed'" [label]="'Seal Off'" [allowedBusinessTypeIds]="[1]"
                                         [(selectedValue)]="ticket.jobDetails.installed" [displayedValue]="ticket.jobDetails.installed"
                    ></edit-in-place-input>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-4 card-label">Valve/Tank:</div>
                  <div class="col-lg-8 card-value">
                    <edit-in-place-input [inputType]="InputType.Select"
                                         [inputId]="'valve'" [label]="'Valve/Tank'" [allowedBusinessTypeIds]="[1]"
                                         [selectedValue]="ticket.valve?.ID" [displayedValue]="ticket.valve?.valve"
                                         (selectedValueChange)="ticket.valve=utils.findObject(valves, 'ID', $event)"
                                         [itemList]="valves" [listValueKey]="'ID'" [listDisplayKey]="'valve'"
                    ></edit-in-place-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 card-label">Comment:</div>
                  <div class="col-lg-8 card-value">
                    <edit-in-place-input [inputType]="InputType.TextArea"
                                         [inputId]="'explanation'" [label]="'Comment'" [allowedBusinessTypeIds]="[2]"
                                         [(selectedValue)]="ticket.explanation" [displayedValue]="ticket.explanation"
                    ></edit-in-place-input>
                  </div>
                </div>
                <div class="row" *ngIf="ticket.correctionExplanations">
                  <div class="col-lg-4 card-label">Correction Reason:</div>
                  <div class="col-lg-8">
                    {{ticket.correctionExplanations[0].explanation}}</div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-lg-12">
            <mat-card class="mat-elevation-z8">
              <mat-card-content class="card-body">
                <div class="row">
                  <div class="col-lg-9">

                  </div>
                  <div class="col-lg-3">
                    <a><button class="btn btn-secondary btn-small pad-15-right">Reject Ticket</button></a>
                    <a><button class="btn btn-primary btn-small">Approve & Email</button></a>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="f5-bg">
    <div class="f5-bg">
      <div class="head-container">
        <div class="row">
          <div class="col-lg-6"><strong>Upload Documents</strong></div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <mat-card class="mat-elevation-z8">
              <mat-card-content class="card-body">
                <file-upload [buttonText]="'Add Document'" [ticketId]="ticket.ticketID" [uploadType]="'attachment'"
                  [extensionWhitelist]="'.png,.jpg,.jpeg,.gif,.pdf'" [currentFiles]="ticketAttachments"></file-upload>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="col-lg-6"></div>
        </div>
      </div>
    </div>
  </div>
</div>
