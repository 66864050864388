export class EquipmentType {
  ID: number;
  equipmentType: string;
  businessID: number;
  operatorCode?: number;
  level: number;
  businessCostCodeID?: number;
  facilityTypeID?: number;
  primaryVendor?: number;
  productCode?: boolean;
  runType?: number;
  restrictedLoad?: boolean;
  isVisible?: boolean;
  isObsTemp?: boolean;
  parents?: number[];
  children?: number[]
  services?: EquipmentType[];

}
