import { Component, OnInit } from '@angular/core';
import { BusinessRegionService } from './business-region.service';
import * as _ from 'lodash';
import { TestBed, waitForAsync } from '@angular/core/testing';
import Utils from '../../shared/helpers/utils-helper'
import { utils } from 'protractor';
import { isEmpty } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManageRegionButtons } from './renderers/manage-region-button'
import { createHostListener } from '@angular/compiler/src/core';
import { AssociateAreaModal } from './associate-area-modal/associate-area-modal.component';
import { EditRegionModal } from './edit-region-modal/edit-region-modal.component';
import { KeyValue } from '@angular/common';
import { faIcons, faPlusCircle, faBars, faFileExcel, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { HeaderTitleService } from '../../layout/header-title.service';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-business-region',
  templateUrl: './business-region.component.html',
  styleUrls: ['./business-region.component.scss']
})

/**
 * Creates the grid for managing the business regions
 */
export class BusinessRegionComponent implements OnInit {
  // ------------- Members ----------------------
  rowData: [any] = [{}];
  activeBusiness: any;
  public rowCount = 0;
  public frameworkComponents: any;
  areas: any;
  selectedArea: string;
  private MODAL_WIDTH: string = '550px';
  public title = ['Business', 'Regions'];

  // Icons
  faPlusCircle = faPlusCircle;
  faBars = faBars;
  faFileExcel = faFileExcel;
  faFileCsv = faFileCsv;

  // Config for Excel Export
  csvOptions: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Business Region Data :',
    useBom: true,
    noDownload: false
  }

  // -------------- Initialization --------------
  constructor(private businessRegionService: BusinessRegionService, public dialog: MatDialog, private headerTitleService: HeaderTitleService) {
    this.frameworkComponents = {
      buttonRenderer: ManageRegionButtons
    };

    this.gridOptions.context = {
      componentParent: this
    }
  }

  /**
   * Initializes the class and gets the data from the Laravel server
   */
  ngOnInit(): void {
    // Set the title
    this.headerTitleService.setTitle(this.title);

    // Get the assigned business from local storage
    this.activeBusiness = JSON.parse(localStorage.getItem('selectedBusiness'));
    this.areas = new Array();
    this.gridOptions.frameworkComponents = this.frameworkComponents;
    if (this.activeBusiness) {
      // Load the region hierarchy
      this.businessRegionService.getRegionsByBusinessID(this.activeBusiness.ID).subscribe(result => {
        this.rowData = result.data;
        this.rowCount = result.count;
      });

      this.gridOptions.frameworkComponents = this.frameworkComponents;
      // Load the areas for managing the hierarchy
      this.businessRegionService.getAreasByBusiness(this.activeBusiness.ID).subscribe(result => {

        let sortedAreas = _.sortBy(result.data, 'regionName');

        sortedAreas.forEach(area => {
          this.areas.push({ key: area.ID + "|" + area.regionName, value: area.regionName });
        });
      });
    }
  }

  // ----------- Tree Grid config and events --------------------

  /**
   * Defines each of the columns on the grid
   */
  columnDefs = [
    {
      headerName: 'Region Name', field: 'regionName', sortable: true, filter: true, editable: true, onCellValueChanged: function (ele) {
        ele.data.origValue = ele.oldValue;
        ele.data.childCount = ele.node.allChildrenCount;
        if (ele.node.allChildrenCount > 0) this.gridOptions.api.showLoadingOverlay();

        // Update the hierarchy field value
        ele.node.groupData["ag-Grid-AutoColumn"] = ele.data.regionName;
        this.gridOptions.api.refreshView();

        // Call Laravel and update the data
        this.businessRegionService.updateBusinessRegion(ele.data).subscribe(result => {
          // refresh the tree if the hierarchy has changed
          if (ele.node.allChildrenCount > 0) {
            this.reloadGrid();
          }
        });
      }.bind(this)
    },
    {
      headerName: 'Region ID', field: 'businessRegionID', sortable: true, filter: true, editable: true, onCellValueChanged: function (ele) {
        this.businessRegionService.updateBusinessRegion(ele.data).subscribe(result => { });
      }.bind(this)
    },
    {
      headerName: 'Description', field: 'description', sortable: true, filter: true, editable: true, onCellValueChanged: function (ele) {
        this.businessRegionService.updateBusinessRegion(ele.data).subscribe(result => { });
      }.bind(this)
    },
    { headerName: 'Action', field: 'value', headerClass: 'text-center', cellClass: 'text-center', resizable: false, width: 70, cellRendererFramework: ManageRegionButtons }
  ];

  gridOptions: GridOptions = {
    api: null,
    columnDefs: this.columnDefs,
    pagination: true,
    rowSelection: 'single',
    paginationPageSize: 20,
    rowHeight: 30,
    headerHeight: 30,
    autoSizePadding: 0,
    treeData: true,
    groupDefaultExpanded: 5,
    domLayout: 'autoHeight',

    autoGroupColumnDef: {
      headerName: "Hierarchy",
      minWidth: 330,
      flex: 1,
      cellRendererParams: {
        suppressCount: false
      }
    },

    /**
     * Sets the grid to auto column and assigns the API to a member variable for use in the exporting of data
     * @param event
     */
    onGridReady: function (event) {
      event.api.sizeColumnsToFit();
      this.grid = this;
      this.api = event.api;
      event.columnApi.sizeColumnsToFit(document.querySelectorAll('.container')[0].clientWidth - 40);
    },

    /**
     * Method used to split the path into a hierarchy array for the tree
     * @param data
     */
    getDataPath: function (data) {
      // Deal with empty data
      if (!data.ID) {
        data.ID = "";
        data.regionName = "";
      }

      if (!data.businessRegionArea) data.businessRegionArea = { fullPath: data.regionName, businessRegionGroupID: data.ID };

      // Split the data into an array and return;
      return data.businessRegionArea.fullPath.split("|");
    },
  }


  // ------------ Functions ----------------------

  /**
   * Removes a region from the hierarchy as well as all of its children
   * @param cell 
   */
  public removeBusinessRegion(cell: any) {
    let id = Number(cell.row.businessRegionArea.businessRegionAreaID);
    this.gridOptions.api.showLoadingOverlay();
    this.businessRegionService.deleteBusinessRegionArea(id, this.activeBusiness.ID).subscribe(res => {
      this.areas.push({ key: cell.row.ID + "|" + cell.row.regionName, value: cell.row.regionName });
      this.reloadGrid();
    });
  }

  /**
   * Opens the dialog to associate an area to a parent region/area
   * @param cell
   */
  public openEditHierarchy(cell: any) {
    // Set the data to pass to the modal
    let businessRegionGroupID = cell.row.businessRegionArea.businessRegionGroupID ? cell.row.businessRegionArea.businessRegionGroupID : cell.ID;
    let info = {
      width: this.MODAL_WIDTH,
      data: {
        areas: this.areas,
        businessID: this.activeBusiness.ID,
        parentID: cell.row.ID,
        basePath: cell.row.businessRegionArea.fullPath,
        area: '',
        businessRegionGroupID: businessRegionGroupID,
        areaID: 0,
        nodeDepth: cell.row.businessRegionArea.fullPath.split("|").length,
        fullPath: ''
      }
    };

    // Open the modal
    const dialogRef = this.dialog.open(AssociateAreaModal, info);

    // Call back for the modal when closing
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.gridOptions.api.showLoadingOverlay();

        // Assign the data elements to match the view model
        let area = info.data.area.split("|");
        info.data.fullPath = info.data.basePath + "|" + area[1];
        info.data.areaID = Number(area[0]);
        delete info.data.areas;
        delete info.data.area;
        delete info.data.basePath;

        // Saving the add region area object
        this.businessRegionService.addBusinessRegionArea(info.data).subscribe(res => {
          // Remove that Item from the areas list
          this.removeArea(info.data.areaID);

          // After the data has been saved, reload the grid data
          this.reloadGrid();
        });
      }
    });
  }

  /**
   * 
   * @param id Area ID of the item to remove
   */
  private removeArea(id: number) {
    if (this.areas.length < 2) {
      this.areas = new Array();
      return;
    }

    let tempAreas = new Array();
    for (let i = 0; i < this.areas.length; i++) {
      let key = this.areas[i].key;
      if (!key.startsWith(id + "|")) {
        tempAreas.push(this.areas[i]);
      }
    }

    this.areas = tempAreas;
  }

  /**
   * Reloads the grid data and refreshes the grid display
   */
  private reloadGrid() {
    // After the data has been saved, reload the grid data
    this.businessRegionService.getRegionsByBusinessID(this.activeBusiness.ID).subscribe(result => {
      this.gridOptions.api.setRowData(result.data);
      this.gridOptions.api.hideOverlay();
    });
  }

  /**
   * Opens the modal to add a new region and performs the insert to laravel
   */
  public addBusinessRegion() {
    // Set the data to pass to the modal
    var info = {
      width: this.MODAL_WIDTH,
      data: {
        businessID: this.activeBusiness.ID,
        regionName: '',
        isParent: 0,
        isActive: 1
      }
    };

    const dialogRef = this.dialog.open(EditRegionModal, info);

    // Call back for the modal when closing
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.gridOptions.api.showLoadingOverlay();

        // Save the business region
        this.businessRegionService.addBusinessRegion(info.data).subscribe(res => {

          // Add data to the areas array
          if (res.data.isParent === 0) {
            this.areas.push({ key: res.data.ID + "|" + res.data.regionName, value: res.data.regionName });

            // After the data has been saved, reload the grid data
            this.reloadGrid();
          } else {
            this.gridOptions.api.hideOverlay();
          }
        });
      }
    });
  }

  /**
   * Exports the grid data as CSV or Excel format
   * @param type Either csv or excel.  Called from the menu ont he grid panel
   */
  exportGrid(type: string) {
    if (type === 'csv') this.gridOptions.api.exportDataAsCsv(this.csvOptions);
    else this.gridOptions.api.exportDataAsExcel({
      fileName: 'Users',
      allColumns: true
    });
  }
}
