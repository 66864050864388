import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Business } from '../../models/business';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { AliasService } from '../alias.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../user/user.service';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, tap, switchMap, finalize, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { BusinessService } from '../../operator/business.service';
import { ApiResponse } from '../../models/apiResponse';

@Component({
  templateUrl: './mobile-alias.component.html',
  styleUrls: ['./mobile-alias.component.scss']
})
export class MobileAliasComponent implements OnInit {

  businessSearchCtrl = new FormControl();
  activeBusiness = new Business();
  businesses = [];
  headerAlias;
  routeData;
  defaultValues = [];
  aliasArray = [];
  defaultArray = [];
  isLoading = false;
  errorMsg = null;
  faSave = faSave;
  newAlias = false;
  selectedInput;

  constructor(private aliasService: AliasService,
    private route: ActivatedRoute,
    private userService: UserService,
    private toastr: ToastrService,
    private businessService: BusinessService) { }

  ngOnInit(): void {

    let defaults = <ApiResponse>this.route.snapshot.data.defaultAliases;
    if (defaults && defaults.data) {
      this.defaultValues = this.route.snapshot.data.defaultAliases.data;
      this.headerAlias = this.defaultValues;

      this.setAliasArrayValue(this.defaultValues, false);
    }
    this.activeBusiness = JSON.parse(localStorage.getItem('selectedBusiness'));
    if (!this.activeBusiness || !this.activeBusiness.ID) {
      return;
    }

    this.aliasService.getAllMobileAlisesByBusiness(this.activeBusiness.ID).subscribe(result => {

      if (!result.data.ID) {
        this.newAlias = true;
        this.headerAlias = this.defaultValues;
        this.headerAlias.ID = null;
        this.headerAlias.businessID = this.activeBusiness.ID


        this.setAliasArrayValue(this.headerAlias, true)
      }
      else if (result.data.ID) {
        this.headerAlias = result.data;
        this.setAliasArrayValue(this.headerAlias, false)
        this.isLoading = false;
      };

    })
  }


  generateArrayOfAliases(obj) {
    return Object.keys(obj).map((key) => {

      return { key: key, value: obj[key] }
    }).reduce(function (result, alias) {
      if (["ID", "businessID"].indexOf(alias.key) === -1) {
        result.push(alias);
      }
      return result;
    }, []);
  }



  setAliasArrayValue(aliasValues, isEmpty) {
    this.aliasArray = _.zipWith(
      this.generateArrayOfAliases(aliasValues),
      this.generateArrayOfAliases(this.defaultValues),
      function (a, b) {
        if (["ID", "businessID"].indexOf(a.key) > -1) {
          return;
        }
        let aliasKey = a.key;
        let aliasValue = isEmpty ? null : a.value;
        let defaultText = b.value;
        return { aliasKey, aliasValue, defaultText }
      })
  }
  ticketOptionChanged() {
    this.setAliasArrayValue(this.defaultValues, false)
  }
  updateAlias() {
    this.aliasArray.forEach(alias => {
      _.set(this.headerAlias, alias.aliasKey, alias.aliasValue);
    });
    this.aliasService.updateMobileAlias(this.headerAlias).subscribe(result => {
      if (result.success) {
        this.toastr.success('Header Alias successfully updated!');
        this.headerAlias = result.data;
      }
    })
  }
  createAlias() {
    this.aliasArray.forEach(alias => {
      _.set(this.headerAlias, alias.aliasKey, alias.aliasValue);
    });
    this.aliasService.createMobileAlias(this.headerAlias).subscribe(result => {
      if (result.success) {
        this.toastr.success('Header Alias record successfully created!');
        this.headerAlias = result.data;
        this.newAlias = false;
      }

    })
  }
}


