import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReinstateTicketComponent } from './reinstate-ticket/reinstate-ticket.component';
import { MainLayoutComponent } from '../layout/main-layout/main-layout.component';
import { ReviewTicketComponent } from './review-ticket/review-ticket.component';
import { TicketDetailResolver } from '../shared/resolvers/ticket-details.resolver';
import {AutoRejectTicketComponent} from "./auto-reject-ticket/auto-reject-ticket.component";
import {AutoRejectTicketListComponent} from "./auto-reject-ticket/auto-reject-ticket-list/auto-reject-ticket-list.component";


const routes: Routes = [
  {
    path: 'ticket',
    component: MainLayoutComponent,
    children: [
      { path: 'reinstate-ticket', component: ReinstateTicketComponent },
      {
        path: 'review-ticket/:id', component: ReviewTicketComponent, resolve: {
          ticket: TicketDetailResolver
        }
      },
      { path: 'auto-reject/create', component: AutoRejectTicketComponent },
      { path: 'auto-reject/list', component: AutoRejectTicketListComponent },
      { path: 'auto-reject/edit/:id', component: AutoRejectTicketComponent },
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TicketRoutingModule { }
