import { ColDef, GridOptions } from 'ag-grid-community';
import { EditAutoRejectButton } from '../renderers/edit-auto-reject-button';

// @dynamic
export class AutoRejectTicketGrid {
  public static getColumnDefs(): ColDef[] {
    return [
      {
        headerName: `Customer`,
        field: 'customer.businessName',
        filter: 'agTextColumnFilter',
        valueFormatter: ({ data }) => {
          if (!data?.customer?.businessName) {
            return '-';
          }
        },
      },
      {
        headerName: `Rejector`,
        field: 'rejector.person.personName',
        filter: 'agTextColumnFilter',
        valueFormatter: ({ data }) => {
          if (!data?.rejector?.person?.personName) {
            return '-';
          }
        },
      },
      {
        headerName: `Service Contractor`,
        field: 'serviceContractor.businessName',
        filter: 'agTextColumnFilter',
        valueFormatter: ({ data }) => {
          if (!data?.serviceContractor?.businessName) {
            return '-';
          }
        },
      },
      {
        headerName: `Service`,
        field: 'service.equipmentName',
        filter: 'agTextColumnFilter',
        valueFormatter: ({ data }) => {
          if (!data?.service?.equipmentName) {
            return '-';
          }
        },
      },
      {
        headerName: 'Actions',
        field: 'value',
        colId: 'action',
        minWidth: 100,
        width: 100,
        sortable: false,
        suppressMenu: true,
        filter: false,
        cellRendererFramework: EditAutoRejectButton,
        headerClass: 'text-center',
        pinned: 'right',
        lockPinned: true,
        lockVisible: true
      },
    ];
  }

  public static loadGridOptions(
    context: any = null
  ) {
    let gridOptions: GridOptions = {
      api: null,
      context: context,
      columnDefs: AutoRejectTicketGrid.getColumnDefs(),
      defaultColDef: {
        flex: 1,
        filter: true,
        filterParams: {
          filterOptions: ['contains'],
          suppressAndOrCondition: true,
        },
        minWidth: 150,
        resizable: true,
        sortable: true,
        suppressMenu: false,
        columnsMenuParams: {
          suppressColumnExpandAll: true,
          suppressColumnSelectAll: true
        }
      },
      pagination: true,
      rowSelection: 'single',
      paginationPageSize: 10,
      cacheBlockSize: 100,
      rowHeight: 40,
      headerHeight: 40,
      autoSizePadding: 0,
      domLayout: 'autoHeight',
      blockLoadDebounceMillis: 10,
      animateRows: true,

      /**
       * Sets the grid to auto column and assigns the API to a member variable for use in the exporting of data
       * @param event
       */
      onGridReady: function (event): void {
        this.grid = this;
        this.api = event.api;
        this.rowData = [];
        this.api.showLoadingOverlay();
      },

      onRowDataChanged() {
        filterGridByActiveBusiness(context.activeBusiness.businessName, this.api);
      }
      
    };

    const filterGridByActiveBusiness = (activeBusiness: number, gridApi): void => {
      const gridFilterModel = gridApi.getFilterInstance('serviceContractor.businessName');
      const value = gridFilterModel.setModel({type: "equal", filter: activeBusiness});
      gridApi.onFilterChanged(value);
    }

    return gridOptions;
  }
}
