export class ItemQRCode {
  ID: number;
  companyItemNo: string;
  properShippingName: string;
  itemDescription: string;
  itemPrice?: number;
  make?: any;
  model?: any;
  lot: string;
  itemTypeID: number;
  thirdPartyQRBody?: any;
  isDefaultItem?: any;
  wellsite?: any;
  afeId?: any;
  isAltAfeFlag?: any;

}
