import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploadDialogComponent } from '../file-upload-dialog/file-upload-dialog.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTrash, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FileuploadService } from '../../services/fileupload.service';

@Component({
    selector: 'file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

    /**
    * Whether we limit the upload to a single file, or permit multiple.
    */
    @Input() allowMultiple: boolean;

    /**
     * Text to be displayed within the button element.
     */
    @Input() buttonText: string;

    /**
     * Existing associated file(s) of the specified upload type; each object 
     * contains the filename to display to users and the S3 public URL.  
     */
    @Input() currentFiles: any[];

    /**
     * Comma-delimited list (case-insensitive) of what file extensions will be 
     * permitted for upload.  Note that this can be easily circumvented, so if 
     * we want something more stringent we should look at MIME type
     */
    @Input() extensionWhitelist: string;

    /**
     * What should the maximum permitted file size be?  Note: enforced separately 
     * on the server side, this merely provides a limit for the client interface. 
     */
    @Input() maxFileSize: number;

    /** 
     * ID of the ticket associated with this upload 
     */
    @Input() ticketId: number;

    /**
     * What kind of item are we uploading (e.g. attachment)?
     */
    @Input() uploadType: string;


    static readonly defaultButtonText = 'Upload File';
    static readonly defaultMaxFileSize = 10;                         // MB
    static readonly defaultModalWidth: string = '550px';
    static readonly defaultUploadType: string = 'unknown';
    static readonly defaultWhitelist = '.pdf,.gif,.jpg,.jpeg,.png';  // relatively common and "safe" filetypes  
    faTrash = faTrash;
    faExclamationTriangle = faExclamationTriangle
    static readonly maximumPermittedFileSize = 20;                   // MB 
    deletionError: boolean = false;

    constructor(public dialog: MatDialog, private fileuploadService: FileuploadService) { }

    ngOnInit(): void {
        if (this.buttonText == undefined) {
            this.buttonText = FileUploadComponent.defaultButtonText;
        }
        if (this.extensionWhitelist == undefined) {
            this.extensionWhitelist = FileUploadComponent.defaultWhitelist;
        }
        if (this.allowMultiple == undefined) {
            this.allowMultiple = false;
        }
        if (this.maxFileSize == undefined || isNaN(this.maxFileSize) || this.maxFileSize > FileUploadComponent.maximumPermittedFileSize) {
            this.maxFileSize = FileUploadComponent.defaultMaxFileSize;
        }
        if (this.uploadType == undefined) {
            this.uploadType = FileUploadComponent.defaultUploadType;
        }
        if (this.currentFiles == undefined) {
            this.currentFiles = [];
        }
    }

    /**
     * Dismisses the flag to display a file deletion error notification.
     */
    dismissError() {
        this.deletionError = false;
    }

    /**
     * Whether one or more files are currently associated with the current ticket.
     */
    filesExist(): boolean {
        if (this.currentFiles !== undefined && this.currentFiles.length > 0)
            return true;
        else
            return false;
    }

    /**
     * Handle a new file upload
     */
    handleFileUploadCompleted(files: object[]) {
        if (files !== null && files.length > 0) {
            for (let x = 0; x < files.length; x++) {
                let thisFile = files[x];
                this.currentFiles.push(thisFile);
            }
        }
    }

    /**
     * Handles the trashcan click event to delete an associated file.
     * @param index     Index of the item within this.currentFiles that is to be deleted.
     */
    handleTrashClick(index: number) {
        this.fileuploadService.deleteTicketFile(this.ticketId, this.currentFiles[index].name, this.uploadType).subscribe(
            res => {
                if (res.data.success !== undefined && res.data.success && res.data.filename !== undefined) {
                    // remove the item locally
                    for (let x = 0; x < this.currentFiles.length; x++) {
                        let thisObj = this.currentFiles[x];
                        if (thisObj.name === res.data.filename) {
                            this.currentFiles.splice(x, 1);
                        }
                    }
                } else {
                    this.deletionError = true;
                }
            }
        )
    }

    /**
     * Handles a file upload attempt.
     */
    handleUploadClicked() {
        let dialogData = {
            width: FileUploadComponent.defaultModalWidth,
            data: {
                allowedExtensions: this.extensionWhitelist,
                allowMultiple: this.allowMultiple,
                maxFileSize: this.maxFileSize,
                message: 'Please select the file to upload.',
                ticketId: this.ticketId,
                title: 'File Upload',
                uploadType: this.uploadType
            }
        };
        const dialogRef = this.dialog.open(FileUploadDialogComponent, dialogData);

        // listen for when the child file upload dialog is closed 
        dialogRef.afterClosed().subscribe(
            data => this.handleFileUploadCompleted(data)
        );
    }

    /**
     * Returns whether or not a file upload attempt is permitted at this time.
     */
    uploadPermitted(): boolean {
        if (this.allowMultiple || !this.filesExist())
            return true;
        else
            return false;
    }
}