<div class="container">
  <div class="col-lg-12">
    <form>
      <div class="row">
        <form *ngIf="this.activeBusiness">
          <div class="row" *ngIf="newAlias">
            <div class="col-lg-12">
              <p> <strong>Note:</strong> {{this.activeBusiness.businessName}} does not have an existing Mobile Header
                Alias
                record, so you will be creating a new one upon saving.</p>
            </div>
          </div>
          <div class="row alias-container">
            <div class="alias" *ngFor="let alias of aliasArray ">
              <label for="{{alias.aliasKey}}" class="form-label">{{alias.defaultText?alias.defaultText:alias.aliasKey }}
                <div style="display:inline; font-size:10px;" *ngIf="selectedInput === alias.aliasKey" matSuffix>
                  <i>{{alias.aliasKey}}</i>
                </div>
              </label>
              <input (click)="selectedInput = alias.aliasKey" (blur)="selectedInput = null" id="{{alias.aliasKey}}"
                class="form-control no-padding full-width" name="{{alias.aliasKey}}" [(ngModel)]="alias.aliasValue">
            </div>
          </div>
          <div class="row">
            <div *ngIf="!newAlias" class="col-lg-12 center">
              <button class="btn btn-primary" [disabled]="isLoading" (click)="updateAlias()">
                <fa-icon [icon]="faSave"></fa-icon> Update Alias
              </button>
            </div>
            <div *ngIf="newAlias" class="col-lg-12 center">
              <button class="btn btn-primary" [disabled]="isLoading" (click)="createAlias()">
                <fa-icon [icon]="faSave"></fa-icon> Create Alias
              </button>
            </div>
          </div>
        </form>
        <div *ngIf="!this.activeBusiness">
          Please select a business to view the header alias.
        </div>
      </div>
