import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../models/apiResponse';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class BusinessRegionService {

  constructor(private http: HttpClient) { }

  /**
   * Retrieves a list of regions by business
   * @param businessID 
   */
  getRegionsByBusinessID(businessID: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/businessRegion/${businessID}/hierarchy?type=admin`)
      .pipe(map((response) => { return <ApiResponse>response }));
  }

  /**
 * Retrieves a list of regions by business
 * @param businessID 
 */
  getAreasByBusiness(businessID: number): Observable<any> {

    return this.http.get(`${environment.apiUrl}/api/businessRegion?businessID=${businessID}&isParent=0&pagination=0&businessRegionArea=`)
      .pipe(map((response) => { return <ApiResponse>response }));
  }

  /**
   * Sends a request to insert the provided data into the businessRegionTable
   * @param businessRegion 
   */
  addBusinessRegion(businessRegion: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/businessRegion`, businessRegion);
  }

  /**
 * Sends a request to update the provided data to the businessRegionTable
 * @param businessRegion 
 */
  updateBusinessRegion(businessRegion: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/api/businessRegion/${Number(businessRegion.ID)}`, businessRegion);
  }

  // ---------------------- Business Region Area Calls ---------------------------------

  /**
 * Sends a request to insert the provided data into the businessRegionTable
 * @param businessRegion 
 */
  addBusinessRegionArea(businessRegionArea: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/businessRegionArea`, businessRegionArea);
  }

  /**
   * Removes an element from the hierarchy and all of its children
   * @param $id 
   */
  deleteBusinessRegionArea(id: number, businessId: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/api/businessRegionArea/${id}?businessID=${businessId}`);
  }
}
