import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../angular-material.module';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { DataValidationRoutingModule } from './data-validation-routing.module';
import { ScadaValidationFormComponent } from './scada-validation-form/scada-validation-form.component';
import { ScadaValidationListComponent } from './scada-validation-list/scada-validation-list.component';
import { AgGridModule } from 'ag-grid-angular';
import { ScadaValidationGridActionButtons } from './scada-validation-list/scada-validation-grid-buttons';


@NgModule({
  declarations: [ScadaValidationFormComponent, ScadaValidationListComponent, ScadaValidationGridActionButtons],
  imports: [
    CommonModule,
    DataValidationRoutingModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    SharedModule,
    FormsModule,
    FontAwesomeModule,
    AgGridModule.withComponents([
      ScadaValidationListComponent
    ]),
  ]
})
export class DataValidationModule { }




