<form id="business-select-form">
  <mat-form-field color="grey">
    <mat-label>Active Business</mat-label>
    <input id="business-selector-id" matInput [formControl]="businessSearchCtrl" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayBusiness"
      (optionSelected)="businessSelected($event)">
      <mat-option *ngIf="isBusinessLoading" class="is-loading">Retrieving Businesses...</mat-option>
      <ng-container *ngIf="!isBusinessLoading">
        <mat-option *ngFor="let business of businesses " [value]="business" (onSelect)="businessSelected(business)">
          <span>{{business.businessName}}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</form>