import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'zone.js/dist/zone';
import { datadogRum } from '@datadog/browser-rum';
import { LicenseManager } from 'ag-grid-enterprise';


if (environment.production) {
  enableProdMode();
}

LicenseManager.setLicenseKey('CompanyName=Engage Mobilize,LicensedApplication=Engage Invoicing,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-014741,ExpiryDate=6_April_2022_[v2]_MTY0OTE5OTYwMDAwMA==b5f72f99e766d81da2dad2ff2c3d16b7');

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch((err) => console.error(err));

if (environment?.shouldRunDataDog) {
  datadogRum.init({
    applicationId: '176ee617-b72e-4374-baf6-fb7548ccad8c',
    clientToken: 'pub89889aff8dbe298d0771e278a07454ee',
    site: 'datadoghq.com',
    service: 'angular-administration',
    env: environment.production ? 'production' : 'development',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
  datadogRum.startSessionReplayRecording();
}
