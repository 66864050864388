import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment} from "../../../../environments/environment";
import {AutoReject} from "../../../models/autoReject";

@Injectable({
  providedIn: 'root'
})
export class AutoRejectTicketService {
  constructor(private http: HttpClient) {}

  getPaginatedAutoRejects(search: string = '', page: number = 1, perPage:number = 50) : Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/autoReject?search=${search}&page=${page}&perpage=${perPage}`);
  }

  getCurrentAutoReject(id: number) : Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/autoReject/${id}`);
  }

  saveAutoReject(autoReject: AutoReject, autoRejectID: number = null): Observable<any> {
    if(autoRejectID) {
      return this.http.put(`${environment.apiUrl}/api/autoReject/${autoRejectID}`, autoReject);
    }

    return this.http.post(`${environment.apiUrl}/api/autoReject`, autoReject);
  }
}
