import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../user/user.service';
import { Business } from '../../models/business';
import { AliasService } from '../../alias/alias.service';

@Injectable({
  providedIn: 'root'
})
export class MobileHeaderAliasResolver implements Resolve<Observable<any[]>> {

  constructor(private aliasService: AliasService) { }

  resolve(): Observable<any> {
    return this.aliasService.getAllMobileAlisesByBusiness(10); //TODO: Add these to global config
  }
}
