import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from '../user';
import { Business } from '../../models/business';
import { UserService } from '../user.service';
import { CredentialType } from '../../models/credentialType';
import { PersonType } from '../../models/personType';
import { ActivatedRoute, Router } from '@angular/router';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { BusinessService } from '../../operator/business.service';
import { Timezone } from '../../models/timezone';
import { PasswordRequirements } from '../../auth/update-password/password-requirements';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  user = new User();
  createOrModify = '';
  newUser = true;
  businesses: Business[];
  timezones: Timezone[];
  credentialTypes: CredentialType[];
  personTypes: PersonType[];
  faSave = faSave;
  isPasswordChanged = false;
  constructor(private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private businessService: BusinessService) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {

      if (params.get('id')) {
        this.userService.getUser(Number(params.get('id'))).subscribe(result => {
          this.user = result.data;
          this.createOrModify = 'Modify User';
          this.newUser = false;
          this.populateUserForm();
        })
      } else {
        this.createOrModify = 'Modify User';
      }
    });

    this.businessService.getAllBusinesses().subscribe(result => {
      this.businesses = result.data;
    });
    this.userService.getAllTimezones().subscribe(result => {
      this.timezones = result.data;
    });
    this.userService.getAllPersonTypes().subscribe(result => {
      this.personTypes = result.data;
    });
    this.userService.getAllCredentialTypes().subscribe(result => {
      this.credentialTypes = result.data;
    });
  }
  saveUser() {
    if (this.userForm.valid)
      this.user = this.userForm.getRawValue();

    if (this.newUser) {
      this.userService.createUser(this.user).subscribe(result => {
        if (result.success) {
          this.toastr.success('User successfully created!');
        }
      });
    } else {
      if (this.isPasswordChanged) {
        this.userService.updatePassword(this.user).subscribe(
          () => {
            delete this.user.password;
            this.userService.updateUser(this.user).subscribe(
              () => {
                this.toastr.success('User successfully updated!');
              },
              err => {
                this.toastr.error('Please try again, the user was not updated.');
              });
          },
          err => {
            if (err === 'Unprocessable Entity') {
              this.toastr.error('Please ensure the password does not match any of the last 5 passwords.');
            } else {
              this.toastr.error(err);
            }
          }
        );
      } else {
        this.userService.updateUser(this.user).subscribe(result => {
          if (result.success) {
            this.toastr.success('User successfully updated!');
          }
        });
      }
    }
  }
  passwordChanged() {
    this.isPasswordChanged = true;
  }
  populateUserForm() {
    if (this.newUser) {
      this.userForm.patchValue({
        ID: this.user.ID,
        username: this.user.username,
        personName: this.user.personName,
        businessID: this.user.businessID,
        isEnabled: this.user.isEnabled,
        credentialTypeID: this.user.credentialTypeID,
        phoneNumber: this.user.phoneNumber,
        timezoneID: this.user.timezoneID,
        email: this.user.email,
        personTypeID: this.user.personTypeID
      })
    } else {
      this.userForm.patchValue({
        ID: this.user.ID,
        username: this.user.username,
        personName: this.user.personName,
        businessID: this.user.businessID,
        password: 'engAg3!',
        isEnabled: this.user.isEnabled,
        credentialTypeID: this.user.credentialTypeID,
        phoneNumber: this.user.phoneNumber,
        timezoneID: this.user.timezoneID,
        email: this.user.email,
        personTypeID: this.user.personTypeID
      })
    }
  }
  userForm = new FormGroup({
    ID: new FormControl(''),
    username: new FormControl('', Validators.required),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6)
    ]),
    personName: new FormControl('', Validators.required),
    businessID: new FormControl('', Validators.required),
    isEnabled: new FormControl(''),
    credentialTypeID: new FormControl('', Validators.required),
    phoneNumber: new FormControl('', []),
    timezoneID: new FormControl('', Validators.required),
    email: new FormControl('', []),
    personTypeID: new FormControl('', Validators.required)
  },
    {
      validators: [
        PasswordRequirements.passwordMustMeetPatternRequirements
      ]
    });

}
