
import { Injectable, Input } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { DataSourceSetupModel, DefaultItemService, GridUtility, PaginationSortModel } from 'lib-shared';
import { DataValidationService } from '../data-validation.service';
import { ScadaValidationGridActionButtons } from './scada-validation-grid-buttons';
@Injectable()
export class ScadaValidationGrid {

  @Input() paginationPageSize: number;
  private frameworkComponents;
  public static gridApi;

  public static columnDefs = [
    {
      headerName: 'Default Item ID', field: 'ID', sortable: false, filter: 'text'
    },

    {
      headerName: 'Service Contractor', field: 'serviceContractor.businessName', sortable: false, filter: 'text',
    },
    {
      headerName: 'Service', field: 'businessEquipment.equipmentName', sortable: false, filter: 'text', //maxWidth: 175
    },

    {
      headerName: '', field: 'value', sortable: false, filter: false, headerClass: 'text-center',
      cellClass: ['text-center', 'action-cell'], maxWidth: 100, resizable: false, cellRendererFramework: ScadaValidationGridActionButtons
    }
  ];

  public static currencyFormatter(currency, sign) {
    if (currency) {
      var sansDec = currency.toFixed(2);
      var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return sign + `${formatted}`;
    }
    return '';

  }

  public static locationFormatter(data) {
    if (data && data.afe) {
      return data.afe.wellSite;
    }
    if (data && data.businessRegion) {
      return data.businessRegion.businessRegionID
    }

  }

  public static loadGridOptions(context: any, dataValidationGrid: DataValidationService, businessID: number) {

    let dataSourceSetup: DataSourceSetupModel = {
      context: context,
      service: dataValidationGrid,
      serviceCall: 'getFlowCheckByOperator',
      serviceCallParams: [businessID],
      defaultPagination: <PaginationSortModel>{ perpage: 100 }
    };
    var gridOptions: GridOptions = {
      api: null,
      columnDefs: ScadaValidationGrid.columnDefs,
      defaultColDef: { flex: 1, resizable: true },
      pagination: true,
      rowSelection: 'single',
      paginationPageSize: 20,
      rowHeight: 40,
      headerHeight: 40,
      rowModelType: 'serverSide',
      autoSizePadding: 10,
      domLayout: 'autoHeight',
      context: context,
      cacheBlockSize: 100,
      serverSideDatasource: GridUtility.getGridDataSource(dataSourceSetup, context.successCallback),
      blockLoadDebounceMillis: 50,

      //frameworkComponents: { ticketLocationEditor: TicketLocationEditorComponent, costCodeEditor: CostCodeEditorComponent },

      /**
       * Sets the grid to auto column and assigns the API to a member variable for use in the exporting of data
       * @param event
       */
      onGridReady: function (event) {

        this.grid = this;
        this.api = event.api;
        ScadaValidationGrid.gridApi = event.api;
        event.api.sizeColumnsToFit();
      },
      getRowNodeId: data => data.id
    }
    return gridOptions;
  };
  public static pageSizeChanged(newPageSize) {

    setTimeout(function () { this.gridApi.paginationSetPageSize(Number(newPageSize)) }, 0);
  }
  public static refreshCells() {
    ScadaValidationGrid.gridApi.purgeServerSideCache();
  }

};
