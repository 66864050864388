import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faSave, faBan, faRedo } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { DataValidationService } from '../data-validation.service';
import { DataValidationModel } from '../data-validation.model';

import { Business } from '../../models/business';
import { BusinessService } from '../../operator/business.service';

import { EquipmentType } from '../../models/equipmentType.';
import { ForemanEngineer } from '../../models/foremanEngineer';
import { FlowcheckConfiguration } from '../../models/flowcheckConfiguration';
import { BusinessEquipment } from 'dist/lib-shared/public-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-scada-validation-form',
  templateUrl: './scada-validation-form.component.html',
  styleUrls: ['./scada-validation-form.component.scss']
})
export class ScadaValidationFormComponent implements OnInit {

  isLoading = false;
  foreman: ForemanEngineer;
  foremen: ForemanEngineer[] = [];
  operator: Business;
  operators: Business[] = [];
  serviceContractor: Business;
  serviceContractors: Business[] = [];
  //equipmentType: EquipmentType;
  businessEquipments: BusinessEquipment[] = [];
  public submitted: boolean = false;
  validationData: FlowcheckConfiguration = null;
  scInfoLoaded = false;
  autoFlowcheckForm: FormGroup;
  selectedContractor: Business;
  editMode = false;


  constructor(private datvalidationservice: DataValidationService,
    private businessService: BusinessService,
    private toastr: ToastrService,
    private router: Router,
    public activeModal: NgbActiveModal,) { }

  faSave = faSave;
  faBan = faBan;
  faRedo = faRedo;

  ngOnInit(): void {

    this.autoFlowcheckForm = new FormGroup({
      ID: new FormControl(),
      //operatorID: new FormControl(null, Validators.required),
      serviceContractor: new FormControl(null, Validators.required),
      businessEquipment: new FormControl(null, Validators.required),
      foreman: new FormControl(null, Validators.required),
      maxJobHour: new FormControl(),
      minJobHour: new FormControl(null, Validators.min(0)),
      maxJobVolume: new FormControl(),
      comments: new FormControl(),
      minJobVolume: new FormControl(null, Validators.min(0)),
      maxVolumeVarianceLiteral: new FormControl(null, [Validators.min(0)]),
      maxVolumeVariancePercentage: new FormControl(null, [Validators.min(0), Validators.max(100)]),
      marginalVolumeVarianceLiteral: new FormControl(null, [Validators.min(0)]),
      marginalVolumeVariancePercentage: new FormControl(null, [Validators.min(0), Validators.max(100)]),
      maxVolumeVarianceLiteralAbove: new FormControl(null, [Validators.min(0)]),
      maxVolumeVarianceLiteralBelow: new FormControl(null, [Validators.min(0)]),
      maxVolumeVariancePercentageAbove: new FormControl(null, [Validators.min(0), Validators.max(100)]),
      maxVolumeVariancePercentageBelow: new FormControl(null, [Validators.min(0), Validators.max(100)]),
      marginalVolumeVarianceLiteralAbove: new FormControl(null, [Validators.min(0)]),
      marginalVolumeVarianceLiteralBelow: new FormControl(null, [Validators.min(0)]),
      marginalVolumeVariancePercentageAbove: new FormControl(null, [Validators.min(0), Validators.max(100)]),
      marginalVolumeVariancePercentageBelow: new FormControl(null, [Validators.min(0), Validators.max(100)]),
      verificationID: new FormControl(null, Validators.required),
      autoApprove: new FormControl(true),
      autoFail: new FormControl(true),
      automationDataRequired: new FormControl(true),

    }, { validators: [this.formValidation()] });

    if (this.validationData) {

      //I didn't create these variable names, blame Sellers.
      this.validationData.maxVolumeVariancePercentageAbove = this.validationData.maxVolumeVariancePercentageAbove &&
        this.validationData.maxVolumeVariancePercentageAbove > 0 ? this.validationData.maxVolumeVariancePercentageAbove * 100 : null;

      this.validationData.maxVolumeVariancePercentageBelow = this.validationData.maxVolumeVariancePercentageBelow &&
        this.validationData.maxVolumeVariancePercentageBelow > 0 ? this.validationData.maxVolumeVariancePercentageBelow * 100 : null;

      this.validationData.marginalVolumeVariancePercentageAbove = this.validationData.marginalVolumeVariancePercentageAbove &&
        this.validationData.marginalVolumeVariancePercentageAbove > 0 ? this.validationData.marginalVolumeVariancePercentageAbove * 100 : null;

      this.validationData.marginalVolumeVariancePercentageBelow = this.validationData.marginalVolumeVariancePercentageBelow &&
        this.validationData.marginalVolumeVariancePercentageBelow > 0 ? this.validationData.marginalVolumeVariancePercentageBelow * 100 : null;

      this.autoFlowcheckForm.patchValue(this.validationData);
      this.editMode = true;
      this.serviceContractorSelected(this.validationData.businessTruckerID);
    }
  }
  serviceContractorSelected(serviceContractorID) {
    this.businessService.getBusinessEquipmentByBusinessID(serviceContractorID)
      .subscribe(result => {
        this.businessEquipments = result.data;
        this.scInfoLoaded = true;
      })
  }
  get f() { return this.autoFlowcheckForm.controls; }

  saveAutoFlowcheckForm() {
    this.submitted = true;

    if (this.autoFlowcheckForm.valid) {
      let formData = this.autoFlowcheckForm.getRawValue();
      this.validationData = formData;
      this.validationData.businessOperatorID = this.operator.ID;
      this.validationData.businessTruckerID = formData.serviceContractor.ID;
      this.validationData.foremanID = formData.foreman.businessPersonID;
      this.validationData.serviceTypeID = formData.businessEquipment.ID;

      if (this.validationData != null) {

        //Divide the percentages by 100 to save the decimal.
        this.validationData.maxVolumeVariancePercentageAbove = this.validationData.maxVolumeVariancePercentageAbove &&
          this.validationData.maxVolumeVariancePercentageAbove > 0 ? this.validationData.maxVolumeVariancePercentageAbove / 100 : null;

        this.validationData.maxVolumeVariancePercentageBelow = this.validationData.maxVolumeVariancePercentageBelow &&
          this.validationData.maxVolumeVariancePercentageBelow > 0 ? this.validationData.maxVolumeVariancePercentageBelow / 100 : null;

        this.validationData.marginalVolumeVariancePercentageAbove = this.validationData.marginalVolumeVariancePercentageAbove &&
          this.validationData.marginalVolumeVariancePercentageAbove > 0 ? this.validationData.marginalVolumeVariancePercentageAbove / 100 : null;

        this.validationData.marginalVolumeVariancePercentageBelow = this.validationData.marginalVolumeVariancePercentageBelow &&
          this.validationData.marginalVolumeVariancePercentageBelow > 0 ? this.validationData.marginalVolumeVariancePercentageBelow / 100 : null;


        if (this.editMode) {
          this.datvalidationservice.updateFlowCheck(this.validationData as FlowcheckConfiguration).subscribe(
            data => {
              this.toastr.success('Flowcheck Configuration Updated Successfully', 'Success');
              this.activeModal.close(true);
            },
            error => {
              this.toastr.error('Error Updating Flowcheck Configuration', 'Error');
              this.submitted = false;
            }
          );
        } else {
          this.datvalidationservice.createFlowCheck(this.validationData as FlowcheckConfiguration).subscribe(data => {
            this.toastr.success('Flowcheck Configuration Created Successfully', 'Success');
            this.activeModal.close(true);
          },
            _error => {

              this.toastr.error('Error Creating Flowcheck Configuration', 'Error');
              this.submitted = false;
            });
        }
      }
    }

  }

  onCancelClick() {
    this.activeModal.close(false);
  }
  displayServiceContractor(business?): string | undefined {
    return business ? business.businessName : undefined;
  }
  filteredServices(value: string) {
    const filterValue = value.toLowerCase();
    if (value) {
      return this.businessEquipments.filter(service =>
        (service.equipmentName.toLowerCase().indexOf(filterValue) > -1));
    } else {
      return this.businessEquipments;
    }

  }
  filteredForemen(value: string) {
    const filterValue = value.toLowerCase();
    if (value) {
      return this.foremen.filter(f =>
        (f.personName.toLowerCase().indexOf(filterValue) > -1));
    } else {
      return this.foremen;
    }

  }
  displayEquipmentType(et?): string | undefined {
    return et ? et.equipmentName : undefined;
  }
  displayForeman(f?): string | undefined {
    return f ? f.personName : undefined;
  }

  public marginalVarianceAboveValidator(control: AbstractControl) {

    const marginalVolumeVariancePercentageAbove = control.get('marginalVolumeVariancePercentageAbove');
    const maxVolumeVariancePercentageAbove = control.get('maxVolumeVariancePercentageAbove');

    return marginalVolumeVariancePercentageAbove && maxVolumeVariancePercentageAbove
      && marginalVolumeVariancePercentageAbove.value < maxVolumeVariancePercentageAbove.value ? { maxVolumeVarianceAboveInvalid: true } : null;
  };
  public inputValidator(control: AbstractControl, form: string, form2?: string): boolean {
    if (!control) {
      return;
    }
    return control.touched && (control.hasError(form) || control.hasError(form2));
  }

  formValidation(): ValidatorFn {

    return (form: FormGroup): ValidationErrors | null => {
      let errors: ValidationErrors = {};
      const marginalVolumeVariancePercentageBelow = form.get('marginalVolumeVariancePercentageBelow').value;
      const maxVolumeVariancePercentageBelow = form.get('maxVolumeVariancePercentageBelow').value;


      //marginalVolumeVariancePercentageBelow Check
      let maxVolumeVarianceBelowInvalid = marginalVolumeVariancePercentageBelow < maxVolumeVariancePercentageBelow ? { maxVolumeVarianceBelowInvalid: true } : null;

      if (maxVolumeVarianceBelowInvalid) {
        errors["maxVolumeVarianceBelowInvalid"] = true;
      }

      //marginalVolumVariancePercentageAbove Check
      const marginalVolumeVariancePercentageAbove = form.get('marginalVolumeVariancePercentageAbove').value;
      const maxVolumeVariancePercentageAbove = form.get('maxVolumeVariancePercentageAbove').value;
      let maxVolumeVarianceAboveInvalid = marginalVolumeVariancePercentageAbove < maxVolumeVariancePercentageAbove ? { maxVolumeVarianceAboveInvalid: true } : null;

      if (maxVolumeVarianceAboveInvalid) {
        errors["maxVolumeVarianceAboveInvalid"] = true;
      }



      const marginalVolumeVarianceLiteralBelow = form.get('marginalVolumeVarianceLiteralBelow').value;
      const maxVolumeVarianceLiteralBelow = form.get('maxVolumeVarianceLiteralBelow').value;


      //marginalVolumeVarianceLiteralBelow Check
      let maxVolumeVarianceLiteralBelowInvalid = marginalVolumeVarianceLiteralBelow < maxVolumeVarianceLiteralBelow ? { maxVolumeVarianceBelowInvalid: true } : null;

      if (maxVolumeVarianceLiteralBelowInvalid) {
        errors["maxVolumeVarianceLiteralBelowInvalid"] = true;
      }

      //marginalVolumeVarianceLiteralAbove Check
      const marginalVolumeVarianceLiteralAbove = form.get('marginalVolumeVarianceLiteralAbove').value;
      const maxVolumeVarianceLiteralAbove = form.get('maxVolumeVarianceLiteralAbove').value;
      let maxVolumeVarianceLiteralAboveInvalid = marginalVolumeVarianceLiteralAbove < maxVolumeVarianceLiteralAbove ? { maxVolumeVarianceAboveInvalid: true } : null;

      if (maxVolumeVarianceLiteralAboveInvalid) {
        errors["maxVolumeVarianceLiteralAboveInvalid"] = true;
      }

      return errors;
    }

  }
  get maxVolumeVariancePercentageBelow() {
    return this.autoFlowcheckForm.get('marginalVolumeVariancePercentageBelow');
  }

}
