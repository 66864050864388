import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperatorModule } from '../operator/operator.module'
import { HeaderComponent } from './header/header.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBars, faBell, faUserCircle, faHome } from '@fortawesome/free-solid-svg-icons';
import { MenuComponent } from './menu/menu.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { RouterModule } from '@angular/router';
import { HeaderTitleService } from './header-title.service';

@NgModule({
  declarations: [HeaderComponent, MenuComponent, MainLayoutComponent],
  imports: [CommonModule, RouterModule.forChild([]), FontAwesomeModule, OperatorModule],
  exports: [HeaderComponent, MenuComponent, MainLayoutComponent],
  providers: [HeaderTitleService]
})
export class LayoutModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faBars, faBell, faUserCircle, faHome);
  }
}
