import { Component, Input, OnInit } from '@angular/core';
import { GpsLocation } from '../../../models/gpsLocation';
import { Ticket } from '../../../models/ticket';
/// <reference types="@types/googlemaps" />
@Component({
  selector: 'afe-map',
  templateUrl: './afe-map.component.html',
  styleUrls: ['./afe-map.component.scss']
})
export class AfeMapComponent implements OnInit {

  /**
   * Map location center
   */
  @Input() afeMapLocation: GpsLocation = { latitude: 0, longitude: 0 };

  /**
   * 2-element array: start {} [0], destination {} [1]
   * format of an array element should be: { position: { lat: 0, lng: 0 }, clickable: false, icon: '' }
   */
  @Input() afeMarkers: google.maps.MarkerOptions[];

  /**
   * Associated ticket data; structure should always mirror review-ticket.component
   */
  @Input() ticketData: Ticket = { startTime: null, status: null };
  /**
     * Associated ticket data; structure should always mirror review-ticket.component
     */
  @Input() mapHeight: Number = 300;

  constructor() { }

  ngOnInit(): void {

  }

  isFirst(marker: google.maps.MarkerOptions): boolean {
    return this.afeMarkers.indexOf(marker) === 0;
  }

  getFormattedDate(startTime): string {
    const inputDate = new Date(startTime);
    const dateTimeFormat = new Intl.DateTimeFormat('en', {
      year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: 'numeric', second: 'numeric', hour12: false,
    });
    const [{ value: month }, , { value: day }, , { value: year }, ,
      { value: hour }, , { value: minute }, , { value: second }] = dateTimeFormat.formatToParts(inputDate);

    return `${month} ${day}, ${year} ${hour}:${minute}`;
  }

}
