import { Component, OnInit, NgModule } from '@angular/core';
import { User } from '../user';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faSearch, faEdit, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../user.service';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { HeaderTitleService } from '../../layout/header-title.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  users: User[];
  searchTerm = '';
  page = 1;
  faSearch = faSearch;
  faEdit = faEdit;
  faPlusCircle = faPlusCircle;
  length = 0;
  pageSize = 50;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  alwaysFloat = 'always';
  public title = ['Business', 'User List'];

  // MatPaginator Output
  pageEvent: PageEvent;
  constructor(private userService: UserService, private router: Router, private headerTitleService: HeaderTitleService) { }

  ngOnInit(): void {
    // Set the title
    this.headerTitleService.setTitle(this.title);
  }

  usersSearch() {
    this.userService.usersSearch(this.searchTerm, this.page).subscribe(result => {
      this.users = result.data
      this.length = result.count;
    });
  }
  paginationChange(event) {
    this.page = event.pageIndex + 1;  //Laravel uses literal page numbers (starting at 1), Angular uses array indexes (starting at 0)
    this.usersSearch();
    return this.pageEvent;
  }
  editUser(user) {
    this.router.navigate(['/user/edit/', user.ID]);
  }
  createUser() {
    this.router.navigate(['/user/create']);
  }
}


