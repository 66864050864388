<nav class="menu sidebar">
  <div class="navbar-nav" *ngIf="!activeBusiness">
    <li>
      <fa-icon class="warning-text" [icon]="faBusinessTime"></fa-icon> <span class="warning-text pad-left-5">Select a
        business!</span>
    </li>
  </div>
  <ul *ngIf="activeBusiness" class="navbar-nav">
    <li class="nav-item pad-left-5">
      <a class="nav-link" [ngClass]="{active : isMenuActive('/dashboard')}" [routerLink]="['/dashboard']"
        id="sidebarHome">
        <fa-icon [icon]="faHome"></fa-icon>
        <span class="pad-left-5">Home</span>
      </a>
    </li>
    <li class="nav-item pad-left-5">
      <a class="secondary" [ngClass]="{active : isMenuActive('/ticket/reinstate-ticket')}"
        [routerLink]="['/ticket/reinstate-ticket']" id="sidebarReinstateTicket">
        <fa-icon [icon]="faTicketAlt"></fa-icon>
        <span class="pad-left-5"> Reinstate Ticket</span>
      </a>
    </li>
    <li class="nav-item pad-left-5">
      <a class="nav-link" (click)="toggleMenuGroup('#sidebarLanguageSubNav')" id="sidebarLanguage">
        <fa-icon [icon]="faGlobeAmericas"></fa-icon>
        <span class="pad-left-5">Languages</span>
      </a>
      <ul class="engage-subnav collapse" [ngClass]="{'engage-subnav' : true, collapse : toggleLanguage}"
        id="sidebarLanguageSubNav">
        <li class="nav-item">
          <a [ngClass]="{active : isMenuActive('/alias/mobile-header-alias')}"
            [routerLink]="['/alias/mobile-header-alias']" id="sidebarUserList">
            <fa-icon [icon]="faMobile"></fa-icon>
            <span class="pad-left-5">Mobile Alias</span>
          </a>
        </li>
        <li class="nav-item">
          <a [ngClass]="{active : isMenuActive('/alias/header-alias')}" [routerLink]="['/alias/header-alias']"
            id="sidebarUserList">
            <fa-icon [icon]="faColumns"></fa-icon>
            <span class="pad-left-5">Header Alias</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="activeBusiness.businessTypeID == 1">
          <a [ngClass]="{active : isMenuActive('/operator/time-type-aliases')}"
            [routerLink]="['/operator/time-type-aliases']" id="sidebaropServices">
            <fa-icon [icon]="faToolbox"></fa-icon>
            <span class="pad-left-5">Phase Aliases</span>
          </a>
        </li>
      </ul>
    </li>

    <li class="nav-item pad-left-5">
      <a class="nav-link" (click)="toggleMenuGroup('#sidebarOperatorSubNav')" id="sidebarOperator">
        <fa-icon [icon]="faBuilding"></fa-icon>
        <span class="pad-left-5">Business</span>
      </a>
      <ul [ngClass]="{'engage-subnav' : true, collapse : toggleOperator}" id="sidebarOperatorSubNav">
        <!-- <li class="nav-item">
          <a [ngClass]="{active : isMenuActive('/operator/edit')}" [routerLink]="['/operator/edit']"
            id="sidebarOpConfig">
            <fa-icon [icon]="faWrench"></fa-icon>
            <span class="pad-left-5">Configuration</span>
          </a>
        </li> -->
        <li class="nav-item">
          <a [ngClass]="{active : isMenuActive('/operator/cost-codes')}" [routerLink]="['/operator/cost-codes']"
            id="sidebarCostCodes">
            <fa-icon [icon]="faMoneyBill"></fa-icon>
            <span class="pad-left-5">Cost Codes</span>
          </a>
        </li>
        <li class="nav-item">
          <a [ngClass]="{active : isMenuActive('/operator/region')}" [routerLink]="['/operator/region']"
            id="sidebarRegionList">
            <fa-icon [icon]="faUser"></fa-icon>
            <span class="pad-left-5">Regions</span>
          </a>
        </li>
        <li class="nav-item">
          <a [ngClass]="{active : isMenuActive('/operator/services')}" [routerLink]="['/operator/services']"
            id="sidebaropServices">
            <fa-icon [icon]="faToolbox"></fa-icon>
            <span class="pad-left-5">Services</span>
          </a>
        </li>

        <li class="nav-item">
          <a [ngClass]="{active : isMenuActive('/user/user-list')}" [routerLink]="['/user/user-list']"
            id="sidebarUserList">
            <fa-icon [icon]="faUser"></fa-icon>
            <span class="pad-left-5">Users</span>
          </a>
        </li>
        <li class="nav-item">
          <a [ngClass]="{active : isMenuActive('/data-validation/auto-flowcheck-form')}"
            [routerLink]="['/data-validation/auto-flowcheck-form']" id="sidebarDataValidation">
            <fa-icon [icon]="faUser"></fa-icon>
            <span class="pad-left-5">Data Validation</span>
          </a>
        </li>
      </ul>
    </li>

    <li class="nav-item pad-left-5">
      <a class="nav-link" (click)="toggleMenuGroup('#sidebarAutoRejectTicketsSubNav')" id="sidebarAutoRejectTickets">
        <fa-icon [icon]="faTicketAlt"></fa-icon>
        <span class="pad-left-5">Auto Reject Tickets</span>
      </a>

      <ul [ngClass]="{'engage-subnav' : true, collapse : toggleAutoRejectTickets}"
        id="sidebarAutoRejectTicketsListSubNav">
        <li class="nav-item">
          <a [ngClass]="{active : isMenuActive('/ticket/auto-reject/list')}" [routerLink]="['/ticket/auto-reject/list']"
            id="sidebarAutoRejectTicketList">
            <fa-icon [icon]="faList"></fa-icon>
            <span class="pad-left-5">List</span>
          </a>
        </li>
      </ul>

      <ul [ngClass]="{'engage-subnav' : true, collapse : toggleAutoRejectTickets}" id="sidebarAutoRejectTicketsSubNav">
        <li class="nav-item">
          <a [ngClass]="{active : isMenuActive('/ticket/auto-reject/create')}"
            [routerLink]="['/ticket/auto-reject/create']" id="sidebarAutoRejectTicket">
            <fa-icon [icon]="faPlus"></fa-icon>
            <span class="pad-left-5">Create</span>
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-item pad-left-5">
      <a class="secondary" [ngClass]="{active : isMenuActive('/data-validation/scada-data-validation-list')}"
        [routerLink]="['/data-validation/scada-data-validation-list']" id="SCADA Validation">
        <fa-icon [icon]="faTicketAlt"></fa-icon>
        <span class="pad-left-5"> SCADA Validation</span>
      </a>
    </li>
  </ul>
</nav>
