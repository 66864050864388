<h3 mat-dialog-title>
    Associate Child Element to {{data.basePath}}
</h3>
<form [formGroup]="areaForm" autocomplete="off" novalidate fxLayout="column wrap" fxLayoutAlign="center center"
    fxLayoutGap="10px">
    <mat-dialog-content>
        <mat-form-field>
            <mat-select required placeholder='Select an Area to Associate ...' name="area" formControlName="area">
                <mat-option *ngFor="let area of data.areas" [value]="area.key">
                    {{area.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button class="btn btn-secondary" mat-button (click)="onNoClick()">No</button>
        <button class="ml-auto btn btn-accent" mat-button [mat-dialog-close]="true"
            [disabled]="!areaForm.valid">Yes</button>
    </mat-dialog-actions>
</form>