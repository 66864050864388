import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderAliasComponent } from './header-alias/header-alias.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../angular-material.module';
import { SharedModule } from '../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AliasRoutingModule } from './alias-routing.module';
import { MobileAliasComponent } from './mobile-alias/mobile-alias.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
  declarations: [HeaderAliasComponent, MobileAliasComponent],
  imports: [
    CommonModule,
    AliasRoutingModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    SharedModule,
    FormsModule,
    FontAwesomeModule,
    BrowserAnimationsModule
  ]
})
export class AliasModule { }
