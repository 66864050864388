import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Business } from '../../models/business';
import { AliasService } from '../alias.service';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { debounceTime, tap, switchMap, finalize, take } from 'rxjs/operators';
import { UserService } from '../../user/user.service';
import { pipe } from 'rxjs';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { faSave, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { BusinessService } from '../../operator/business.service';
import { ApiResponse } from '../../models/apiResponse';
@Component({
  templateUrl: './header-alias.component.html',
  styleUrls: ['./header-alias.component.scss']
})
export class HeaderAliasComponent implements OnInit {

  businessSearchCtrl = new FormControl();
  activeBusiness: Business;
  businesses = [];
  headerAlias;
  routeData;
  defaultValues = [];
  aliasArray = [];
  defaultArray = [];
  isLoading = false;
  ticketOption = 0;
  errorMsg = null;
  faSave = faSave;
  newAlias = false;
  faInfoCircle = faInfoCircle;
  selectedInput = '';
  constructor(private aliasService: AliasService, private route: ActivatedRoute,
    private userService: UserService,
    private toastr: ToastrService,
    private businessService: BusinessService) { }



  ngOnInit(): void {
    let defaults = <ApiResponse>this.route.snapshot.data.defaultAliases;
    if (defaults && defaults.data) {
      this.defaultValues = _.sortBy(<ApiResponse>defaults.data, ['ticketOption']);
      this.headerAlias = this.defaultValues[this.ticketOption];
      this.setAliasArrayValue(this.defaultValues[this.ticketOption], false);
    }

    this.activeBusiness = JSON.parse(localStorage.getItem('selectedBusiness'));
    if (!this.activeBusiness || !this.activeBusiness.ID) {
      return;
    }

    this.aliasService.getAllHeaderAlisesByBusiness(this.activeBusiness.ID).subscribe(result => {

      if (result.data.length === 0) {
        this.newAlias = true;
        this.headerAlias = {
          businessID: this.activeBusiness.ID,
          ticketOption: Number(this.activeBusiness.ticketOption),
          ID: null
        }

        this.setAliasArrayValue(this.defaultValues[this.ticketOption], true)
      };
      if (result.data.length === 1) {
        this.headerAlias = result.data[0];
        this.setAliasArrayValue(this.headerAlias, false)
        this.isLoading = false;

      };
      if (result.data.length > 1) { //this Engage Admin
        this.headerAlias = result.data[this.ticketOption];
        this.setAliasArrayValue(this.headerAlias, false)
        this.isLoading = false;
      }
    }
    )
  }


  generateArrayOfAliases(obj): any {
    if (obj) {
      return Object.keys(obj).map((key) => {

        return { key: key, value: obj[key] }
      }).reduce(function (result, alias) {
        if (["ID", "businessID", "ticketOption"].indexOf(alias.key) === -1) {
          result.push(alias);
        }
        return result;
      }, []);
    }
  }
  setAliasArrayValue(aliasValues, isEmpty) {
    this.aliasArray = _.zipWith(
      this.generateArrayOfAliases(aliasValues),
      this.generateArrayOfAliases(this.defaultValues[this.ticketOption]),
      function (a, b) {
        if (["ID", "businessID", "ticketOption"].indexOf(a.key) > -1) {
          return;
        }
        let aliasKey = a.key;
        let aliasValue = isEmpty ? null : a.value;
        let defaultText = b ? b.value : a.value;
        return { aliasKey, aliasValue, defaultText }
      })
  }
  ticketOptionChanged() {
    this.headerAlias = this.defaultValues[this.ticketOption];
    this.setAliasArrayValue(this.defaultValues[this.ticketOption], false)
  }
  updateAlias() {

    if (Number(this.headerAlias.businessID) === this.activeBusiness.ID) {
      this.aliasArray.forEach(alias => {
        _.set(this.headerAlias, alias.aliasKey, alias.aliasValue);
      });
      this.aliasService.updateHeaderAlias(this.headerAlias).subscribe(result => {
        if (result.success) {
          this.toastr.success('Header Alias successfully updated!');
          this.headerAlias = result.data;
        }
      })
    } else {
      this.toastr.error('Business IDs out of sync');
    }

  }
  createAlias() {

    if (Number(this.headerAlias.businessID) === this.activeBusiness.ID) {
      this.aliasArray.forEach(alias => {
        _.set(this.headerAlias, alias.aliasKey, alias.aliasValue);
      });
      this.aliasService.createHeaderAlias(this.headerAlias).subscribe(result => {
        if (result.success) {
          this.toastr.success('Header Alias record successfully created!');
          this.headerAlias = result.data;
          this.newAlias = false;
        }

      })
    } else {
      this.toastr.error('Business IDs out of sync');
    }
  }
}


