import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../user/user.service';
import { Business } from '../../models/business';
import { BusinessService } from '../../operator/business.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessesResolver implements Resolve<Observable<Business[]>> {

  constructor(private businessService: BusinessService) { }

  resolve():Observable<Business[]> {
    return this.businessService.getAllBusinesses();
  }
}
