import { Component, OnInit } from '@angular/core';
import { HeaderTitleService } from '../layout/header-title.service';
import { FileUploadComponent } from '../shared/components/file-upload/file-upload.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public title = ['Home', 'Dashboard'];

  constructor(private headerTitleService: HeaderTitleService) { }

  ngOnInit(): void {
    // Set the title
    this.headerTitleService.setTitle(this.title);
  }

}
