import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScanItemDialogData } from './ScanItemDialogData';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ItemQrCodeService } from "../../../item-qr-code/item-qr-code.service";
import { ItemQRCode } from "../../../models/itemQRCode";
import Utils from "../../../shared/helpers/utils-helper";
import { faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'edit-scan-item-modal',
  templateUrl: './edit-scan-item-modal.component.html',
  styleUrls: ['./edit-scan-item-modal.component.scss']
})

/**
 * Component for adding/editing scan items on a ticket
 */
export class EditScanItemModal implements OnInit {
  public editScanItemForm: FormGroup;
  public isAdd: boolean = false; // Toggles between adding an item to a ticket, or editing an existing one already on a ticket.
  public isNewItem: boolean = false; // Toggles between whether adding an item is done by selecting an item from a pre-existing list, or by creating a eew item on-the-fly.
  public itemQRCodes: ItemQRCode[];
  public faPlus = faPlus;
  private utils = new Utils();

  constructor(
    public dialogRef: MatDialogRef<EditScanItemModal>,
    @Inject(MAT_DIALOG_DATA) public data: ScanItemDialogData,
    private itemQrCodeService: ItemQrCodeService) {
  }

  ngOnInit() {
    if (this.data.isAdd) {
      this.isAdd = true;
    }

    // Setup the itemQRCode data, which could be passed in to save on network calls.
    if (this.data.itemQRCodes != undefined) {
      this.itemQRCodes = this.data.itemQRCodes;
    } else {
      this.itemQrCodeService.getItemQrCodeByBusiness(this.data.businessID).subscribe(result => {
        this.itemQRCodes = result.data;
      });
    }

    // Setup the form for the modal.
    this.editScanItemForm = new FormGroup({
      itemID: new FormControl({value: this.data.scanItem.itemQRCode.ID, disabled: !this.isAdd}, [Validators.required]),
      itemDescription: new FormControl(this.data.scanItem.itemQRCode.itemDescription, [Validators.required]),
      itemPrice: new FormControl({value: this.data.scanItem.currentItemPrice, disabled: !this.isNewItem}, [Validators.required]),
      itemQuantity: new FormControl(this.data.scanItem.quantity, [Validators.required])
    });

    // Instead of using NG Model (which is deprecated), subscribe to the form changes and store the value.
    this.editScanItemForm.valueChanges.subscribe(formData => {
      if (formData.itemID && !this.isNewItem && formData.itemID != this.data.scanItem.itemQRCode.ID) {
        this.data.scanItem.itemQRCode = this.utils.findObject(this.itemQRCodes, 'ID', formData.itemID);
        this.editScanItemForm.patchValue({
          itemDescription: this.data.scanItem.itemQRCode.itemDescription,
          itemPrice: this.data.scanItem.itemQRCode.itemPrice
        });
      }

      if (formData.itemDescription && this.isNewItem && formData.itemDescription != this.data.scanItem.itemQRCode.itemDescription) {
        this.data.scanItem.itemQRCode.itemDescription = formData.itemDescription;
        this.editScanItemForm.get('itemID').patchValue(0);
      }

      if (formData.itemPrice) {
        this.data.scanItem.currentItemPrice = parseFloat(formData.itemPrice);
        this.data.scanItem.itemQRCode.itemPrice = formData.itemPrice;
      }

      if (formData.itemQuantity)
        this.data.scanItem.quantity = formData.itemQuantity;
    });
  }

  /**
   * Toggles the modal display between selecting a pre-existing item, or creating a new one.
   */
  swapItemType(): void {
    this.isNewItem = !this.isNewItem;

    // Reset/adjust the form
    this.editScanItemForm.patchValue({
      itemID: null,
      itemDescription: null,
      itemPrice: null
    });
    this.editScanItemForm.get('itemPrice')[!this.isNewItem ? 'disable' : 'enable']();

    // Reset the itemQRCode for the scan item if we are creating a new item
    if (this.isNewItem) {
      this.data.scanItem.itemQRCode = new ItemQRCode();
    }
  }

  /**
   * Cancels the add/edit of an item.
   */
  cancelModalEdit(): void {
    this.dialogRef.close();
  }
}
